import * as THREE from 'three'

export const generatePreviewImage = (geometry) => {
	// Create a new scene
	const scene = new THREE.Scene()

	// Transparent background
	scene.background = null

	// Center geometry
	geometry.center()

	// Get bounding sphere
	geometry.computeBoundingSphere()
	const boundingSphere = geometry.boundingSphere
	if (!boundingSphere) {
		throw new Error('Geometry bounding sphere could not be computed.')
	}
	const radius = boundingSphere.radius

	// Initialize the orthographic camera
	const camera = new THREE.OrthographicCamera(
		-radius, // left
		radius, // right
		radius, // top
		-radius, // bottom
		0.001, // near
		100000, // far
	)

	// Position and rotation of the camera
	camera.position.set(radius, radius, radius)
	camera.lookAt(new THREE.Vector3(0, 0, 0))

	// Update camera's world matrix
	camera.updateMatrixWorld()
	camera.updateProjectionMatrix()

	// Select a random color
	const colors = [
		'#A5A5A5',
		'#EAEAEA',
		'#C4E2F3',
		'#F88701',
		'#7F7F7F',
		'#FAB601',
	]
	const randomColor = colors[Math.floor(Math.random() * colors.length)]

	// Create the material
	const material = new THREE.MeshPhongMaterial({
		color: new THREE.Color(randomColor),
		specular: 0x333333,
		shininess: 150,
	})

	// Create the mesh and add it to the scene
	const mesh = new THREE.Mesh(geometry, material)
	scene.add(mesh)

	// Set up lighting
	const light1 = new THREE.DirectionalLight(0xffffff, 0.8)
	light1.position.set(-1, 5, 1)
	scene.add(light1)

	const light2 = new THREE.DirectionalLight(0xffffff, 0.6)
	light2.position.set(1, -1, 1)
	scene.add(light2)

	const ambientLight = new THREE.AmbientLight(0x404040, 0.8)
	ambientLight.position.set(1, 1, -1)
	scene.add(ambientLight)

	// Create the renderer
	const renderer = new THREE.WebGLRenderer({
		antialias: true,
		alpha: true,
	})
	renderer.setSize(500, 500)

	// Render the scene
	renderer.render(scene, camera)
	const dataUrl = renderer.domElement.toDataURL()

	// Clean up
	renderer.dispose()

	return dataUrl
}
