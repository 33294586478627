import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
	CardText,
	Col,
	Row,
} from 'reactstrap'
import Tabs from '../../components/Tabs/Tabs'

import {
	getBoxIconByInteraction,
} from 'pages/CRMUserDetail/Histórico de uso/CrmLogUtils'
import { withTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import {
	adaptDateOnLocale,
	adaptTimerString,
} from '../../common/Utils/StringUtilities'

import ModalCreateCrmLog from 'pages/UserDetail/Components/LeadScoreAndCrmLogs.js/CrmLogs/ModalCreateCrmLog'

class OfferTab extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: '1',
			crmInteractions: this.props.offer.crmInteractions,
		}
		this.toggle = this.toggle.bind(this)
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			})
		}
	}
	render() {
		const { t } = this.props
		return (
			<React.Fragment>
				<Tabs
					defaultSelected={0}
					sections={[
						{
							id: 'clientOfferComment',
							title: (
								<span id='clientOfferCommentTab' className="d-none d-sm-block">
									{this.props.offer?.message ? (
										<>
											<span>{t('clientOfferComment', { ns: 'naming' })}</span>
											<i className="bx bxs-error text-primary fs-3 text mx-2"></i>
										</>
									) : (
										t('clientOfferComment', { ns: 'naming' })
									)}
								</span>
							),
							content: (
								<Row>
									<Col sm="12">
										<CardText id="clientOfferCommentCardText" className="mb-0 ps-3 my-2">
											{this.props.offer?.message || t('noComments', { ns: 'naming' })}
										</CardText>
									</Col>
								</Row>
							),
						},
						{
							id: 'crmInteraction',
							title: (
								<span id='crmInteractionTab' className="d-none d-sm-block">
									{!this.state.crmInteractions.length > 0 ? (
										t('crmInteraction', { ns: 'naming' })
									) : (
										<>
											{t('crmInteraction', { ns: 'naming' })}
											<i className="bx bxs-error text-primary fs-3 text mx-2"></i>
										</>
									)}
								</span>
							),
							content: (
								<Row>
									<Col sm="12">
										{this.state.crmInteractions.length == 0 ? (
											<CardText id="crmInteractionsCardText" className="mb-0 ps-3 my-2">
												{t('no-crm-interactions', { ns: 'naming' })}
											</CardText>
										) : (
											<div className='bg-light'>
												<SimpleBar style={{ maxHeight: '9rem' }} autoHide={false}>
													{this.state.crmInteractions.map(interaction => {
														return (
															<CardText
																key={`interaction_${JSON.stringify(interaction.date)}`}
																className="mt-2"
															>
																<Row className="mx-2">
																	<Col className="col-1">
																		{interaction.interaction &&
																			<div className="ms-1 mt-3">{getBoxIconByInteraction(interaction.interaction)}</div>}
																	</Col>
																	<Col className="col-11">
																		<Row>
																			<span>
																				{adaptDateOnLocale(
																					interaction.date.getFullYear(),
																					interaction.date.getMonth(),
																					interaction.date.getDate(),
																					'es',
																				) + ' '}
																				{adaptTimerString(
																					interaction.date.getHours(),
																					interaction.date.getMinutes(),
																					interaction.date.getSeconds(),
																				) + ' '}
																				({interaction.managerFullName})
																			</span>
																		</Row>
																		<Row>
																			<span>
																				<strong className="text-primary">
																					{t(interaction.interactionType, { ns: 'naming' })}
																				</strong>
																				<strong>
																					{' ' + interaction.subject}
																				</strong>
																			</span>
																		</Row>
																		<Row>
																			<div className>{interaction.message}</div>
																		</Row>
																	</Col>
																</Row>
															</CardText>
														)
													})}
												</SimpleBar>
											</div>
										)}
									</Col>
									<div className='m-3'>
										{this.props.offer?.customer?.id && (
											<ModalCreateCrmLog
												accountId={this.props.offer.customer.id}
												simpleButton={true}
												defaultOfferId={this.props.offer.id}
												afterCreateNewCrmLog={(newCrmInteraction) => {
													this.setState({
														...this.state,
														crmInteractions: [...this.state.crmInteractions, newCrmInteraction],
													})
												}}
											/>
										)}
									</div>
								</Row >
							),
						},
					]
					}
				/>
			</React.Fragment >
		)
	}
}

OfferTab.propTypes = {
	t: PropTypes.func,
	offer: PropTypes.shape({
		message: PropTypes.string,
		crmInteractions: PropTypes.arrayOf(
			PropTypes.shape({
				date: PropTypes.instanceOf(Date),
				userFullName: PropTypes.string,
				type: PropTypes.string,
				destination: PropTypes.string,
				status: PropTypes.shape({
					message: PropTypes.string,
					isOk: PropTypes.bool,
				}),
				message: PropTypes.string,
			}),
		),
	}),
}
export default withTranslation()(OfferTab)
