import { DELETE_MODIFIED_BLUEPRINT } from 'store/offers/actionTypes'
import {
	DELETE_COMPLETE_PRODUCTION_FILE,
	DELETE_COMPLETE_PRODUCTION_FILE_FAIL,
	DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS,
	DELETE_MODIFIED_BLUEPRINTS_FAIL,
	DELETE_MODIFIED_BLUEPRINTS_SUCCESS,
	DELETE_OLD_PART_FILES,
	DELETE_OLD_PART_FILES_FAIL,
	DELETE_OLD_PART_FILES_SUCCESS,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS,
	GET_ALL_ORDER_SHIPMENT_FILES,
	GET_ALL_ORDER_SHIPMENT_FILES_FAIL,
	GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS,
	GET_COMPLETE_PRODUCTION_FILES,
	GET_COMPLETE_PRODUCTION_FILES_FAIL,
	GET_COMPLETE_PRODUCTION_FILES_SUCCESS,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_FAIL,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_SUCCESS,
	GET_PARTS_FILES,
	GET_PARTS_FILES_FAIL,
	GET_PARTS_FILES_SUCCESS,
	GET_PART_LIST_BLUEPRINTS_AND_STP,
	GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL,
	GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS,
	GET_PART_LIST_IMAGES,
	GET_PART_LIST_IMAGES_FAIL,
	GET_PART_LIST_IMAGES_SUCCESS,
	GET_PRODUCTION_CHAT_IMAGES,
	GET_PRODUCTION_CHAT_IMAGES_FAIL,
	GET_PRODUCTION_CHAT_IMAGES_SUCCESS,
	GET_QUALITY_VERIFICATION_IMAGES,
	GET_QUALITY_VERIFICATION_IMAGES_FAIL,
	GET_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	GET_QUOTE_CHAT_IMAGES,
	GET_QUOTE_CHAT_IMAGES_FAIL,
	GET_QUOTE_CHAT_IMAGES_SUCCESS,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS,
	UPLOAD_COMPLETE_PRODUCTION_FILES,
	UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL,
	UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS,
	UPLOAD_MANAGER_TASK_IMAGE,
	UPLOAD_MANAGER_TASK_IMAGE_FAIL,
	UPLOAD_MANAGER_TASK_IMAGE_SUCCESS,
	UPLOAD_ORDER_DELIVERY_NOTE,
	UPLOAD_ORDER_DELIVERY_NOTE_FAIL,
	UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS,
	UPLOAD_ORDER_SHIPMENT_FILES,
	UPLOAD_ORDER_SHIPMENT_FILES_FAIL,
	UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS,
	UPLOAD_PART_FILES,
	UPLOAD_PART_FILES_FAIL,
	UPLOAD_PART_FILES_SUCCESS,
	UPLOAD_PART_IMAGE,
	UPLOAD_PART_IMAGE_FAIL,
	UPLOAD_PART_IMAGE_SUCCESS,
	UPLOAD_PRODUCTION_CHAT_IMAGE,
	UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL,
	UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS,
	UPLOAD_PROFILE_IMAGE,
	UPLOAD_PROFILE_IMAGE_FAIL,
	UPLOAD_PROFILE_IMAGE_SUCCESS,
	UPLOAD_QUALITY_VERIFICATION_IMAGES,
	UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL,
	UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	UPLOAD_QUOTE_CHAT_IMAGE,
	UPLOAD_QUOTE_CHAT_IMAGE_FAIL,
	UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS,
	UPLOAD_RESOLUTION_IMAGE,
	UPLOAD_RESOLUTION_IMAGE_FAIL,
	UPLOAD_RESOLUTION_IMAGE_SUCCESS,
	UPLOAD_TO_CAD,
	UPLOAD_TO_CAD_FAIL,
	UPLOAD_TO_CAD_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	isLoading: false,
	isDeleteBlueprintsCompleted: null,
	error: null,
	images: [],
	chatImages: [],
	partsBlueprintsAndStp: [],
	partsFiles: [],
	productionChatImages: [],
	productionFiles: [],
	uploadCompleteProductionFilesSuccess: false,
	uploadImagesSuccess: false,
	imagesInBase64: [],
	deliveryNote: null,
	orderShipmentFiles: [],
	qualityVerficationImages: [],
	deleteCompleteProductionFilesSuccess: null,
	isLoadingGetQualityVerificationImages: false,
	dontShowModalAutomatic: null,
	resolutionsImages: null,
	managerTaskImages: null,
	isLoadingManagerTaskImages: false,

	uploadPartFilesState: {
		status: undefined,
		response: undefined,
		error: undefined,
	},
	uploadToCadState: {
		status: undefined,
		response: undefined,
		error: undefined,
	},
	deleteOldPartFilesState: {
		status: undefined,
		response: undefined,
		error: undefined,
	},
}

const CloudStorage = (state = INIT_STATE, action) => {
	switch (action.type) {

		case UPLOAD_MANAGER_TASK_IMAGE:
			return {
				...state,
				isLoadingUploadManagerTaskImages: true,
			}

		case UPLOAD_MANAGER_TASK_IMAGE_SUCCESS:
			return {
				...state,
				isLoadingUploadManagerTaskImages: false,
			}

		case UPLOAD_MANAGER_TASK_IMAGE_FAIL:
			return {
				...state,
				isLoadingUploadManagerTaskImages: false,
				error: action.payload.error,
			}
		case GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID:
			return {
				...state,
				isLoadingManagerTaskImages: true,
			}

		case GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_SUCCESS:
			return {
				...state,
				isLoadingManagerTaskImages: false,
				managerTaskImages: action.payload.response.managerTaskImages,
			}

		case GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_FAIL:
			return {
				...state,
				isLoadingManagerTaskImages: false,
				error: action.payload.error,
			}

		case UPLOAD_RESOLUTION_IMAGE:
			return {
				...state,
				isLoading: true,
			}
		case UPLOAD_RESOLUTION_IMAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case UPLOAD_RESOLUTION_IMAGE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}

		case GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID:
			return {
				...state,
				isLoading: true,
			}

		case GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS:
			return {
				...state,
				isLoading: false,
				resolutionsImages: action.payload.response.resolutionsImages,
			}

		case GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}

		case UPLOAD_PROFILE_IMAGE:
			return {
				...state,
				isLoading: true,
			}
		case UPLOAD_PROFILE_IMAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				uploadImagesSuccess: true,
			}
		case UPLOAD_PROFILE_IMAGE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}



		case GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID:
			return {
				...state,
				error: null,
			}
		case GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS:
			return {
				...state,
			}
		case GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL:
			return {
				...state,
				error: action.payload.error.response.statusText,
			}
		case GET_PART_LIST_IMAGES:
			return {
				...state,
			}
		case GET_PART_LIST_IMAGES_SUCCESS:
			return {
				...state,
				images: action.payload.response.images,
			}
		case GET_PART_LIST_IMAGES_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case UPLOAD_PART_IMAGE:
			return {
				...state,
			}
		case UPLOAD_PART_IMAGE_SUCCESS:
			return {
				...state,
			}
		case UPLOAD_PART_IMAGE_FAIL:
			return {
				...state,
				error: action.payload.error,
			}

		// ------------ UPLOAD_PART_FILES
		case UPLOAD_PART_FILES:
			return {
				...state,
				uploadPartFilesState: {
					status: 'fetching',
					response: undefined,
					error: undefined,
				},
			}
		case UPLOAD_PART_FILES_SUCCESS:
			return {
				...state,
				uploadPartFilesState: {
					status: 'finished',
					response: action.payload.response,
					error: undefined,
				},
			}
		case UPLOAD_PART_FILES_FAIL:
			return {
				...state,
				uploadPartFilesState: {
					status: 'finished',
					response: undefined,
					error: action.payload.error,
				},
			}

		// ------------ UPLOAD_TO_CAD
		case UPLOAD_TO_CAD:
			return {
				...state,
				uploadToCadState: {
					status: 'fetching',
					response: undefined,
					error: undefined,
				},
			}
		case UPLOAD_TO_CAD_SUCCESS:
			return {
				...state,
				uploadToCadState: {
					status: 'finished',
					response: action.payload.response,
					error: undefined,
				},
			}
		case UPLOAD_TO_CAD_FAIL:
			return {
				...state,
				uploadToCadState: {
					status: 'finished',
					response: undefined,
					error: action.payload.error,
				},
			}

		// ------------ DELETE_OLD_PART_FILES
		case DELETE_OLD_PART_FILES:
			return {
				...state,
				deleteOldPartFilesState: {
					status: 'fetching',
					response: undefined,
					error: undefined,
				},
			}
		case DELETE_OLD_PART_FILES_SUCCESS:
			return {
				...state,
				deleteOldPartFilesState: {
					status: 'finished',
					response: action.payload.response,
					error: undefined,
				},
			}
		case DELETE_OLD_PART_FILES_FAIL:
			return {
				...state,
				deleteOldPartFilesState: {
					status: 'finished',
					response: undefined,
					error: action.payload.error,
				},
			}

		case GET_PART_LIST_BLUEPRINTS_AND_STP:
			return {
				...state,
			}
		case GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS:
			return {
				...state,
				partsBlueprintsAndStp: action.payload.response,
			}
		case GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case GET_QUOTE_CHAT_IMAGES:
			return {
				...state,
			}
		case GET_QUOTE_CHAT_IMAGES_SUCCESS:
			return {
				...state,
				chatImages: action.payload.response,
			}
		case GET_QUOTE_CHAT_IMAGES_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case UPLOAD_QUOTE_CHAT_IMAGE:
			return {
				...state,
			}
		case UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS:
			return {
				...state,
			}
		case UPLOAD_QUOTE_CHAT_IMAGE_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case GET_PARTS_FILES:
			return {
				...state,
			}
		case GET_PARTS_FILES_SUCCESS:
			return {
				...state,
				partsFiles: action.payload.response.data,
			}
		case GET_PARTS_FILES_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case GET_PRODUCTION_CHAT_IMAGES:
			return {
				...state,
			}
		case GET_PRODUCTION_CHAT_IMAGES_SUCCESS:
			return {
				...state,
				productionChatImages: action.payload.response,
			}
		case GET_PRODUCTION_CHAT_IMAGES_FAIL:
			return {
				...state,
				error: action.payload.error,
			}
		case UPLOAD_PRODUCTION_CHAT_IMAGE:
			return {
				...state,
			}
		case UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS:
			return {
				...state,
			}
		case UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL:
			return {
				...state,
				error: action.payload.error,
			}

		case UPLOAD_COMPLETE_PRODUCTION_FILES:
			return {
				...state,
				uploadCompleteProductionFilesSuccess: null,
				isLoading: true,
			}
		case UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS:
			return {
				...state,
				uploadCompleteProductionFilesSuccess: true,
				isLoading: false,
			}
		case UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL:
			return {
				...state,
				uploadCompleteProductionFilesSuccess: false,
				isLoading: false,
				error: action.payload.error,
			}
		case GET_COMPLETE_PRODUCTION_FILES:
			return {
				...state,
				isLoading: true,
				productionFiles: null,
			}
		case GET_COMPLETE_PRODUCTION_FILES_SUCCESS:
			return {
				...state,
				isLoading: false,
				productionFiles: action.payload.response,
			}
		case GET_COMPLETE_PRODUCTION_FILES_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		case DELETE_MODIFIED_BLUEPRINT:
			return {
				...state,
				isLoading: true,
				isDeleteBlueprintsCompleted: null,
			}
		case DELETE_MODIFIED_BLUEPRINTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isDeleteBlueprintsCompleted: true,
			}
		case DELETE_MODIFIED_BLUEPRINTS_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error.message,
				isDeleteBlueprintsCompleted: false,
			}
		case UPLOAD_ORDER_SHIPMENT_FILES:
			return {
				...state,
				isLoading: true,
			}
		case UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case UPLOAD_ORDER_SHIPMENT_FILES_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		case UPLOAD_QUALITY_VERIFICATION_IMAGES:
			return {
				...state,
				isLoading: true,
				images: [],
			}
		case UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				images: action.payload.response.uploadedIn,
			}
		case UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		case DOWNLOAD_PART_LIST_IMAGES_IN_BASE64:
			return {
				...state,
				isLoading: true,
				imagesInBase64: [],
			}
		case DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS:
			return {
				...state,
				isLoading: false,
				imagesInBase64: action.payload.response,
			}
		case DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}

		case UPLOAD_ORDER_DELIVERY_NOTE:
			return {
				...state,
				isLoading: true,
				deliveryNote: null,
			}
		case UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false,
				deliveryNote: action.payload.response.uploadedIn,
			}
		case UPLOAD_ORDER_DELIVERY_NOTE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		case GET_QUALITY_VERIFICATION_IMAGES:

			return {
				...state,
				isLoadingGetQualityVerificationImages: true,
				dontShowModalAutomatic: action.payload.dontShowModalAutomatic,
				qualityVerficationImages: [],
			}
		case GET_QUALITY_VERIFICATION_IMAGES_SUCCESS:

			return {
				...state,
				isLoadingGetQualityVerificationImages: false,
				qualityVerficationImages: action.payload.response.images,
				dontShowModalAutomatic: action.payload.dontShowModalAutomatic,
			}
		case GET_QUALITY_VERIFICATION_IMAGES_FAIL:
			return {
				...state,
				isLoadingGetQualityVerificationImages: false,
				error: action.payload.error.message,
			}
		case GET_ALL_ORDER_SHIPMENT_FILES:
			return {
				...state,
				isLoading: true,
				orderShipmentFiles: [],
			}
		case GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS:
			return {
				...state,
				isLoading: false,
				orderShipmentFiles: action.payload.response,
			}
		case GET_ALL_ORDER_SHIPMENT_FILES_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error.message,
			}

		case DELETE_COMPLETE_PRODUCTION_FILE:
			return {
				...state,
				deleteCompleteProductionFilesSuccess: null,
				isLoading: true,
			}
		case DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS:
			return {
				...state,
				deleteCompleteProductionFilesSuccess: true,
				isLoading: false,
			}
		case DELETE_COMPLETE_PRODUCTION_FILE_FAIL:
			return {
				...state,
				deleteCompleteProductionFilesSuccess: false,
				isLoading: false,
				error: action.payload.error,
			}

		default:
			return state
	}

}
export default CloudStorage
