import { getDate } from 'common/Utils/StringUtilities'
import DownloadFilesButtons from 'components/Common/DownloadFilesButtons'
import { IconTooltip } from 'components/Common/IconTooltip'
import PartThumbnail from 'components/Common/PartThumbnail'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getQualityVerificationImages } from 'store/actions'
import ResolutionIncidenceModal from './ResolutionIncidenceModal'

const PartIncidence = ({ item, solutions, problems, getQualityVerificationImages, qualityVerficationImages, t }) => {
	const { incidences, orderItem } = item
	const [modalData, setModalData] = useState({
		visible: false,
		incidence: null,
		quantity: 0,
	})
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
	const [message, setMessage] = useState('')

	const handleDisplayIdClick = (incidence) => {
		getQualityVerificationImages({
			data: {
				partId: incidence.partId,
				qualityVerificationId: incidence.id,
			},
		}, true)
		setModalData({
			visible: true,
			incidence,
			quantity: orderItem.quantity,
		})
	}

	const handleShowMessage = (incidence) => {
		if (incidence.observation === '') {
			setMessage(t('no-observation', { ns: 'naming' }))
			setIsMessageModalOpen(true)
			return
		}
		setMessage(incidence.observation)
		setIsMessageModalOpen(true)
	}

	const handleModalClose = () => {
		setModalData({
			visible: false,
			incidence: null,
		})
	}

	return (
		<>
			{incidences.map((incidence, index) => {

				const problem = problems.find((problem) => problem.id === incidence.problem)
				const solution = solutions.find((solution) => solution.id === incidence.solution)
				return (
					<div
						key={`${incidence.displayId}_${index}`}
						className={'w-100 m-1 mx-2 p-4 rounded d-flex flex-row align-items-center'}
						style={{ backgroundColor: incidence.solved ? '#C4D79B' : '#F08080' }}
					>
						<span className='col-1'>
							<span className='cursor-pointer' style={{ color: '#556EE6' }} onClick={() => handleDisplayIdClick(incidence)}>
								{incidence.displayId}
							</span>

						</span>
						<span className='col-1'>
							<PartThumbnail
								propsImageUrl={orderItem.part.fileLinks.thumbnail}
								propsGeometryUrl={orderItem.part.fileLinks.stlModel || orderItem.part.fileLinks.objModel}
								geometryData={{
									partId: orderItem.part.id,
									partName: orderItem.part.name,
									size: orderItem.part.size,
									weight: orderItem.part.weight,
								}}
							/>
						</span>
						<span className='col-1'>
							<DownloadFilesButtons part={orderItem.part} />
						</span>
						<span className='col-2 d-flex align-items-center'>
							{t('parts-wrong', { ns: 'naming' })}: {incidence.partsWrong}
						</span>
						<span className='col-1'>
							<span onClick={() => handleShowMessage(incidence)} className='cursor-pointer'>
								<IconTooltip message={incidence.observation} icon='bx bx-note fs-4 d-flex align-items-center' position='left' />
							</span>
						</span>
						<span className='col-1'>
							{t(incidence.notifiedBy, { ns: 'naming' })}
						</span>
						<span className='col-1'>
							{getDate(incidence.creationDate)}
						</span>
						<span className='col-2'>
							{problem.description}
						</span>
						<span className='col-1'>
							{solution.description}
						</span>
					</div>
				)
			})}

			{modalData.incidence && (
				<ResolutionIncidenceModal
					isOpen={modalData.visible}
					onClose={handleModalClose}
					incidence={modalData.incidence}
					partId={modalData.incidence.partId}
					problems={problems}
					solutions={solutions}
					quantity={modalData.quantity}
				/>
			)}
			<ModalMessage isModalOpen={isMessageModalOpen} closeModal={() => setIsMessageModalOpen(false)} message={message} />

		</>
	)
}

PartIncidence.propTypes = {
	item: PropTypes.object,
	solutions: PropTypes.array,
	problems: PropTypes.array,
	getQualityVerificationImages: PropTypes.func,
	qualityVerficationImages: PropTypes.array,
}

const mapStateToProps = (state) => ({
	problems: state.Orders.qualityProblems,
	solutions: state.Orders.qualitySolutions,
	qualityVerficationImages: state.CloudStorage.qualityVerficationImages,

})

const mapDispatchToProps = { getQualityVerificationImages }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PartIncidence))
