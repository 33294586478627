import PropTypes from 'prop-types'

import Modal from 'components/Common/Modal/Modal'
import { withTranslation } from 'react-i18next'

const CreateQuoteResponseModal = ({
	isQuoteCreatedSuccessfully,
	closeCreateQuotationResultModal,
	t,
}) => {
	return <Modal
		isOpen={isQuoteCreatedSuccessfully !== null}
		closeModal={closeCreateQuotationResultModal}
		title={isQuoteCreatedSuccessfully === false ? t('attention', { ns: 'naming' }) : ''}
		body={
			isQuoteCreatedSuccessfully == null ? undefined :
				(isQuoteCreatedSuccessfully ? (
					<div className="modal-body">
						¡La cotización fue creada de manera satisfactoria!
					</div>
				) : (
					<div className="modal-body">
						Hubo un problema a la hora de crear la cotización, por favor,
						contacte con un administrador.
					</div>
				))
		}
	/>
}

CreateQuoteResponseModal.propTypes = {
	isQuoteCreatedSuccessfully: PropTypes.bool,
	closeCreateQuotationResultModal: PropTypes.func,
	t: PropTypes.func,
}

export default withTranslation()(CreateQuoteResponseModal)
