import { DAY_IN_MILLISECONDS, checkIsWorkingDay } from 'common/Utils/DateUtils'
import { calculateVolumetricWeight, roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import { calculateShipmentCosts } from 'common/Utils/ShipmentUtils'
import { getDate, getDateWithHour } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import Select from 'components/Common/Select/Select'
import quoteStatus from 'constants/quoteStatus'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getPartCostsBySupplierId, getPartPricesBySupplierId, getSupplierInfo, getSupplierShipmentPricingList } from 'store/actions'
import SimulationPartCard from './SimulationPartCard'

const OrderSimulationModal = ({
	t,
	isOrderSimulationModalOpen,
	closeOrderSimulationModal,
	setModalMessage,
	parts,
	suppliers,
	parseSuppliers,
	offerDate,
	getPartPricesBySupplierId,
	partPrices,
	getPartCostsBySupplierId,
	partCosts,
	getSupplierInfo,
	supplierShippingTiming,
	getSupplierShipmentPricingList,
	supplierShipmentPricingList,
	isLoading,
	error,
}) => {

	const [defaultSupplier, setDefaultSupplier] = useState()
	const [availableSuppliers, setAvailableSuppliers] = useState([])
	const [isSimulating, setIsSimulating] = useState(false)
	const [isCalculating, setIsCalculating] = useState(false)
	const [partsToSimulate, setPartsToSimulate] = useState()
	const [partsToRender, setPartsToRender] = useState()
	const [partsBySupplierList, setpartsBySupplierList] = useState()
	const [readyToSimulate, setReadyToSimulate] = useState(false)
	const [totalCost, setTotalCost] = useState()
	const [totalSale, setTotalSale] = useState()
	const [shipmentCost, setShipmentCost] = useState()
	const [productionTerm, setProductionTerm] = useState()
	const [arrivalDate, setArrivalDate] = useState()
	const [grossProfits, setGrossProfits] = useState()
	const [netProfits, setNetProfits] = useState()
	const [suppliersInfo, setSuppliersInfo] = useState([])
	const [partPricesLength, setPartPricesLength] = useState(0)
	const [partCostsLength, setPartCostsLength] = useState(0)

	useEffect(() => {
		if (parts?.length != 0) {
			setPartsToRender(parts.filter(part => {
				let resolution = false
				if (part.costsAndMargins.suppliers.length != 0) {
					for (const supplier of part.costsAndMargins.suppliers) {
						if (supplier.statusId == quoteStatus.QUOTE_STATUS_QUOTED) {
							resolution = true
						}
					}
				}
				return resolution
			}))
			setPartsToSimulate(parts.filter(part => {
				let resolution = false
				if (part.costsAndMargins.suppliers.length != 0) {
					for (const supplier of part.costsAndMargins.suppliers) {
						if (supplier.statusId == quoteStatus.QUOTE_STATUS_QUOTED) {
							resolution = true
						}
					}
				}
				return resolution
			}).map((part) => {
				return {
					id: part.id.value,
					supplierId: undefined,
					quantity: undefined,
					cost: 0,
					sale: 0,
					productionTerm: 0,
				}
			}))
		} else {
			setPartsToRender([])
			setPartsToSimulate([])
		}
	}, [parts])

	useEffect(() => {
		if (suppliers?.length != 0 && parts?.length != 0) {
			const supplierList = []
			const supplierIds = []
			for (const part of parts) {
				for (const partSupplier of part.costsAndMargins.suppliers) {
					if (!supplierIds.includes(partSupplier.id) && partSupplier.statusId == quoteStatus.QUOTE_STATUS_QUOTED) {
						supplierIds.push(partSupplier.id)
						const supplierFound = suppliers.find(supplier => supplier.id == partSupplier.id)
						if (supplierFound) {
							supplierList.push(supplierFound)
						}
					}
				}
			}
			setAvailableSuppliers(supplierList)
		}
	}, [suppliers, parts])

	useEffect(() => {
		if (partsToSimulate && partsToSimulate.length != 0) {
			let ready = true
			for (const part of partsToSimulate) {
				if (part.supplierId == undefined) ready = false
			}
			setReadyToSimulate(ready)
		}
	}, [partsToSimulate])

	useEffect(() => {
		if (isSimulating && (partPrices && partPrices.length == partPricesLength) && (partCosts && partCosts.length == partCostsLength) && (partsToSimulate && partsToSimulate.length != 0)) {
			setPartsToSimulate(partsToSimulate.map(part => {
				const prices = partPrices.find(partPrice => partPrice.partId == part.id && partPrice.supplierId == part.supplierId)?.regressionItems || []
				const costs = partCosts.find(partCost => partCost.partId == part.id && partCost.supplierId == part.supplierId)?.regressionItems || []
				const price = (prices[part.quantity - 1]?.price * part.quantity) || 0
				const cost = (costs[part.quantity - 1]?.totalCost) || 0
				const time = (costs[part.quantity - 1]?.time) || 0
				return {
					...part,
					sale: price,
					cost: cost,
					productionTerm: time,

				}
			}))
			const partsBySupplier = []
			for (const part of partsToSimulate) {
				let found = false
				for (const partBySupplier of partsBySupplier) {
					if (part.supplierId == partBySupplier.supplierId) {
						found = true
						partBySupplier.parts.push(part.id)
					}
				}
				if (!found) {
					partsBySupplier.push({
						supplierId: part.supplierId,
						parts: [part.id],
					})
				}
			}
			setpartsBySupplierList(partsBySupplier)
			setIsCalculating(true)
		}
	}, [partPrices, partCosts, isSimulating])

	useEffect(() => {
		if (isCalculating) {
			setTotalCost(getTotalCost())
			setTotalSale(getTotalSale())
			setProductionTerm(getProductionTerm())
			setShipmentCost(getShipmentCost())
			setArrivalDate(getArrivalDate())
			setGrossProfits(getGrossProfits())
			setNetProfits(getNetProfits())
			setIsCalculating(false)
			setIsSimulating(false)
		}
	}, [isCalculating])

	useEffect(() => {
		if (availableSuppliers.length != 0 && suppliersInfo.length == 0) {
			for (const supplier of availableSuppliers) {
				getSupplierInfo(supplier.id)
			}
			getSupplierShipmentPricingList()
		}
	}, [availableSuppliers])

	useEffect(() => {
		if (supplierShippingTiming) {
			setSuppliersInfo([...suppliersInfo, supplierShippingTiming])
		}
	}, [supplierShippingTiming])

	useEffect(() => {
		if (error) {
			setModalMessage(t('error-simulation', { ns: 'errors' }))
		}
	}, [error])

	const updatePartToSimulate = (partId, update) => {
		const updatedParts = partsToSimulate.map(part => {
			if (part.id == partId) {
				return {
					...part,
					...update,
				}
			}
			return part
		})
		setPartsToSimulate(updatedParts)
	}

	const handleOnChangeDefaultSupplier = (supplierId) => {
		setDefaultSupplier(supplierId)
		const updatedParts = partsToSimulate.map(part => {
			const partData = parts.find(p => p.id.value == part.id)
			const partSupplier = partData.costsAndMargins.suppliers.find(supplier => supplier.id == supplierId)
			if (partSupplier) {
				return {
					...part,
					supplierId: Number(supplierId),
					quantity: partSupplier.quotes[partSupplier.quotes.length - 1].quantity,
				}
			} else {
				return {
					id: part.id,
					supplierId: undefined,
					quantity: undefined,
					cost: 0,
					sale: 0,
					productionTerm: 0,
				}
			}
		})
		setPartsToSimulate(updatedParts)
	}

	const closeModal = () => {
		closeOrderSimulationModal()
		setDefaultSupplier(undefined)
		setTotalCost(undefined)
		setTotalSale(undefined)
		setProductionTerm(undefined)
		setShipmentCost(undefined)
		setArrivalDate(undefined)
		setGrossProfits(undefined)
		setNetProfits(undefined)
		setPartsToSimulate(partsToSimulate.map((part) => {
			return {
				id: part.id,
				supplierId: undefined,
				quantity: undefined,
				cost: 0,
				sale: 0,
				productionTerm: 0,
			}
		}))
	}

	const handleSimulation = () => {

		let partPricesLengthCount = 0
		let partCostsLengthCount = 0

		for (const part of partsToSimulate) {
			if (!partPrices.find(partPrice => partPrice?.partId == part.id && partPrice.supplierId == part.supplierId)) {
				++partPricesLengthCount
				getPartPricesBySupplierId(part.id, part.supplierId)
			}
			if (!partCosts.find(partCost => partCost?.partId == part.id && partCost.supplierId == part.supplierId)) {
				++partCostsLengthCount
				getPartCostsBySupplierId(part.id, part.supplierId)
			}
		}

		setPartPricesLength(partPricesLength + partPricesLengthCount)
		setPartCostsLength(partCostsLength + partCostsLengthCount)
		setIsSimulating(true)
	}

	const getTotalSale = () => {
		return partsToSimulate.reduce((accumulator, part) => accumulator + roundAtDecimals(part.sale, 2), 0)
	}

	const getTotalCost = () => {
		return partsToSimulate.reduce((accumulator, part) => accumulator + roundAtDecimals(part.cost, 2), 0)
	}

	const getProductionTerm = () => {
		let productionTerm = 0
		for (const partsBySupplier of partsBySupplierList) {
			const supplier = suppliersInfo.find(e => e.supplierInfo.accountId == partsBySupplier.supplierId)

			let productionDays = 0
			for (const partId of partsBySupplier.parts) {
				const part = partsToSimulate.find(part => part.id == partId)
				if (part.productionTerm > productionDays) {
					productionDays = part.productionTerm
				}
			}

			let extraDays = 1
			let i = 0
			let checkingDate = new Date()
			let isWorkingDay

			while (i < productionDays) {
				checkingDate = new Date(new Date().getTime() + (i + extraDays) * DAY_IN_MILLISECONDS)
				isWorkingDay = checkIsWorkingDay(checkingDate, supplier.supplierInfo.calendar)
				if (isWorkingDay) {
					++i
				} else {
					++extraDays
				}
			}

			let productionTime = productionDays + extraDays
			productionTime += Math.floor(partsBySupplier.parts.length / 8)
			productionTime += supplier.supplierInfo.calendar.shipmentDays

			if (productionTime > productionTerm) {
				productionTerm = productionTime
			}
		}
		return productionTerm
	}

	const getShipmentCost = () => {
		const shipmentCostList = []
		for (const partsBySupplier of partsBySupplierList) {
			const supplier = suppliers.find((supplier) => supplier.id == partsBySupplier.supplierId)
			let totalWeight = 0
			let totalVolumetricWeight = 0
			for (const partId of partsBySupplier.parts) {
				const part = parts.find(part => part.id.value == partId)
				const partToSimulate = partsToSimulate.find(part => part.id == partId)
				if (part) {
					totalWeight += (part.weight.props.weight * partToSimulate.quantity)
					totalVolumetricWeight += (calculateVolumetricWeight(part.size.props.size.x, part.size.props.size.y, part.size.props.size.z) * partToSimulate.quantity)
				}
			}
			shipmentCostList.push({
				supplierId: partsBySupplier.supplierId,
				supplierName: supplier.personalInformation?.firstName,
				cost: calculateShipmentCosts(partsBySupplier.supplierId, supplier.personalInformation.country, totalWeight, totalVolumetricWeight, supplierShipmentPricingList),
			})
		}
		return shipmentCostList
	}

	const getArrivalDate = () => {
		const productionTime = getProductionTerm()
		const imasWorkshopInfo = suppliersInfo.find(supplier => supplier.imasWorkshopInfo != null)?.imasWorkshopInfo || suppliersInfo.find(supplier => supplier.supplierInfo.accountId == process.env.REACT_APP_IMAS_WORKSHOP_SUPPLIER_ID)?.supplierInfo
		let arrivalDate = new Date(new Date().getTime() + productionTime * DAY_IN_MILLISECONDS)
		let isWorkingDay = checkIsWorkingDay(arrivalDate, imasWorkshopInfo.calendar)

		while (!isWorkingDay) {
			arrivalDate = new Date(arrivalDate.getTime() + DAY_IN_MILLISECONDS)
			isWorkingDay = checkIsWorkingDay(arrivalDate, imasWorkshopInfo.calendar)
		}

		return arrivalDate
	}

	const getGrossProfits = () => {
		return getTotalSale() - getTotalCost()
	}

	const getNetProfits = () => {
		return getGrossProfits() - getShipmentCost().reduce((accumulator, shipment) => accumulator + roundAtDecimals(shipment.cost, 2), 0)
	}

	return <Modal
		className="orderSimulationModal"
		isOpen={isOrderSimulationModalOpen}
		closeModal={closeModal}
		title={t('order-simulation', { ns: 'naming' })}
		body={
			partsToRender && partsToRender.length !== 0 ? (
				<div className="modal-body d-flex flex-column justify-content-center">
					<Select
						placeholder={t('select-supplier', { ns: 'naming' })}
						options={availableSuppliers.length != 0 ? parseSuppliers(availableSuppliers) : []}
						onChange={e => handleOnChangeDefaultSupplier(e.target.value)}
					/>
					<Row className="mt-2">
						{partsToRender.map((part) => {
							return <Col className="col-2 mb-2" key={`part_${part.id.value}`}>
								<SimulationPartCard part={part} defaultSupplier={defaultSupplier} suppliers={parseSuppliers(suppliers)} update={updatePartToSimulate} />
							</Col>
						})}
					</Row>
					<Row className="mx-0">
						<table id="simulationTable">
							<tr className="bg-gray">
								<th className="border p-2 w-25">{t('offer-date', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('production-term', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('arrival-date', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('total-sale', { ns: 'naming' })}</th>
							</tr>
							<tr>
								<td className="border p-2 w-25">{offerDate && getDateWithHour(offerDate)}</td>
								<td className="border p-2 w-25">{productionTerm != undefined && `${productionTerm} ${t('days', { ns: 'naming' })}`}</td>
								<td className="border p-2 w-25">{arrivalDate && getDate(arrivalDate)}</td>
								<td className="border p-2 w-25">{totalSale != undefined && `${roundCurrency(totalSale)}€`}</td>
							</tr>
							<tr className="bg-gray">
								<th className="border p-2 w-25">{t('total-cost', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('gross-profits', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('shipment-cost', { ns: 'naming' })}</th>
								<th className="border p-2 w-25">{t('net-profits', { ns: 'naming' })}</th>
							</tr>
							<tr>
								<td className="border p-2 w-25">{totalCost != undefined && `${roundCurrency(totalCost)}€`}</td>
								<td className={`border p-2 w-25 ${grossProfits <= 0 ? 'text-red' : 'text-green'}`}>{grossProfits != undefined && `${roundCurrency(grossProfits)}€`}</td>
								<td className="border p-2 w-25">{shipmentCost != undefined && shipmentCost.map((shipment) => { return <div key={shipment.supplier}>{shipment.supplierName}: {roundCurrency(shipment.cost)}€</div> })}</td>
								<td className={`border p-2 w-25 ${netProfits <= 0 ? 'text-red' : 'text-green'}`}>{netProfits != undefined && `${roundCurrency(netProfits)}€`}</td>
							</tr>
						</table>
					</Row>
				</div>
			) : (
				<div className="p-4"><h3>{t('at_least_one_valid_part', { ns: 'naming' })}</h3></div>
			)
		}
		buttons={[
			<button
				id="simulateButton"
				type="button"
				key="simulateButton"
				className="btn btn-primary"
				onClick={() => handleSimulation()}
				disabled={!readyToSimulate}
			>
				{(isSimulating || isLoading) ? <Loader className="mx-auto" /> : t('simulate', { ns: 'naming' })}
			</button>,
		]}
	/>
}

const mapStateToProps = state => {
	return {
		partPrices: state.Quotes.partPrices,
		partCosts: state.Quotes.partCosts,
		supplierShippingTiming: state.Users.supplierShippingTiming,
		supplierShipmentPricingList: state.Productions.supplierShipmentPricingList,
		isLoading: state.Quotes.isLoadingPartCosts || state.Quotes.isLoadingPartPrices,
		error: state.Quotes.error,
	}
}

OrderSimulationModal.propTypes = {
	t: PropTypes.func,
	isOrderSimulationModalOpen: PropTypes.bool,
	closeOrderSimulationModal: PropTypes.func,
	setModalMessage: PropTypes.func,
	parts: PropTypes.array,
	suppliers: PropTypes.array,
	parseSuppliers: PropTypes.func,
	offerDate: PropTypes.object,
	getPartPricesBySupplierId: PropTypes.func,
	partPrices: PropTypes.array,
	getPartCostsBySupplierId: PropTypes.func,
	partCosts: PropTypes.array,
	getSupplierInfo: PropTypes.func,
	supplierShippingTiming: PropTypes.object,
	getSupplierShipmentPricingList: PropTypes.func,
	supplierShipmentPricingList: PropTypes.array,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
}

export default connect(mapStateToProps, { getPartPricesBySupplierId, getPartCostsBySupplierId, getSupplierInfo, getSupplierShipmentPricingList })(withTranslation()(OrderSimulationModal))
