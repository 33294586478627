import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { Alert, Card, CardBody, Col, Container, Label, Row } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import AppRoutes from 'constants/appRoutes'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import MetaTags from 'react-meta-tags'
import * as Yup from 'yup'

// actions
import { apiError, loginSuccess, loginUser } from '../../store/actions'

import { isStringInList } from '../../common/Utils/StringUtilities'

import {
	USER_ROLE_BOSS,
	USER_ROLE_CUSTOMER,
	USER_ROLE_SUPPLIER,
} from '../../constants/userRoles'

import BrandLogo from '../../components/Common/BrandLogo'
import Footer from '../../components/Common/Footer'
import Loader from '../../components/Common/Loader'

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isShowingPassword: false,
		}
	}

	componentDidMount() {
		this.props.apiError('')
	}
	componentDidUpdate(prevProps) {
		if (this.props.roles.length > 0) {
			this.redirectLoggedUser()
		}
	}

	redirectLoggedUser() {
		if (isStringInList(USER_ROLE_CUSTOMER, this.props.roles)) {
			this.props.apiError('Customers can\'t login into this application')
			return
		}
		if (isStringInList(USER_ROLE_BOSS, this.props.roles)) {
			this.props.history.push(AppRoutes.OFFER_LIST_ROUTE)
			return
		}
		if (isStringInList(USER_ROLE_SUPPLIER, this.props.roles)) {
			this.props.history.push(AppRoutes.QUOTE_LIST_ROUTE)
			return
		}
		this.props.history.push(AppRoutes.OFFER_LIST_ROUTE)
	}

	render() {
		const { t } = this.props
		return (
			<React.Fragment>
				<MetaTags>
					<title>Proto&Go! | {t('login', { ns: 'naming' })}</title>
				</MetaTags>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark">
						<i className="bx bx-home h2" />
					</Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-black">
										<BrandLogo />
									</div>
									<CardBody className="pt-0 bg-black text-light">
										<div className="p-2">
											{this.props.error && this.props.error ? (
												<Alert color="danger">
													{t(this.props.error, { ns: 'errors' })}
												</Alert>
											) : null}
											<Formik
												enableReinitialize={true}
												initialValues={{
													email:
														(this.state && this.state.email) ||
														'',
													password:
														(this.state && this.state.password) || '',
												}}
												validationSchema={Yup.object().shape({
													email: Yup.string().required(
														'Please Enter Your Email',
													),
													password: Yup.string().required(
														'Please Enter Valid Password',
													),
												})}
												onSubmit={values => {
													this.props.loginUser(values, this.props.history)
												}}
											>
												{({ errors, status, touched }) => (
													<Form className="form-horizontal">
														<div className="mb-3 me-2">
															<Label for="email" className="form-label">
																Email
															</Label>
															<Field
																id="loginEmailInput"
																name="email"
																type="text"
																className={
																	'form-control' +
																	(errors.email && touched.email
																		? ' is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																id="loginEmailErrorDiv"
																name="email"
																component="div"
																className="invalid-feedback"
															/>
														</div>
														<div className="mb-3">
															<Label for="password" className="form-label">
																Password
															</Label>
															<div className="input-group auth-pass-inputgroup">
																<Field
																	id="loginPasswordlInput"
																	name="password"
																	type={
																		!this.state.isShowingPassword
																			? 'password'
																			: 'text'
																	}
																	autoComplete="true"
																	className={
																		'h-50 form-control' +
																		(errors.password && touched.password
																			? ' is-invalid'
																			: '')
																	}
																/>
																<button
																	className="btn btn-light w-auto"
																	type="button"
																	id="loginPassword-addon"
																	onClick={() =>
																		this.setState({
																			...this.state,
																			isShowingPassword:
																				!this.state.isShowingPassword,
																		})
																	}
																>
																	<i className="mdi mdi-eye-outline"></i>
																</button>
															</div>
															<ErrorMessage
																id="loginPasswordErrorDiv"
																name="password"
																component="div"
																className="invalid-feedback"
															/>
														</div>

														<div className="form-check">
															<input
																type="checkbox"
																className="form-check-input"
																id="loginCustomControlInline"
															/>
															<label
																className="form-check-label"
																htmlFor="customControlInline"
															>
																Remember me
															</label>
														</div>

														<div className="mt-3 d-grid">
															<button
																id="loginSubmitButton"
																className="btn btn-primary w-100  "
																type="submit"
																disabled={this.props.isLoading}
															>
																{this.props.isLoading ? (
																	<Loader className="font-size-18 mx-auto" />
																) : (
																	'Log In'
																)}
															</button>
														</div>
														<div className="mt-4 text-center">
															<Link
																id="loginForgotYourPasswordA"
																to="/forgot-password"
																className="text-primary"
															>
																<i className="mdi mdi-lock me-1" /> Forgot your
																password?
															</Link>
														</div>
													</Form>
												)}
											</Formik>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<Footer />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

Login.propTypes = {
	apiError: PropTypes.any,
	error: PropTypes.any,
	history: PropTypes.object,
	loginUser: PropTypes.func,
	socialLogin: PropTypes.func,
	loginSuccess: PropTypes.func,
	email: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.string),
	isLoading: PropTypes.bool,
	t: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		error: state.Login.error,
		roles: state.Login.roles,
		email: state.Login.email,
		isLoading: state.Login.isLoading,
	}
}

export default withRouter(
	connect(mapStateToProps, { loginUser, loginSuccess, apiError })(
		withTranslation()(Login),
	),
)
