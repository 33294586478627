import classNames from 'classnames'
import { roundCurrency } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import {
	Card,
	CardBody,
	Col,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap'
import {
	getSupplierDetail,
} from '../../store/actions'





const ProductionTabs = ({
	t,
	production,
	getSupplierDetail,
	supplierDetail
}) => {
	const [activeTab, setActiveTab] = useState('1')
	useEffect(() => {
		if (production) {
			getSupplierDetail(production.supplierId)
		}
	}, [])


	return (
		<div className="mt-4">
			<Card className="col-12 mt-1 mb-4">
				<CardBody>
					<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
						<NavItem>
							<NavLink
								className={classNames({ active: activeTab === '1' })}
								onClick={() => setActiveTab('1')}
							>
								{t('supplierNote', { ns: 'naming' })}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({ active: activeTab === '2' })}
								onClick={() => setActiveTab('2')}
							>
								{t('completeProductionFiles', { ns: 'naming' })}
							</NavLink>
						</NavItem>
					</ul>
					<TabContent activeTab={activeTab} className="p-3">
						<TabPane tabId="1">
							<div>
								{production.comment}
							</div>
						</TabPane>
						<TabPane tabId="2">
							{supplierDetail && supplierDetail.account && supplierDetail.account.personalInformation.country.toLowerCase() === 'cn' && <div className='d-flex'>
								<h5 className='mt-1'>{t('declaredValueInInvoice', { ns: 'naming' })} : </h5>
								<div className='ms-1 mt-1 fs-5'>
									{roundCurrency(production.declaredValue)} €
								</div>
							</div>}

							<Col>
								<div className="d-flex">
									<div className='mt-2'>
										<h5>{t('invoice', { ns: 'naming' })} :</h5>
									</div>
									{production.files.invoice && production.files.invoice.src ? <a
										href={production.files.invoice.src}
										target="_blank"
										rel="noopener noreferrer"
										download={production.files.invoice.name}

									>
										<i className="bx bxs-file-pdf fs-2 ms-1 mt-1" style={{ color: '#AD0B00' }}></i>
									</a> : <div className='ms-1 mt-2'><h5 style={{ color: 'rgba(0,0,0,0.39)' }}>{t('no_invoice', { ns: 'naming' })} </h5></div>}

								</div>
							</Col>
							<div>
								<div className='mt-2'>
									<h5>{t('photos', { ns: 'naming' })}</h5>
								</div>
								<div className='d-flex flex-wrap'>
									{production.files.photos.length > 0 ? production.files.photos.map((photo, index) => (
										<img
											key={index}
											src={photo.src}
											alt={photo.name}
											className="img-fluid m-2"
											style={{ maxHeight: '150px', maxWidth: '150px', objectFit: 'cover' }}
										/>
									)) : <div className='mt-2'><h5 style={{ color: 'rgba(0,0,0,0.39)' }}>{t('no_images', { ns: 'naming' })}</h5></div>}
								</div>
							</div>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</div>
	)
}
const mapStateToProps = state => {
	return {
		isLoading: state.Productions.isLoading,
		error: state.error,
		supplierDetail: state.Users.supplierDetail,
	}
}


ProductionTabs.propTypes = {
	t: PropTypes.func,
	production: PropTypes.object,
	getSupplierDetail: PropTypes.func,
	supplierDetail: PropTypes.object,
}
export default withRouter(connect(mapStateToProps, {
	getSupplierDetail
})(
	withTranslation()(ProductionTabs),
))
