import * as THREE from 'three'
import * as BGU from 'three/examples/jsm/utils/BufferGeometryUtils.js'

export const calculateModelProperties = (
	geometry,
) => {

	// Create a copy of the geometry to avoid modifying the original
	let workingGeometry = geometry.clone()

	// Ensure geometry has an index buffer
	if (!workingGeometry.index) {
		const positions = workingGeometry.getAttribute('position')
		const vertexCount = positions.count
		const indices = new Uint32Array(vertexCount)
		for (let i = 0; i < vertexCount; i++) {
			indices[i] = i
		}
		workingGeometry.setIndex(new THREE.BufferAttribute(indices, 1))
	}

	// Optimize geometry
	workingGeometry = BGU.mergeVertices(workingGeometry)
	workingGeometry.computeVertexNormals()
	workingGeometry = workingGeometry.toNonIndexed()

	// get bounding box
	const boundingBox = getBoundingBox(workingGeometry)
	if (!boundingBox) throw 'No bounding box'

	// get triangles
	const triangles = getGeometryTriangles(workingGeometry)

	// SIZE
	const size_unitless = getSize(boundingBox)

	// VOLUME
	const volume_unitless = Math.abs(triangles.reduce((acc, triangle) =>
		acc + getSignedVolumeOfTriangle(...triangle), 0))

	// AREA
	const area_unitless = triangles.reduce((acc, triangle) =>
		acc + calculateTriangleArea(...triangle), 0)

	return { size_unitless, volume_unitless, area_unitless }
}

const getBoundingBox = (geometry) => {
	geometry.computeBoundingBox()
	return geometry.boundingBox
}

const getSize = (boundingBox) => {
	const x = (boundingBox.max.x - boundingBox.min.x)
	const y = (boundingBox.max.y - boundingBox.min.y)
	const z = (boundingBox.max.z - boundingBox.min.z)
	return { x, y, z }
}

const getGeometryTriangles = (geometry) => {
	const positionAttribute = geometry.getAttribute('position')
	const trianglesSet = new Set()
	const triangles = []

	const vertexCount = positionAttribute.count
	for (let i = 0; i < vertexCount; i += 3) {
		const a = i
		const b = i + 1
		const c = i + 2

		const key = `${a}-${b}-${c}`
		if (trianglesSet.has(key)) continue
		trianglesSet.add(key)

		const vA = new THREE.Vector3().fromBufferAttribute(positionAttribute, a)
		const vB = new THREE.Vector3().fromBufferAttribute(positionAttribute, b)
		const vC = new THREE.Vector3().fromBufferAttribute(positionAttribute, c)

		triangles.push([vA, vB, vC])
	}
	return triangles
}

const calculateTriangleArea = (p1, p2, p3) => {
	const v1 = new THREE.Vector3().subVectors(p2, p1)
	const v2 = new THREE.Vector3().subVectors(p3, p1)
	const cross = new THREE.Vector3().crossVectors(v1, v2)
	return cross.length() / 2
}

const getSignedVolumeOfTriangle = (p1, p2, p3) => {
	const crossProduct = new THREE.Vector3().copy(p2).cross(p3)
	return p1.dot(crossProduct) / 6.0
}
