/**
 * Calculate chip volume.
 *
 * @param {{
 *   boundaryBoxVolume?: number,
 *   volume?: number
 * }} args
 * @returns {number}
 */
export const calculateChipVolume = (args) => {

	// Contemplate undefined values:

	const {
		boundaryBoxVolume,
		volume,
	} = args

	if (boundaryBoxVolume == null
		|| volume == null) {
		return 0
	}

	// Calculate:

	return boundaryBoxVolume - volume
}
