import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap'

//i18n
import { withTranslation } from 'react-i18next'

// users
import user1 from '../../../assets/images/users/avatar-1.jpg'

import { connect } from 'react-redux'
import { eraseSupplierIdView, logoutUser } from 'store/actions'

import { LOGIN_ROUTE } from 'constants/appRoutes'

const getUserName = () => {
	if (localStorage.getItem('authUser')) {
		const obj = JSON.parse(localStorage.getItem('authUser'))
		return obj
	}
}

class ProfileMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			menu: false,
			name: 'Admin',
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle() {
		this.setState(prevState => ({
			menu: !prevState.menu,
		}))
	}

	componentDidMount() {
		const userData = getUserName()
		if (userData) {
			this.setState({ name: userData.username })
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.success !== this.props.success) {
			const userData = getUserName()
			if (userData) {
				this.setState({ name: userData.username })
			}
		}

		if (prevProps.userEmail !== this.props.userEmail) {
			this.props.history.push(LOGIN_ROUTE)
		}
	}

	logout() {
		this.props.eraseSupplierIdView()
		this.props.logoutUser(this.props.userEmail)
	}

	render() {
		return (
			<React.Fragment>
				<Dropdown
					isOpen={this.state.menu}
					toggle={this.toggle}
					className="d-inline-block"
				>
					<DropdownToggle
						className="btn header-item"
						id="page-header-user-dropdown"
						tag="button"
					>
						<img
							className="rounded-circle header-profile-user"
							src={user1}
							alt="Header Avatar"
						/>{' '}
						<span className="d-none d-xl-inline-block ms-1">
							{this.state.name}
						</span>
						<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
					</DropdownToggle>
					<DropdownMenu className="dropdown-menu-end">
						<DropdownItem
							tag="a"
							onClick={e => {
								this.props.history.push('/profile')
							}}
						>
							<i className="bx bx-user font-size-16 align-middle ms-1" />
							{this.props.t('Profile')}
						</DropdownItem>
						<DropdownItem tag="a" href="/form-layouts">
							<span className="badge bg-success float-end">11</span>
							<i className="bx bx-wrench font-size-17 align-middle me-1" />
							{this.props.t('Settings')}
						</DropdownItem>
						<div className="dropdown-divider" />
						<span
							id="profileMenuLogoutSpan"
							className="dropdown-item cursor-pointer"
							onClick={() => this.logout()}
						>
							<i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
							<span>{this.props.t('Logout')}</span>
						</span>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		)
	}
}

ProfileMenu.propTypes = {
	t: PropTypes.any,
	success: PropTypes.string,
	userEmail: PropTypes.string,
	logoutUser: PropTypes.func,
	history: PropTypes.object,
	eraseSupplierIdView: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		userEmail: state.Login.email,
	}
}

export default withRouter(
	connect(mapStateToProps, { logoutUser, eraseSupplierIdView })(
		withTranslation()(ProfileMenu),
	),
)
