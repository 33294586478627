import SimpleTable from 'components/Common/SimpleTable'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
const RegressionsTable = ({ id, isVcPart, currentPartData }) => {

	const headers = ['quantities', 'partCost']
	if (isVcPart) {
		headers.push('moldCost', 'masterCost')
	}
	headers.push('unitCost', 'totalCost', 'margin_value', 'unitPrice', 'totalPrice', 'time')

	const getTableBody = () => {
		return currentPartData.regressionItems.map((regressionItem, index) => {
			return (
				<tr key={regressionItem.quantity}>
					<td>{regressionItem.quantity}</td>
					<td>{regressionItem.partCost}</td>
					{isVcPart && <td>{regressionItem.moldCost}</td>}
					{isVcPart && <td>{regressionItem.masterCost}</td>}
					<td>{regressionItem.partTotalUnitCost}</td>
					<td>{regressionItem.partTotalCost}</td>
					<td>{regressionItem.margin}</td>
					<td>{regressionItem.unitPrice}</td>
					<td>{regressionItem.totalPrice}</td>
					<td>{regressionItem.time}</td>
				</tr>
			)
		})
	}

	return (
		<SimpleTable id={id} header={headers} getTableBody={getTableBody} />

	)
}

RegressionsTable.propTypes = {
	t: PropTypes.func,
	partCosts: PropTypes.array,
	partPrices: PropTypes.array,
	partId: PropTypes.number,
	supplier: PropTypes.object,
	isVcPart: PropTypes.bool,
	headerToRender: PropTypes.element,
	regressionItems: PropTypes.array,
}

const mapStateToProps = (state) => ({
	partCosts: state.Quotes.partCosts,
	partPrices: state.Quotes.partPrices,

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegressionsTable))
