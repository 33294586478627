import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import TooltipMessage from 'components/Common/TooltipMessage'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export const MeasureWeightAlert = ({ t, size, weight, measure, id }) => {
	const isOverLimit = (value, limit) => {
		return value > limit
	}

	return (
		<div style={{ minHeight: '19.5px' }}>
			{size && size.x && size.y && size.z ? (
				<div id={id} className="d-flex align-items-center">
					{roundAtDecimals(weight, 1)} {measure}
					<span>

						{isOverLimit(size.x, 1000) || isOverLimit(size.y, 1000) || isOverLimit(size.z, 1000) || isOverLimit(weight, 25000) ? (
							<span>
								<TooltipMessage place="top" message={t('alert_part', { ns: 'naming' })}>
									<div>
										<div style={{ marginTop: '5px' }}>
											<i className="bx bxs-error text-warning ms-1"></i>
										</div>
									</div>
								</TooltipMessage>
							</span>
						) : null}
					</span>

				</div>
			) : (
				<div></div>
			)}
		</div>
	)
}

MeasureWeightAlert.propTypes = {
	t: PropTypes.func,
	size: PropTypes.object,
	weight: PropTypes.number,
	id: PropTypes.number,

}

export default withTranslation()(MeasureWeightAlert)
