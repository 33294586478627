import { useObjectState } from 'common/Hooks/UseObjectState'
import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import FileInput from 'components/Common/FileInput'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import { PartName } from 'components/Common/PartName'
import PartThumbnail from 'components/Common/PartThumbnail'
import TextInput from 'components/Common/TextInput'
import OrganizationFlag from 'pages/Orders/OrganizationFlag'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addQuatlityVerificationImages, createPartVerification, getOrderDetail, getOrderItemByOrderIdSearch, uploadQualityVerificationImages } from 'store/actions'
import { useHandleSearchOrderItems } from './hooks/useHandleSearchOrderItems'

export const CreateIncidenceModal = ({ t, incidences, isOpen, suppliers, closeModal, problems, solutions, isQualityIncidenceCreatedSuccessfully, createPartVerification, qualityVerification, uploadQualityVerificationImages, images, isLoading, addQuatlityVerificationImages, getOrderDetail, orderDetail, searchedOrderItems, getOrderItemByOrderIdSearch, isLoadingQuality }) => {

	const INIT_STATE = {
		orderId: null,
		partId: null,
		problem: null,
		solution: null,
		partsWrong: 0,
		observation: null,
		images: [],
		notifiedBy: 'client',
		orderFragmentId: undefined,
	}

	const [uniqueOrderIds, setUniqueOrderIds] = useState([])
	const [partIdsByOrderId, setPartIdsByOrderId] = useState({})
	const { handleOnChange, objectData, resetState, setObjectData } = useObjectState(INIT_STATE)

	const { orderItems } = useHandleSearchOrderItems(objectData.orderId, getOrderItemByOrderIdSearch)
	const [imageFiles, setImageFiles] = useState()
	const [step, setStep] = useState(1)
	const [partFound, setPartFound] = useState(false)
	const [saveIncidenceRequiredMessage, setSaveIncidenceRequiredMessage] = useState(null)



	const itemToRender = useMemo(() => {
		if (orderDetail.order == null) {
			return <div className='my-1 mx-2 p-4 d-flex flex-row align-items-center'>
				{objectData.orderId != null && <span className='col'>{t('no_order_found', { ns: 'naming' })}</span>}
			</div>
		}
		const orderItem = orderDetail.order?.orderItems?.find((orderItem) => orderItem.partId == objectData.partId)
		if (orderItem != null) setPartFound(true)
		return <div className='my-1 mx-2 p-4 d-flex flex-row align-items-center'>
			<span className='col-4'>
				<OrganizationFlag
					country={orderDetail.order.shippingAddress?.country?.toLowerCase() || ''}
					organizationName={orderDetail.organization.organizationName || ''}
					firstName={orderDetail.order.shippingAddress?.firstName || ''}
					lastName={orderDetail.order.shippingAddress?.lastName || ''}
					userId={orderDetail.order.accountId || ''}
				/>
			</span>
			{orderItem != null ? <>
				<span className='col'>{orderItem.part.id}</span>
				<span className='col'>
					<PartName
						name={orderItem.part.name}
					/>
				</span>
				<span className='col'>
					<PartThumbnail
						propsImageUrl={orderItem.part.fileLinks.thumbnail}
						propsGeometryUrl={orderItem.part.fileLinks.stlModel || orderItem.part.fileLinks.objModel}
						geometryData={{
							partId: orderItem.part.id,
							partName: orderItem.part.name,
							size: orderItem.part.size,
							weight: orderItem.part.weight,
						}}
					/>
				</span>
			</> : <span className='col'>{t('no_part_found', { ns: 'naming' })}</span>}
		</div>
	}, [orderDetail])

	useEffect(() => {
		if (!orderItems || !Array.isArray(orderItems)) {
			return
		}

		const validItems = orderItems.filter(item => item && item.orderId !== null)
		const orderIds = validItems.map(item => item.orderId)
		const uniqueIds = [...new Set(orderIds)]
		setUniqueOrderIds(uniqueIds)

		const partsMap = uniqueIds.reduce((acc, orderId) => {
			const partIds = validItems
				.filter(item => item.orderId === orderId)
				.map(item => item.partId)
			acc[orderId] = partIds
			return acc
		}, {})

		setPartIdsByOrderId(partsMap)
	}, [orderItems])




	const parseOptions = options =>
		options.map(option => {
			return {
				value: option.id,
				label: t(option.name, { ns: 'quality' }),
			}
		})

	const problemsOptions = parseOptions(problems)
	const solutionsOptions = parseOptions(solutions)
	const notifiedOptions = [
		{
			value: 'quality',
			label: t('quality', { ns: 'naming' }),
		},
		{
			value: 'client',
			label: t('client', { ns: 'naming' }),
		},
	]

	const partHasMultipleProductionItems = () => {
		const allProductionItems = orderDetail.order.productions?.flatMap(({ productionItems }) => productionItems.map(({ part }) => part.id))
		return allProductionItems?.filter(id => id === objectData.partId).length > 1
	}

	const getQuantityList = () => {
		const { quantity } = orderDetail.order.orderItems.find((orderItem) => orderItem.partId == objectData.partId)
		return [0, ...Array(quantity).fill().map((element, index) => index + 1)]
	}

	const close = () => {
		resetState()
		closeModal()
		setStep(1)
	}

	const getOrderFragmentId = () => {
		const haveMultipleProductionItems = partHasMultipleProductionItems()
		if (!haveMultipleProductionItems) {
			const orderFragmentIdFind = orderDetail.order.productions.find(({ productionItems }) => productionItems.find(({ part }) => part.id == objectData.partId))?.orderFragmentId
			if (orderFragmentIdFind) {
				return orderFragmentIdFind
			} else {
				return orderDetail.order.productions[0]?.orderFragmentId || null
			}

		}
		return orderDetail.order.productions.find(({ productionItems }) => productionItems.find(({ part }) => part.id == objectData.partId)).orderFragmentId
	}

	const getProductionsOptions = () => {
		return orderDetail.order.productions?.map(({ orderId, orderFragmentId, supplierId }) => ({
			value: orderFragmentId,
			label: `${orderId}-${orderFragmentId} ${suppliers.find(({ id }) => id === supplierId)?.personalInformation?.firstName}`,
		}))
	}


	const getOrdersOptions = () => {

		if (orderItems) {
			if (orderItems.length < 80)
				return uniqueOrderIds?.map((order) => ({
					value: order,
					label: `${order}`
				})) || []
		}

	}

	const getPartsOptionsByOrderSelected = () => {
		if (objectData.orderId == null) {
			return []
		}
		const partIds = partIdsByOrderId[objectData.orderId]
		return partIds?.map(partId => ({
			value: partId,
			label: `${partId}`
		})) || []
	}

	useEffect(() => {
		const resultGetPartsOptions = getPartsOptionsByOrderSelected()
	}, [objectData.orderId])

	const saveIncidence = () => {
		if (objectData.observation == null || objectData.observation == '') {
			setSaveIncidenceRequiredMessage(t('field-observation-empty', { ns: 'naming' }))
		}
		else {
			const partsOK = orderDetail.order.orderItems.find((orderItem) => orderItem.partId == objectData.partId).quantity - objectData.partsWrong
			const agent = getUserValuesFromLocalStorage().id

			const incidenceInput = {
				qualityPartState: {
					partId: Number(objectData.partId),
					orderId: Number(objectData.orderId),
					quantity: Number(partsOK) + Number(objectData.partsWrong),
					state: false,
				},
				qualityVerification: {
					partsOK,
					partsWrong: Number(objectData.partsWrong),
					problem: objectData.problem,
					agent,
					solution: objectData.solution,
					notifiedBy: objectData.notifiedBy,
					observation: objectData.observation,
					orderFragmentId: getOrderFragmentId(),
				},
			}
			createPartVerification({ data: incidenceInput })
		}

	}

	useEffect(() => {
		if (qualityVerification != null && imageFiles != null) {
			const imagesInput = {
				data: {
					qualityVerificationId: qualityVerification.id,
					partId: qualityVerification.partId,
				},
				images: imageFiles,
			}
			uploadQualityVerificationImages(imagesInput)
		} else {
			close()
		}
	}, [qualityVerification])

	useEffect(() => {
		if (images.length != 0 && qualityVerification) {
			addQuatlityVerificationImages({ orderId: objectData.orderId, qualityVerificationId: qualityVerification.id, images })
			close()
		}
	}, [images, qualityVerification])

	return (
		<>
			<Modal
				className="modal-lg"
				isOpen={isOpen}
				closeModal={close}
				title={`${t('register-incidence', { ns: 'naming' })}`}
				body={
					<div className="px-4 py-2 d-flex flex-column">
						{step === 1 && <>
							<div className="d-flex flex-row align-items-center">
								<OptionsInput
									className="w-100"
									value={objectData.orderId}
									onInputChange={(newValue, { action }) => {
										if (action === 'input-change') {
											setObjectData(prev => ({
												...prev,
												orderId: newValue
											}))
										}
									}}
									setValue={handleOnChange}
									objectElement='orderId'
									options={getOrdersOptions()}
									automaticMappedValue
									title={t('order_id', { ns: 'naming' })}
								/>

								<OptionsInput
									className="w-100"
									value={[]}
									objectElement="partId"
									onInputChange={(newValue, { action }) => {
										if (action === 'input-change') {
											setObjectData(prev => ({
												...prev,
												partId: newValue
											}))
										}
									}}
									setValue={handleOnChange}
									options={getPartsOptionsByOrderSelected()}
									automaticMappedValue
									title={t('part_id', { ns: 'naming' })}
								/>

								<button
									className='btn btn-primary mt-4'
									disabled={objectData.orderId == null || objectData.partId == null || isLoading}
									onClick={() => {
										getOrderDetail(objectData.orderId)
										setPartFound(false)
									}}
								>{isLoading ? <Loader /> : t('search', { ns: 'naming' })}</button>
							</div>
							<div>
								{itemToRender}
							</div>
						</>}
						{step === 2 && <>
							{
								partHasMultipleProductionItems() && (
									<OptionsInput
										className="w-100"
										value={objectData.orderFragmentId}
										objectElement="orderFragmentId"
										setValue={handleOnChange}
										options={getProductionsOptions()}
										automaticMappedValue
										title={t('incidence', { ns: 'naming' })}
									/>
								)
							}
							<div className="d-flex flex-row">
								<OptionsInput
									className="w-50"
									value={objectData.problem}
									objectElement="problem"
									setValue={handleOnChange}
									options={problemsOptions}
									automaticMappedValue
									isRequired={true}
									title={t('incidence', { ns: 'naming' })}
								/>
								<OptionsInput
									className="w-50"
									value={objectData.solution}
									objectElement="solution"
									setValue={handleOnChange}
									options={solutionsOptions}
									automaticMappedValue
									isRequired={true}
									title={t('solution', { ns: 'naming' })}
								/>
							</div>
							<div className="d-flex flex-row">
								<TextInput
									className="w-50"
									value={objectData.observation}
									objectElement="observation"
									setValue={handleOnChange}
									title={t('observation', { ns: 'naming' })}
									type="text"
									isRequired={true}
								/>
								<OptionsInput
									className="w-50"
									value={objectData.partsWrong}
									objectElement="partsWrong"
									setValue={handleOnChange}
									automaticMappedValue
									title={t('parts-wrong', { ns: 'naming' })}
									type="number"
									onBlur={value => {
										if (value == '') handleOnChange('partsWrong', 0)
									}}
									options={getQuantityList()}
								/>
							</div>
							<div className="d-flex flex-row">
								<FileInput
									className="w-50"
									value={objectData.images}
									objectElement="images"
									setValue={handleOnChange}
									setFiles={setImageFiles}
									title={t('images', { ns: 'naming' })}
									multiple
									accept="image/*"
								/>
								<OptionsInput
									className="w-50"
									value={objectData.notifiedBy}
									objectElement="notifiedBy"
									setValue={handleOnChange}
									options={notifiedOptions}
									automaticMappedValue
									title={t('notified-by', { ns: 'naming' })}
								/>
							</div>
						</>}
					</div>
				}
				buttons={[
					step === 1 && (<button
						type="button"
						key="continueButton"
						className="btn btn-primary"
						disabled={!partFound}
						onClick={e => {
							setStep(2)
						}}
					>
						{t('continue', { ns: 'naming' })}
					</button>),
					step === 2 && (<button
						type="button"
						key="saveButton"
						className="btn btn-primary"
						onClick={e => {
							saveIncidence()
						}}
						disabled={objectData.partsWrong == 0 || objectData.problem == null || objectData.solution == null}
					>
						{isLoadingQuality ? <Loader /> : t('save', { ns: 'naming' })}
					</button>),
				]}
			/>
			<ModalMessage
				isModalOpen={saveIncidenceRequiredMessage !== null || saveIncidenceRequiredMessage == ''}
				message={saveIncidenceRequiredMessage}
				closeModal={() => { setSaveIncidenceRequiredMessage(null) }}
			/>

			<ModalMessage
				closeModal={() => location.reload()}
				isModalOpen={isQualityIncidenceCreatedSuccessfully !== null}
				message={isQualityIncidenceCreatedSuccessfully ? t("quality_incidence_created_successfully", { ns: "naming" }) : t("quality_incidence_created_error", { ns: "naming" })}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		problems: state.Orders.qualityProblems,
		solutions: state.Orders.qualitySolutions,
		qualityVerification: state.Orders.qualityVerification,
		searchedOrderItems: state.Orders.searchedOrderItems,
		images: state.CloudStorage.images,
		isLoading: state.Orders.isLoading || state.CloudStorage.isLoading,
		suppliers: state.Users.suppliers,
		orderDetail: state.Orders.orderDetail,
		isQualityIncidenceCreatedSuccessfully: state.Orders.isQualityIncidenceCreatedSuccessfully,
		isLoadingQuality: state.Orders.isLoadingQuality,
	}
}

CreateIncidenceModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	problems: PropTypes.array,
	solutions: PropTypes.array,
	createPartVerification: PropTypes.func,
	searchedOrderItems: PropTypes.array,
	getOrderItemByOrderIdSearch: PropTypes.func,
	qualityVerification: PropTypes.object,
	uploadQualityVerificationImages: PropTypes.func,
	images: PropTypes.bool,
	isLoading: PropTypes.bool,
	isLoadingQuality: PropTypes.bool,
	addQuatlityVerificationImages: PropTypes.func,
	suppliers: PropTypes.array,
	getOrderDetail: PropTypes.func,
	orderDetail: PropTypes.object,
	isQualityIncidenceCreatedSuccessfully: PropTypes.bool,
}

export default connect(mapStateToProps, { createPartVerification, uploadQualityVerificationImages, addQuatlityVerificationImages, getOrderDetail, getOrderItemByOrderIdSearch })(withTranslation()(CreateIncidenceModal))
