import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const SimpleTable = ({ id, getTableBody, header, striped = true, t }) => {

	const getHeaderToRender = () => {
		return (
			<tr className="sticky bg-white">
				{header.map(element => {
					return (<th key={`header_${element}`} scope="col">{t(element, { ns: 'naming' })}</th>)
				})}
			</tr>
		)
	}

	return (
		<div>
			<table id={id} className={`table ${striped && 'table-striped'} bg-white table-no-background`}>
				<thead>
					{getHeaderToRender()}
				</thead>
				<tbody>{getTableBody()}</tbody>
			</table>
		</div>
	)
}

SimpleTable.propTypes = {
	getTableBody: PropTypes.func,
	header: PropTypes.array,
	striped: PropTypes.bool,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SimpleTable))
