import { inferMeasure } from 'common/Utils/inferMeasure'
import { recalculatePartCadData } from 'common/Utils/recalculatePartCadData'
import { getGeometryData } from 'common/Utils/threejsUtils/getGeometryData'
import { Unit } from 'common/Utils/UnitConversionUtils'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PartCard from 'components/Common/PartCard/PartCard'
import PartThumbnail from 'components/Common/PartThumbnail'
import { FileUploadButton } from 'components/FileUploadButton'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { deleteOldPartFiles, updateParts, uploadPartFiles, uploadToCad } from 'store/actions'

export const ACCEPTED_3D_FORMATS = [
	'step',
	'stp',
	'sldprt',
	'ipt',
	'prt',
	'sat',
	'catpart',
	'x_t',
	'x_b',
	'3dm',
	'stl',
	'obj',
]

const Update3DModelModal = (props) => {

	const {
		// props given by parent:
		isOpen,
		closeModal,
		selectedRows,
		allParts,

		// props from withTranslation:
		t,

		// props from connect mapStateToProps:
		uploadPartFilesState,
		uploadToCadState,
		deleteOldPartFilesState,
		updatePartsState,
		partConfigOptions,

		// props from connect mapDispatchToProps:
		uploadPartFiles,
		uploadToCad,
		deleteOldPartFiles,
		updateParts,

	} = props

	// logic:
	const rowSelected = selectedRows?.[0]
	const partId = rowSelected?.id.value
	const [status, setStatus] = useState()
	const [fileExtension, setFileExtension] = useState()

	// files:
	const [fileSelected, setFileSelected] = useState()

	// data shown in the front end:
	const isLoading = ['processing_file', 'updating_database', 'uploading', 'deleting_old_files'].includes(status)
	const [cadData, setCadData] = useState()
	const [weight_g, setWeight_g] = useState()
	const [unit, setUnit] = useState(Unit.inches)
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

	/**
	 * Guia:
	 * -----
	 * handleSelectFile() - al seleccionar archivo:
	 * utiliza getGeometryData() o uploadToCad() para procesar el archivo
	 * 
	 * RAMA STL/OBJ:
	 * 
	 * si utiliza getGeometryData:
	 * al terminar de procesar el archivo, asigna valor a geometryData
	 * y muestra el selector de unidad de medida
	 * 
	 * handleSelectMeasurementUnit() - al seleccionar la unidad de medida y confirmar:
	 * sube la pieza al cloud storage con uploadPartFiles()
	 * 
	 * al obtener respuesta de uploadPartFiles:
	 * utiliza geometryData + la unidad seleccionada para actualizar la pieza en base de datos con updateParts()
	 * 
	 * RAMA OTHER:
	 * 
	 * al obtener respuesta de uploadToCad:
	 * utiliza esos datos para actualizar la pieza en base de datos con updateParts()
	 * 
	 * AMBAS:
	 * 
	 * al obtener respuesta de updateParts:
	 * elimina los archivos restantes con deleteOldPartFiles()
	 * 
	 * al obtener respuesta de deleteOldPartFiles:
	 * muestra un mensaje de success
	 */

	const helperCalculatePartWeight_g = (cadData) => {
		if (cadData?.volume_cm3 == null) return
		const part = allParts.find(part => part.id == partId)
		const materialDensity_g_per_cm3 = partConfigOptions.fields.material.options[part.config.material].density
		const weight_g = cadData.volume_cm3 * materialDensity_g_per_cm3
		setWeight_g(weight_g)
		return weight_g
	}

	const helperUpdateDatabase = (cadData) => {
		setStatus('updating_database')
		updateParts({
			parts: [{
				id: partId,
				name: fileSelected.name,
				size: cadData.size_mm,
				volume: cadData.volume_cm3,
				area: cadData.area_cm2,
				faces: cadData.faces,
				process: cadData.process,
				boundaryBoxVolume: cadData.boundaryBoxVolume_cm3,
				chipVolume: cadData.chipVolume_cm3,
				weight: cadData.weight_g,
				did: cadData.did,
				eid: cadData.eid,
				tid: cadData.tid,
				wid: cadData.wid,
			}],
		})
	}

	useEffect(() => {
		if (uploadPartFilesState?.status === 'finished') {
			if (uploadPartFilesState.error) {
				setStatus('error')
				setIsErrorModalOpen(true)
				return
			}
			helperUpdateDatabase({
				...cadData,
				// set to null in database (delete value):
				faces: null,
				process: null,
				did: null,
				eid: null,
				tid: null,
				wid: null,
			})
		}
	}, [uploadPartFilesState])

	useEffect(() => {
		if (uploadToCadState?.status === 'finished') {
			if (uploadToCadState.error) {
				setStatus('error')
				setIsErrorModalOpen(true)
				return
			}

			// Calcular boundaryBoxVolume:
			const { x, y, z } = uploadToCadState.response.boundingBox || {}
			const boundaryBoxVolume_mm3 = x * y * z
			const boundaryBoxVolume_cm3 = boundaryBoxVolume_mm3 / 1000

			const cadData = {
				size_mm: uploadToCadState.response.boundingBox,
				volume_cm3: uploadToCadState.response.volume,
				area_cm2: uploadToCadState.response.surface,
				faces: uploadToCadState.response.faces,
				process: uploadToCadState.response.constructionProcess,
				boundaryBoxVolume_cm3,
				chipVolume_cm3: boundaryBoxVolume_cm3 - uploadToCadState.response.volume,
				did: uploadToCadState.response.did,
				eid: uploadToCadState.response.eid,
				tid: uploadToCadState.response.tid,
				wid: uploadToCadState.response.wid,
			}

			helperUpdateDatabase(cadData)
		}
	}, [uploadToCadState])

	useEffect(() => {
		if (deleteOldPartFilesState?.status === 'finished') {
			if (deleteOldPartFilesState.error) {
				setStatus('error')
				setIsErrorModalOpen(true)
				return
			}
			setStatus('success') // show success modal?
			setIsSuccessModalOpen(true)
		}
	}, [deleteOldPartFilesState])

	useEffect(() => {
		if (updatePartsState?.status === 'finished') {
			if (updatePartsState.error) {
				setStatus('error')
				setIsErrorModalOpen(true)
				return
			}
			setStatus('deleting_old_files')
			deleteOldPartFiles({
				body: {
					data: {
						partId,
					},
				},
			})
		}
	}, [updatePartsState])

	const handleSelectFile = async (file) => {
		setFileSelected(file)

		const fileExtension = file.name.toLowerCase().split('.').pop()
		setFileExtension(fileExtension)

		switch (fileExtension) {
			case 'stl':
			case 'obj': {
				setStatus('processing_file')
				const cadData = await getGeometryData(file)
				const inferedUnit = inferMeasure(cadData.size_unitless)
				const fullCadData = recalculatePartCadData({
					size: { value: cadData.size_unitless, unit: inferedUnit },
					area: { value: cadData.area_unitless, unit: inferedUnit },
					volume: { value: cadData.volume_unitless, unit: inferedUnit },
				})
				setCadData({
					file: file,
					...cadData,
					...fullCadData,
					weight_g: helperCalculatePartWeight_g(fullCadData),
				})
				setStatus('selecting_measurement_unit')
				break
			}
			default: {
				setStatus('processing_file')
				uploadToCad({
					body: {
						data: { partId },
						files: { file },
					},
				})
			}
		}
	}

	const handleSelectMeasurementUnit = (unit) => {
		setUnit(unit)

		const recalculatedPartCadData = recalculatePartCadData({
			size: { value: cadData.size_unitless, unit },
			area: { value: cadData.area_unitless, unit },
			volume: { value: cadData.volume_unitless, unit },
		})
		const weight_g = helperCalculatePartWeight_g(recalculatedPartCadData)
		const newCadData = {
			...cadData,
			...recalculatedPartCadData,
			weight_g,
		}
		setCadData(newCadData)

		const weight = helperCalculatePartWeight_g(newCadData)
		setWeight_g(weight)
	}

	const handleAcceptMeasurementUnit = () => {
		setStatus('uploading')
		uploadPartFiles({
			body: {
				data: {
					partId: partId,
					filename: fileSelected.name,
				},
				files: {
					thumbnail: cadData.imageUrl,
					modelStl: fileExtension === 'stl' ? fileSelected : undefined,
					modelObj: fileExtension === 'obj' ? fileSelected : undefined,
				},
			},
		})
	}

	return useMemo(() => (
		<>
			<Modal
				className="update-3d-model-modal"
				isOpen={isOpen}
				closeModal={closeModal}
				onClose={() => {
					setStatus(undefined)
					setFileExtension(undefined)
					setFileSelected(undefined)
					setCadData(undefined)
					setWeight_g(undefined)
					setUnit(Unit.inches)
					setIsErrorModalOpen(false)
				}}
				title={t('upload_new_version', { ns: 'naming' })}
				disableInteractions={isLoading || status === 'continue'}
				dontCloseOnBackgroundClick={isLoading || status === 'continue'}
				body={
					<div className="m-2">
						{selectedRows.length == 1 ? (
							<div className="d-flex justify-content-center">
								<div
									key={rowSelected.id.value}
									className='d-flex flex-column justify-content-center align-items-center gap-4'
								>
									{/* Information about the part: */}
									<PartCard
										img={rowSelected.fileLinks.thumbnail}
										stl={rowSelected.fileLinks.stlModel}
										stlData={{
											partId: rowSelected.id.value,
											partName: rowSelected.name,
											size: rowSelected.size,
											weight: rowSelected.weight,
										}}
										partId={rowSelected.id.value}
										fileName={rowSelected.name}
										materialColorFinish={(
											<>
												{rowSelected.material}
												{rowSelected.finishing}
											</>
										)}
										technology={rowSelected.technology}
										size={rowSelected.size}
									/>

									{/* Button to upload new 3D model: */}
									<FileUploadButton
										additionalButtonProps={{
											disabled: isLoading,
											style: {
												width: '100%',
												cursor: isLoading ? 'wait' : 'pointer',
												background: isLoading ? 'gray' : '#007bff',
												color: 'white',
												padding: '8px 16px',
												borderRadius: '4px',
												border: 'none',
											},
											children: (
												isLoading ? (
													<div className='d-flex justify-content-center align-items-center gap-1'>
														<Loader />
														{t(status, { ns: 'naming' })}
													</div>
												) : (
													t('upload_new_version', { ns: 'naming' })
												)
											),
										}}
										additionalInputProps={{
											disabled: isLoading,
											accept: ACCEPTED_3D_FORMATS.map(x => `.${x}`).join(','),
											multiple: false,
											onChange: (e) => {
												if (e.target.files && e.target.files[0]) {
													const file = e.target.files[0]
													handleSelectFile(file)
												}
												if (e.currentTarget) e.currentTarget.value = null
											},
										}}
									/>

									{(status == 'selecting_measurement_unit' && cadData != null) && (
										// Measurement Unit Selector:
										<div className='d-flex justify-content-center align-items-center gap-4 w-100'>
											{/* STL CAD data: */}
											<div className="p-3 border w-100">
												{/* Container for all items */}
												<div className="d-flex flex-column gap-3">
													{/* Size */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('size', { ns: 'naming' })}:</span>
														<span></span>
													</div>
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold"></span>
														<span>{cadData.size_mm.x} * {cadData.size_mm.y} * {cadData.size_mm.z} mm</span>
													</div>

													{/* Volume */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('volume', { ns: 'naming' })}:</span>
														<span>{cadData.volume_cm3} cm³</span>
													</div>

													{/* Area */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('area', { ns: 'naming' })}:</span>
														<span>{cadData.area_cm2} cm²</span>
													</div>

													{/* Boundary Box Volume */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('boundaryBoxVolume', { ns: 'naming' })}:</span>
														<span>{cadData.boundaryBoxVolume_cm3} cm³</span>
													</div>

													{/* Chip Volume */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('chipVolume', { ns: 'naming' })}:</span>
														<span>{cadData.chipVolume_cm3} cm³</span>
													</div>

													{/* Weight */}
													<div className="d-flex justify-content-between gap-4">
														<span className="fw-bold">{t('weight', { ns: 'naming' })}:</span>
														<span>{cadData.weight_g} g</span>
													</div>
												</div>
											</div>
											<PartThumbnail
												propsImageUrl={cadData.imageUrl}
												propsGeometryUrl={cadData.fileUrl}
												geometryData={cadData}
												hideData={true}
												extension={fileExtension}
											/>
											<div className='d-flex flex-column justify-content-center align-items-center gap-4'>
												{/* Dropdown STL measurement unit: */}
												<select onChange={e => {
													const unit = e.currentTarget.value
													handleSelectMeasurementUnit(unit)
												}}>
													<option value='inches'>{t('inches', { ns: 'naming' })}</option>
													<option value='millimeters'>{t('millimeters', { ns: 'naming' })}</option>
													<option value='centimeters'>{t('centimeters', { ns: 'naming' })}</option>
													<option value='meters'>{t('meters', { ns: 'naming' })}</option>
												</select>
												{/* Button to continue processing STL: */}
												<button
													className="btn bg-secondary text-white p-1 rounded w-auto"
													disabled={[null, 'success', 'error'].includes(status)}
													onClick={handleAcceptMeasurementUnit}
												>
													{t('continue', { ns: 'naming' })}
												</button>

											</div>

										</div>
									)}
								</div>
							</div>
						) : (
							// You can only update the 3D model of 1 part at a time:
							<div className="modal-body">
								{t('select_only_one_part', { ns: 'naming' })}
							</div>
						)}
					</div>
				}
			/>
			<ModalMessage
				isModalOpen={isErrorModalOpen}
				closeModal={() => {
					setIsErrorModalOpen(false)
				}}
				message={t('error', { ns: 'naming' })}
			/>
			<ModalMessage
				isModalOpen={isSuccessModalOpen}
				closeModal={() => {
					history.go(0)
				}}
				message={t('update_success', { ns: 'naming' })}
			/>
		</>

	), [
		isOpen,
		t,
		status,
		isErrorModalOpen,
		cadData,
	])
}

Update3DModelModal.propTypes = {

	// props given by parent:
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	selectedRows: PropTypes.array,
	allParts: PropTypes.array,

	// props from withTranslation:
	t: PropTypes.func,

	// props from connect mapStateToProps:
	uploadPartFilesState: PropTypes.object,
	uploadToCadState: PropTypes.object,
	deleteOldPartFilesState: PropTypes.object,
	updatePartsState: PropTypes.object,
	partConfigOptions: PropTypes.object,

	// props from connect mapDispatchToProps:
	uploadPartFiles: PropTypes.func,
	uploadToCad: PropTypes.func,
	deleteOldPartFiles: PropTypes.func,
	updateParts: PropTypes.func,
}

const mapStateToProps = state => ({
	uploadPartFilesState: state.CloudStorage.uploadPartFilesState,
	uploadToCadState: state.CloudStorage.uploadToCadState,
	deleteOldPartFilesState: state.CloudStorage.deleteOldPartFilesState,
	updatePartsState: state.Parts.updatePartsState,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {
	uploadPartFiles,
	uploadToCad,
	deleteOldPartFiles,
	updateParts,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Update3DModelModal))
