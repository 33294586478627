import { Mesh } from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { calculateModelProperties } from './calculateModelProperties'
import { generatePreviewImage } from './generatePreviewImage'

/**
* @returns {Promise<{
	fileUrl: string,
*   imageUrl: string,
*   size_unitless: {
*     x: number,
*     y: number,
*     z: number
*   },
*   volume_unitless: number,
*   area_unitless: number,
* }>}
*/
export const getGeometryData = (file) => {

	const fileNameLowercase = file.name.toLowerCase()

	if (fileNameLowercase.endsWith('.stl')) {
		return getStlModelData(file)
	} else if (fileNameLowercase.endsWith('.obj')) {
		return getObjModelData(file)
	} else {
		throw new Error(`Could not get geometry data of ${file.name}`)
	}
}

const getStlModelData = (file) => {
	const fileUrl = URL.createObjectURL(file)
	const loader = new STLLoader()

	return new Promise((resolve, reject) => {
		loader.load(
			//url
			fileUrl,
			//onLoad
			(geometry) => {
				const properties = calculateModelProperties(geometry)
				const imageUrl = generatePreviewImage(geometry)

				const data = {
					...properties,
					extension: 'stl',
					fileUrl,
					imageUrl,
				}

				resolve(data)
			},
			//onProgress
			undefined,
			//onError
			(error) => {
				reject(error)
			},
		)
	})
}

const getObjModelData = (file) => {
	const fileUrl = URL.createObjectURL(file)
	const loader = new OBJLoader()

	return new Promise((resolve, reject) => {
		loader.load(
			//url
			fileUrl,
			//onLoad
			(object) => {
				// Extract the first mesh's geometry from the loaded object
				let geometry = null

				const meshes = []

				object.traverse((child) => {
					if (child instanceof Mesh) meshes.push(child)
				})

				if (meshes.length == 0) {
					reject(new Error('No valid geometry found in OBJ file'))
					return
				}

				if (meshes.length > 1) {
					reject(new Error('Multiple mesh found in OBJ file'))
					return
				}

				geometry = meshes[0].geometry

				const properties = calculateModelProperties(geometry)
				const imageUrl = generatePreviewImage(geometry)

				const data = {
					...properties,
					extension: 'obj',
					fileUrl,
					imageUrl,
				}

				resolve(data)
			},
			//onProgress
			undefined,
			//onError
			(error) => {
				reject(error)
			},
		)
	})
}
