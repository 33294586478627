import Loader from 'components/Common/Loader'
import { USER_ROLE_BOSS, USER_ROLE_MANAGER } from 'constants/userRoles'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Flag } from 'semantic-ui-react'
import { getManagerDetailById, getManagerList } from 'store/actions'
import avatar9 from '../../../assets/images/users/no-avatar.jpg'

export const ManagerInfo = ({ getManagerDetailById, managerDetail, getManagerList, managerList, error, t, isLoading, onManagerSelection, isLoadingManagers }) => {

	const [selectedManagers, setSelectedManagers] = useState([])
	const [managerDetails, setManagerDetails] = useState({})

	const getUserName = () => {
		if (localStorage.getItem('authUser')) {
			const obj = JSON.parse(localStorage.getItem('authUser'))
			return obj
		}
	}


	useEffect(() => {
		const userData = getUserName()
		if (userData.roles.includes(USER_ROLE_BOSS)) {
			getManagerList()
		} else if (userData.roles.includes(USER_ROLE_MANAGER)) {
			getManagerList()
			getManagerDetailById(userData.id)
			setSelectedManagers([{ id: userData.id }])

		} else {
			//TODO ADD ERROR NO MANAGER
		}
	}, [])

	useEffect(() => {
		if (managerDetail) {
			setManagerDetails((prevDetails) => ({
				...prevDetails,
				[managerDetail.id]: managerDetail
			}))
		}
	}, [managerDetail])


	const handleManagerClick = (manager) => {
		const isSelected = selectedManagers.some((m) => m.id === manager.id)

		if (isSelected) {
			const newSelectedManagers = selectedManagers.filter((m) => m.id !== manager.id)
			setSelectedManagers(newSelectedManagers)
			onManagerSelection(newSelectedManagers.map((m) => m.id))
		} else {
			const newSelectedManagers = [...selectedManagers, manager]
			setSelectedManagers(newSelectedManagers)
			getManagerDetailById(manager.id)
			onManagerSelection(newSelectedManagers.map((m) => m.id))
		}
	}


	const combinedLanguages = Array.from(new Set(selectedManagers.flatMap((m) => managerDetails[m.id]?.languages || [])))
	const managerStyle = selectedManagers.length === 1 ? { marginLeft: "0", marginTop: "12px" } : { justifyContent: "flex-start", marginLeft: "45px", marginTop: "10px" }


	return (
		<div>
			{isLoading || isLoadingManagers ? (
				<div style={{ display: "flex", height: "90px", width: "490px", flexWrap: "wrap", marginBottom: "-45px", justifyContent: "flex-end" }}>
					<Loader />
				</div>

			) : (
				<div className='d-flex flex-wrap justify-content-end mb-3 mt-2'>
					{managerList.slice(0, 4).map((manager) => {
						const isSelected = selectedManagers.some((m) => m.id === manager.id)
						const profilePicture = manager.personalInformation.profilePicture || avatar9
						return (
							<div
								key={manager.id}
								style={{ border: isSelected ? '2px solid blue' : '2px solid white', height: "38px", borderRadius: "50%" }}
								onClick={() => handleManagerClick(manager)}

							>
								<img src={profilePicture} width="35px" height="35px" style={{ borderRadius: "50%" }} alt={`${manager.personalInformation.firstName} ${manager.personalInformation.lastName}`} />
							</div>
						)
					})}
				</div>
			)}
			{selectedManagers.length > 0 && (
				<div>
					<div className='d-flex flex-row' style={managerStyle}>
						{selectedManagers.map((manager, index) => {
							const details = managerDetails[manager.id]
							return (
								details && (
									<div style={{ marginTop: "-26px" }}>
										<img
											key={manager.id}
											src={(details.profileImage && details.profileImage.src) || avatar9}
											width="75px"
											height="75px"
											style={{ borderRadius: "50%", marginRight: "10px", marginLeft: "-10px" }}
											alt={`${details.personalInformation.firstName} ${details.personalInformation.lastName}`}
										/>
										{selectedManagers.length === 1 && (
											<div style={{ marginTop: "10px" }}>
												<p style={{ fontFamily: "sans-serif", fontWeight: "bolder" }}>{details.personalInformation.firstName} {details.personalInformation.lastName}</p>
											</div>
										)}
									</div>
								)
							)
						})}

					</div>
					<div className='d-flex flex-row  w-100' style={{ marginTop: "5px" }}>
						{combinedLanguages.map((language, index) => (
							language && (
								<Flag key={`${language.toLowerCase()}_${index}`} name={language} />
							)
						))}
					</div>
				</div>
			)}
		</div>
	)
}

ManagerInfo.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	getManagerDetailById: PropTypes.func,
	managerDetail: PropTypes.object,
	error: PropTypes.object,
	getManagerList: PropTypes.func,
	managerList: PropTypes.array,
	isLoadingManagers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	managerList: state.Users.managerList,
	error: state.Users.error,
	isLoading: state.Users.isLoading,
	managerDetail: state.Users.managerDetail,
	isLoadingManagers: state.Users.isLoadingManagers,
})

const mapDispatchToProps = { getManagerList, getManagerDetailById }

export default connect(mapStateToProps, mapDispatchToProps)(ManagerInfo)
