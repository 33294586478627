export const Unit = {
	inches: 'inches',
	millimeters: 'millimeters',
	centimeters: 'centimeters',
	meters: 'meters',
}

export const UnitExponent = {
	none: 'none',
	square: 'square',
	cubic: 'cubic',
}

export const LENGTH_IN_METERS = {
	[Unit.inches]: 0.0254,
	[Unit.millimeters]: 0.001,
	[Unit.centimeters]: 0.01,
	[Unit.meters]: 1,
}

export const DIMENSION_EXPONENTS = {
	[UnitExponent.none]: 1,
	[UnitExponent.square]: 2,
	[UnitExponent.cubic]: 3,
}

export const convertUnit = (
	value,
	from,
	to,
	unitExponent = UnitExponent.none,
) => {
	const fromInMeters = LENGTH_IN_METERS[from]
	const toInMeters = LENGTH_IN_METERS[to]
	const exponent = DIMENSION_EXPONENTS[unitExponent]
	return value * Math.pow(fromInMeters / toInMeters, exponent)
}
