import PropTypes from 'prop-types'
import React, { Component } from 'react'
import 'react-drawer/lib/react-drawer.css'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

// Reactstrap

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

// import images

import logoLightSvg from '../../assets/images/logo-light.svg'
import logo from '../../assets/images/logo.svg'

//i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import { toggleRightSidebar } from '../../store/actions'

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isSearch: false,
			open: false,
			position: 'right',
		}
		this.toggleMenu = this.toggleMenu.bind(this)
		this.toggleFullscreen = this.toggleFullscreen.bind(this)
	}
	/**
	 * Toggle sidebar
	 */
	toggleMenu() {
		this.props.toggleMenuCallback(true)
	}

	/**
	 * Toggles the sidebar
	 */
	toggleRightbar() {
		this.props.toggleRightSidebar()
	}

	toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen()
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen()
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT,
				)
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<header id="page-topbar">
					<div className="navbar-header">
						<div className="d-flex">
							<div className="navbar-brand-box d-lg-none d-md-block">
								<Link to="/" className="logo logo-dark">
									<span className="logo-sm">
										<img src={logo} alt="" height="22" />
									</span>
								</Link>

								<Link to="/" className="logo logo-light">
									<span className="logo-sm">
										<img src={logoLightSvg} alt="" height="22" />
									</span>
								</Link>
							</div>

							<button
								type="button"
								onClick={this.toggleMenu}
								className="btn btn-sm px-3 font-size-16 header-item"
								id="vertical-menu-btn"
							>
								<i id='button-header-layout-open-side-panel' className="fa fa-fw fa-bars"></i>
							</button>
						</div>
						<div className="d-flex">
							<div className="dropdown d-inline-block d-lg-none ms-2">
								<button
									onClick={() => {
										this.setState({ isSearch: !this.state.isSearch })
									}}
									type="button"
									className="btn header-item noti-icon"
									id="page-header-search-dropdown"
								>
									<i className="mdi mdi-magnify"></i>
								</button>
								<div
									className={
										this.state.isSearch
											? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
											: 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
									}
									aria-labelledby="page-header-search-dropdown"
								>
									<form className="p-3">
										<div className="form-group m-0">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder="Search ..."
													aria-label="Recipient's username"
												/>
												<div className="input-group-append">
													<button className="btn btn-primary" type="submit">
														<i className="mdi mdi-magnify"></i>
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<LanguageDropdown />
							{/*TODO UNCOMMENT WHEN NOTIFICATIONS DOES SOMETHING <NotificationDropdown /> */}
							<ProfileMenu />
						</div>
					</div>
				</header>
			</React.Fragment>
		)
	}
}

Header.propTypes = {
	t: PropTypes.any,
	toggleMenuCallback: PropTypes.any,
	showRightSidebar: PropTypes.any,
	toggleRightSidebar: PropTypes.func,
}

const mapStatetoProps = state => {
	const { layoutType, showRightSidebar } = state.Layout
	return { layoutType, showRightSidebar }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
	withTranslation()(Header),
)
