import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const QuotationInfo = ({ quoteData, handleOnChangeQuoteData, t }) => {

	const handleOnChangeValue = (newValue, field, quoteId) => {
		const newQuotation = {
			...quoteData,
			newValues: quoteData.newValues.map(value => {
				if (value.quoteId !== quoteId) return value
				return {
					...value,
					[field]: roundAtDecimals((newValue), 2),
				}
			}),
		}
		handleOnChangeQuoteData(newQuotation)
	}

	const calculateNewQuotationValue = (marginValue, newQuotation) => {
		return (marginValue * newQuotation)
	}

	const quoteInputs = useMemo(() => {
		return quoteData != null && quoteData.cloningSourceQuote.quotes.map((quote, index) => {
			const newQuoteInfo = quoteData.newValues.find(newQuote => newQuote.quoteId === quote.id)
			const marginValue = roundAtDecimals(newQuoteInfo.newQuotation / quote.quotation, 2)
			return (
				<div className='d-flex flex-row pe-2' key={`inputs_${index}_${quote.id}`}>
					<div className='w-100' key={`quantity_${index}_`}><TextInput className='w-100' noMarginDefault disabled value={quote.quantity} /></div>
					<div className='w-100' key={`quotation_${index}`}><TextInput className='w-100' noMarginDefault disabled value={quote.quotation} /></div>
					<div className='w-100' key={`time_${index}`}><TextInput className='w-100' noMarginDefault disabled value={quote.time} /></div>
					<div className='w-100' key={`margin_${index}`}><TextInput className='w-100' noMarginDefault setValue={(e) => { handleOnChangeValue(calculateNewQuotationValue(e, quote.quotation), 'newQuotation', quote.id) }} value={marginValue} type='number' /></div>
					<div className='w-100' key={`new_quotation_${index}`}><TextInput className='w-100' noMarginDefault setValue={(e) => { handleOnChangeValue(e, 'newQuotation', quote.id) }} value={newQuoteInfo.newQuotation} type='number' /></div>
					<div className='w-100' key={`new_time_${index}`}><TextInput className='w-100' noMarginDefault setValue={(e) => { handleOnChangeValue(e, 'newTime', quote.id) }} value={newQuoteInfo.newTime} type='number' /></div>
				</div>

			)
		})
	}, [quoteData])

	return (
		<div>
			{quoteData != null ?
				<div id="cloneQuoteDivTable">
					<div className='d-flex flex-row'>
						<div className='col-2'>{t('quantity', { ns: 'naming' })}</div>
						<div className='col-2'>{t('price', { ns: 'naming' })}</div>
						<div className='col-2'>{t('time', { ns: 'naming' })}</div>
						<div className='col-2'>{t('quotation_margin', { ns: 'naming' })}</div>
						<div className='col-2'>{t('new_price', { ns: 'naming' })} </div>
						<div className='col-2'>{t('new_time', { ns: 'naming' })} </div>
					</div>
					{quoteInputs}
				</div>
				: <span>{t('select_a_available_supplier_or_mark_it', { ns: 'naming' })}</span>}
		</div>
	)
}

QuotationInfo.propTypes = {
	quoteData: PropTypes.object,
	handleOnChangeQuoteData: PropTypes.func,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuotationInfo))
