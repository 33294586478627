import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { mapSuppliers } from 'common/Utils/SuppliersUtils'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import { PartName } from 'components/Common/PartName'
import SimpleTable from 'components/Common/SimpleTable'
import TextInput from 'components/Common/TextInput'
import { QUOTE_STATUS_QUOTED } from 'constants/quoteStatus'
import OfferTechnologies from 'pages/OfferList/OfferTechnology'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import { cloneQuote, getSuppliers } from 'store/actions'
import QuotationInfo from './QuotationInfo'

const IMAS_ID = process.env.REACT_APP_IMAS_WORKSHOP_SUPPLIER_ID
const DEFAULT_IMAS_MARGIN = 1.25
const QUOTED_STATUS = 30

export const CloneQuotationModal = ({
	t,
	i18n: { language },
	isModalOpen,
	isLoading,
	handleOnChangeCloneQuoteModal,
	translatedParts,
	supplierList,
	cloneQuote,
	partConfigOptions,
}) => {

	const [cloningSourceSupplierId, setCloningSourceSupplierId] = useState(null)
	const [clonedSupplierId, setClonedSupplierId] = useState(null)
	const [clonedQuotesData, setClonedQuotesData] = useState([])

	useEffect(() => {
		setCheckboxesByCloningSourceSupplierId()
	}, [cloningSourceSupplierId])

	useEffect(() => {
		if (clonedSupplierId == IMAS_ID) handleOnChangeGeneralMargin(DEFAULT_IMAS_MARGIN)
	}, [clonedSupplierId])

	const handleOnChangeQuoteMargin = (quoteData) => {
		setClonedQuotesData((prevValue) => [...prevValue.filter(data => data.partId !== quoteData.partId), quoteData])
	}

	const getCloningSourceOptions = () => {
		const supplierArrays = translatedParts.map(translatedPart => {
			const { suppliers } = translatedPart.costsAndMargins
			return suppliers.map(supplier => {
				return supplier.statusId === QUOTE_STATUS_QUOTED ? supplier.id : null
			})
		})
		return [...new Set(supplierArrays.flatMap(item => item).filter(item => item != null))]
	}
	const cloningSourceOptions = getCloningSourceOptions()

	const getRequestedSuppliersIds = () => {
		const selectedParts = translatedParts.filter(part => clonedQuotesData.find(data => data.partId === part.id.value) != null)
		return [...new Set(selectedParts.flatMap(part => (part.costsAndMargins.suppliers.map(supplier => supplier.id))))]
	}

	const requestedSuppliersIds = useMemo(() => getRequestedSuppliersIds(), [translatedParts, clonedQuotesData])

	const handleClickCheckbox = (translatedPart) => {
		const cloningSourceQuote = translatedPart.costsAndMargins.suppliers.find(supplier => supplier.id === cloningSourceSupplierId)
		if (cloningSourceQuote == null || cloningSourceQuote.statusId !== QUOTE_STATUS_QUOTED) return
		const translatedPartId = translatedPart.id.value
		const isPartAlreadyIn = clonedQuotesData.find(data => data.partId === translatedPartId) != null
		if (isPartAlreadyIn) {
			setClonedQuotesData(clonedQuotesData.filter(data => data.partId !== translatedPartId))
		} else {
			setClonedQuotesData([...clonedQuotesData, { partId: translatedPartId, cloningSourceQuote, newValues: getNewPricesInitialArray(cloningSourceQuote.quotes) }])
		}
	}

	const setCheckboxesByCloningSourceSupplierId = () => {
		if (cloningSourceSupplierId == null) {
			setClonedQuotesData([])
		} else {
			const mappedQuotesData = translatedParts.map(translatedPart => {
				const cloningSourceQuote = translatedPart.costsAndMargins.suppliers.find(supplier => supplier.id === cloningSourceSupplierId && supplier.statusId === QUOTED_STATUS)
				if (cloningSourceQuote == null) return null

				return { partId: translatedPart.id.value, cloningSourceQuote, newValues: getNewPricesInitialArray(cloningSourceQuote.quotes) }
			})
			setClonedQuotesData(mappedQuotesData.filter(data => data != null))
		}
	}

	const getGeneralMargin = () => {
		if (clonedQuotesData.length === 0) return
		let margin
		for (const quoteData of clonedQuotesData) {
			for (const quoteIndex in quoteData.cloningSourceQuote.quotes) {
				const newQuotation = quoteData.newValues[quoteIndex].newQuotation
				const quotation = quoteData.cloningSourceQuote.quotes[quoteIndex].quotation
				const currentMargin = roundAtDecimals(newQuotation / quotation, 2)
				if (margin != null && margin != currentMargin) return null
				margin = currentMargin
			}
		}
		return margin
	}

	const generalMargin = useMemo(() => { return getGeneralMargin() }, [clonedQuotesData])

	const handleOnChangeGeneralMargin = (marginValue) => {
		if (clonedQuotesData.length === 0) return
		setClonedQuotesData(prevValue => prevValue.map(quoteData => {
			return {
				...quoteData,
				newValues: quoteData.newValues.map((newValue, index) => {
					const quotation = quoteData.cloningSourceQuote.quotes[index].quotation
					return {
						...newValue,
						newQuotation: roundAtDecimals(quotation * marginValue, 2),
					}
				}),
			}
		}))
	}

	const getNewPricesInitialArray = (quotes) => {
		return quotes.map(quote => {
			return {
				quantity: quote.quantity,
				shipmentPrice: quote.shipmentPrice,
				quoteId: quote.id,
				newQuotation: quote.quotation,
				newTime: quote.time,
				margin: quote.margin,
			}
		})
	}

	const getTableBody = () => {
		return translatedParts.map(part => {
			return (
				<tr key={`KEY_${part.id.value}`}>
					<td>
						<div className='d-flex flex-row align-items-center'>
							<Checkbox checked={clonedQuotesData.find(data => data.partId === part.id.value) != null} onChange={() => handleClickCheckbox(part)} />
							<span className='m-1 mb-2'>{part.id.value}</span>
						</div>
					</td>
					<td><img src={part.imageUrl} width={75} height={100} ></img></td>
					<td className='w-25'>
						<div className='d-flex flex-column width-by-content'>
							<strong>{<PartName name={part.fileName} />}</strong>
							<span>{part.material}</span>
							<span>{part.finishing}</span>
						</div>
					</td>
					<td><OfferTechnologies className="m-0" technologies={[part.configuration.technologyTypeId]} t={t} partConfigOptions={partConfigOptions} /></td>
					<td>
						{
							cloningSourceSupplierId != null ?
								<QuotationInfo quoteData={clonedQuotesData.find(data => data.partId === part.id.value)} handleOnChangeQuoteData={handleOnChangeQuoteMargin} />
								: <span>{t('select_a_supplier_to_view_data', { ns: 'naming' })}</span>
						}
					</td>
				</tr>)
		})
	}

	const header = ['id', 'image', 'description', 'technology', 'quote']
	const handleOnSaveQuote = () => {

		const input = {
			data: {
				newSupplierId: clonedSupplierId,
				parts: clonedQuotesData.map(quote => {
					return {
						constructionId: quote.cloningSourceQuote.constructionTypeId,
						partId: quote.partId,
						quoteData: quote.newValues,
					}
				}),
			},
		}
		cloneQuote(input)
	}
	return (
		<Modal
			className={'hugeModal'}
			title={t('cloneQuote', { ns: 'naming' })}
			buttons={[
				<button id="saveButton" disabled={clonedSupplierId == null || isLoading === true} key='save_clone_quote_button' className='btn btn-primary' onClick={() => handleOnSaveQuote()}>
					{isLoading ? <Loader /> : t('save', { ns: 'naming' })}
				</button>,
			]}
			body={
				<div className='p-4 d-flex flex-column justify-content-center'>
					{cloningSourceOptions.length > 0 ?
						<div>
							<div className='w-75 d-flex justify-content-center'>
								<OptionsInput
									title={t('clone_source', { ns: 'naming' })}
									className='w-100'
									automaticMappedValue
									options={mapSuppliers(supplierList.filter(supplier => cloningSourceOptions.includes(supplier.id)))}
									value={cloningSourceSupplierId}
									setValue={setCloningSourceSupplierId}
								/>
								<OptionsInput
									title={t('supplier_to_clone', { ns: 'naming' })}
									className='w-100'
									automaticMappedValue
									options={mapSuppliers(supplierList.filter(supplier => !requestedSuppliersIds.includes(supplier.id)))}
									value={clonedSupplierId}
									setValue={setClonedSupplierId}
									disabled={cloningSourceSupplierId == null}
								/>
								<TextInput
									title={t('general_margin', { ns: 'naming' })}
									value={generalMargin != null ? generalMargin : ''}
									setValue={handleOnChangeGeneralMargin}
									type={generalMargin != null ? 'number' : 'text'}
									className='w-50'
								/>
							</div>
							<div>
								<SimpleTable id='cloneQuoteTable' getTableBody={getTableBody} header={header} />
							</div>
						</div> : <div>{t('no_available_parts', { ns: 'naming' })}</div>
					}
				</div>}
			isOpen={isModalOpen}
			closeModal={() => { handleOnChangeCloneQuoteModal(false) }}
			size={'lg'}
		/>
	)
}

CloneQuotationModal.propTypes = {
	t: PropTypes.func,
	isModalOpen: PropTypes.bool,
	handleOnChangeCloneQuoteModal: PropTypes.func,
	translatedParts: PropTypes.array,
	supplierList: PropTypes.array,
	cloneQuote: PropTypes.func,
	isLoading: PropTypes.bool,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	supplierList: state.Users.suppliers,
	isLoading: state.Quotes.isLoading,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {
	getSuppliers,
	cloneQuote,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CloneQuotationModal))
