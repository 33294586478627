import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
	CREATE_INTERNAL_COMMENT,
	CREATE_INVOICE,
	CREATE_NEW_NACEX_EXPEDITION,
	CREATE_NEW_UPS_EXPEDITION,
	CREATE_PART_VERIFICATION,
	CREATE_REMIT,
	CREATE_SHIPMENT_EXPORT,
	CREATE_VOUCHER,
	GET_BILLING_DASHBOARD,
	GET_BILLING_LIST,
	GET_BOX_LIST,
	GET_DOMAIN_ORDERS,
	GET_EXPEDITION_NACEX_LABELS,
	GET_EXPEDITION_UPS_LABELS,
	GET_EXPORTED_PART_BY_DATE_LIST,
	GET_FILTERED_ORDERS,
	GET_INCIDENCES_LIST,
	GET_LAST_THREE_DAYS_SHIPMENTS,
	GET_OPENED_EXPEDITION_ORDERS,
	GET_OPENED_VERIFICATION_ORDERS,
	GET_OPEPENED_ORDERS,
	GET_ORDER_DETAIL,
	GET_ORDER_DETAIL_BY_ID,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH,
	GET_ORDER_LIST,
	GET_PENDING_INVOICE_ORDERS,
	GET_QUALITY_PROBLEMS,
	GET_QUALITY_QUANTITIES,
	GET_QUALITY_SOLUTIONS,
	GET_VOUCHER_LIST,
	HANDLE_CANCELLED_ORDER,
	HANDLE_SENT_ORDER,
	HANDLE_SUCCESSFUL_ORDER,
	SCAN_ORDER_EXPEDITION_PART,
	UPDATE_BOXES_BY_REF,
	UPDATE_ORDER,
	UPDATE_ORDER_EXPEDITION_INFO,
	UPDATE_ORDER_ITEM,
	UPDATE_ORDER_SHIPPING_ADDRESS,
	UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY,
	UPDATE_QUALITY_VERIFICATION,
	UPDATE_VOUCHER_BY_C0DE
} from './actionTypes'

import {
	createInternalCommentFail,
	createInternalCommentSuccess,
	createInvoiceFail,
	createInvoiceSuccess,
	createNewNacexExpeditionFail,
	createNewNacexExpeditionSuccess,
	createNewUpsExpeditionFail,
	createNewUpsExpeditionSuccess,
	createPartVerificationFail,
	createPartVerificationSuccess,
	createRemitFail,
	createRemitSuccess,
	createShipmentExportFail,
	createShipmentExportSuccess,
	createVoucherFail,
	createVoucherSuccess,
	getBillingDashboardFail,
	getBillingDashboardSuccess,
	getBillingListFail,
	getBillingListSuccess,
	getBoxListFail,
	getBoxListSuccess,
	getDomainOrdersFail,
	getDomainOrdersSuccess,
	getExpeditionNacexLabelsFail,
	getExpeditionNacexLabelsSuccess,
	getExpeditionUpsLabelsFail,
	getExpeditionUpsLabelsSuccess,
	getExportedPartListByDateFail,
	getExportedPartListByDateNoPaginatedSuccess,
	getExportedPartListByDateSuccess,
	getFilteredOrdersFail,
	getFilteredOrdersSuccess,
	getIncidencesListFail,
	getIncidencesListSuccess,
	getLastThreeDaysShipmentsFail,
	getLastThreeDaysShipmentsSuccess,
	getOpenedExpeditionOrdersFail,
	getOpenedExpeditionOrdersSuccess,
	getOpenedOrdersFail,
	getOpenedOrdersSuccess,
	getOpenedVerificationOrdersFail,
	getOpenedVerificationOrdersSuccess,
	getOrderDetailByIdFail,
	getOrderDetailByIdSuccess,
	getOrderDetailFail,
	getOrderDetailSuccess,
	getOrderItemByOrderIdSearchFail,
	getOrderItemByOrderIdSearchSuccess,
	getOrderListFail,
	getOrderListSuccess,
	getPendingInvoiceOrdersFail,
	getPendingInvoiceOrdersSuccess,
	getQualityProblemsFail,
	getQualityProblemsSuccess,
	getQualityQuantitiesFail,
	getQualityQuantitiesSuccess,
	getQualitySolutionsFail,
	getQualitySolutionsSuccess,
	getVoucherListFail,
	getVoucherListSuccess,
	handleCancelledOrderFail,
	handleCancelledOrderSuccess,
	handleSentOrderFail,
	handleSentOrderSuccess,
	handleSuccessfulOrderFail,
	handleSuccessfulOrderSuccess,
	scanOrderExpeditionPartFail,
	scanOrderExpeditionPartSuccess,
	updateBoxesByRefFail,
	updateBoxesByRefSuccess,
	updateOrderExpeditionInfoFail,
	updateOrderExpeditionInfoSuccess,
	updateOrderFail,
	updateOrderItemFail,
	updateOrderItemSuccess,
	updateOrderShippingAddressFail,
	updateOrderShippingAddressSuccess,
	updateOrderSuccess,
	updateProductionItemReceivedQuantityFail,
	updateProductionItemReceivedQuantitySuccess,
	updateQualityVerificationFail,
	updateQualityVerificationSuccess,
	updateVoucherByCodeFail,
	updateVoucherByCodeSuccess
} from './actions'

import {
	CREATE_CRM_INTERACTION_ENDPOINT,
	CREATE_INVOICE_ENDPOINT,
	CREATE_PART_VERIFICATION_ENDPOINT,
	CREATE_REMIT_ENDPOINT,
	CREATE_SHIPMENT_EXPORT_ENDPOINT,
	CREATE_VOUCHER_ENDPOINT,
	GET_BILLING_DASHBOARD_ENDPOINT,
	GET_BILLING_LIST_ENDPOINT,
	GET_BOX_LIST_ENDPOINT,
	GET_DOMAIN_ORDERS_ENDPOINT,
	GET_EXPORTED_PART_LIST_BY_DATE_ENDPOINT,
	GET_FILTERED_ORDERS_ENDPOINT,
	GET_INCIDENCES_LIST_ENDPOINT,
	GET_LAST_THREE_DAYS_SHIPMENTS_ENDPOINT,
	GET_OPENED_EXPEDITION_ORDERS_ENDPOINT,
	GET_OPENED_ORDERS_ENDPOINT,
	GET_OPENED_VERIFICATION_ORDERS_ENDPOINT,
	GET_ORDER_DETAIL_BY_ID_ENDPOINT,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_ENDPOINT,
	GET_ORDER_LIST_ENDPOINT,
	GET_PENDING_INVOICE_ORDERS_ENDPOINT,
	GET_QUALITY_PROBLEMS_ENDPOINT,
	GET_QUALITY_QUANTITIES_ENDPOINT,
	GET_QUALITY_SOLUTIONS_ENDPOINT,
	GET_VOUCHER_LIST_ENDPOINT,
	HANDLE_CANCELLED_ORDER_ENDPOINT,
	HANDLE_SENT_ORDER_ENDPOINT,
	HANDLE_SUCCESSFUL_ORDER_ENDPOINT,
	PUT_EXPEDITION_NACEX_ENDPOINT,
	PUT_EXPEDITION_UPS_ENDPOINT,
	RETRIEVE_NACEX_EXPEDITION_LABELS_ENDPOINT,
	RETRIEVE_UPS_EXPEDITION_LABELS_ENDPOINT,
	SCAN_ORDER_EXPEDITION_PART_ENDPOINT,
	UPDATE_BOXES_BY_REF_ENDPOINT,
	UPDATE_ORDER_ENDPOINT,
	UPDATE_ORDER_EXPEDITION_INFO_ENDPOINT,
	UPDATE_ORDER_ITEM_ENDPOINT,
	UPDATE_ORDER_SHIPPING_ADDRESS_ENDPOINT,
	UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_ENDPOINT,
	UPDATE_QUALITY_VERIFICATION_ENDPOINT,
	UPDATE_VOUCHER_BY_CODE_ENPOINT
} from 'constants/backendRoutes'

import { downloadFileAsBase64 } from 'common/Utils/downloadUtils/downloadFileAsBase64'
import { updateDashboardCountryList } from 'store/actions'
import ApiHelper, { get, post } from '../../helpers/api_helper'

function* getOrderItemByOrderIdSearch({ payload: { orderId, partId } }) {
	try {
		const input = { data: { orderId, partId } }
		const url = `${GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_ENDPOINT}`
		const response = yield call(post, url, input)
		yield put(getOrderItemByOrderIdSearchSuccess(response))
	} catch (error) {
		yield put(getOrderItemByOrderIdSearchFail(error))
	}
}

function* updateQualityVerification({ payload }) {
	try {
		const url = UPDATE_QUALITY_VERIFICATION_ENDPOINT
		const response = yield call(ApiHelper.put, url, payload)
		yield put(updateQualityVerificationSuccess(response))
	} catch (error) {
		yield put(updateQualityVerificationFail(error))
	}
}

function* getOrderDetail({ payload: { orderId } }) {
	try {
		const url = GET_ORDER_DETAIL_BY_ID_ENDPOINT
		const response = yield call(get, `${url}/${orderId}`)
		yield put(getOrderDetailSuccess({ data: { order: response.order, account: response.account, organization: response.organization } }))
	} catch (error) {
		yield put(getOrderDetailFail(error))
	}
}
function* updateOrderShippingAddress({ payload: { orderId, shippingAddress } }) {
	try {
		const url = UPDATE_ORDER_SHIPPING_ADDRESS_ENDPOINT
		const input = {
			data: {
				orderId,
				shippingAddress,
			},
		}
		const response = yield call(post, url, input)
		yield put(updateOrderShippingAddressSuccess(response))
	} catch (error) {
		yield put(updateOrderShippingAddressFail(error))
	}
}
function* updateOrder({ payload: { order } }) {
	try {
		const url = UPDATE_ORDER_ENDPOINT
		const input = {
			data: {
				order,
			},
		}
		const response = yield call(post, url, input)
		yield put(updateOrderSuccess(response))
	} catch (error) {
		yield put(updateOrderFail(error))
	}
}
function* createInternalComment({ payload: { comment } }) {
	try {
		const url = CREATE_CRM_INTERACTION_ENDPOINT
		const input = {
			data: {
				crmInteraction: comment,
			},
		}

		/*  @author: sbalaguer
		 *  TODO - Check why response is not used? 
		 */
		const response = yield call(post, url, input)
		yield put(createInternalCommentSuccess(response.crmInteraction))
	} catch (error) {
		yield put(createInternalCommentFail(error))
	}
}

function* getOrderDetailById({ payload: orderId }) {
	try {
		const url = `${GET_ORDER_DETAIL_BY_ID_ENDPOINT}/${orderId}`
		const response = yield call(ApiHelper.get, url)

		for (const orderItem of response.order.orderItems) {
			if (orderItem.part.files.image) {
				orderItem.part.files.imageBase64 = yield call(downloadFileAsBase64, orderItem.part.files.image)
			}
		}

		yield put(getOrderDetailByIdSuccess(response))
	} catch (error) {
		yield put(getOrderDetailByIdFail(error))
	}
}

function* getOrderList() {
	try {
		const url = GET_ORDER_LIST_ENDPOINT
		const response = yield call(ApiHelper.get, url)
		yield put(getOrderListSuccess({ data: { orderList: response.orderList } }))
	} catch (error) {
		yield put(getOrderListFail(error))
	}
}

function* getBillingDashboard({ payload }) {
	try {
		const url = GET_BILLING_DASHBOARD_ENDPOINT
		const response = yield call(ApiHelper.post, url, payload)
		if (response.countryList != null) {
			yield put(updateDashboardCountryList(response.countryList))
		}
		yield put(getBillingDashboardSuccess(response))
	} catch (error) {
		yield put(getBillingDashboardFail(error))
	}
}

function* handleSuccessfulOrder({ payload: { orderId, paymentDate, emailOnlyToCustomer, statusId, sendEmail } }) {
	try {
		const url = HANDLE_SUCCESSFUL_ORDER_ENDPOINT
		const input = {
			data: {
				orderId,
				paymentDate,
				emailOnlyToCustomer,
				statusId,
				sendEmail,
			},
		}
		const response = yield call(post, url, input)
		yield put(handleSuccessfulOrderSuccess(response))
	} catch (error) {
		yield put(handleSuccessfulOrderFail(error))
	}
}

function* handleCancelledOrder({ payload: { orderId } }) {
	try {
		const url = HANDLE_CANCELLED_ORDER_ENDPOINT
		const input = {
			data: {
				orderId,
			},
		}
		const response = yield call(post, url, input)
		yield put(handleCancelledOrderSuccess(response))
	} catch (error) {
		yield put(handleCancelledOrderFail(error))
	}
}

function* createNacexExpedition({ payload: { data } }) {
	try {
		const url = PUT_EXPEDITION_NACEX_ENDPOINT
		const response = yield call(ApiHelper.put, url, data)
		yield put(createNewNacexExpeditionSuccess(response))
	} catch (error) {
		yield put(createNewNacexExpeditionFail(error))
	}
}

function* getVoucherList() {
	try {
		const url = GET_VOUCHER_LIST_ENDPOINT
		const response = yield call(ApiHelper.get, url)
		yield put(getVoucherListSuccess(response))
	} catch (error) {
		yield put(getVoucherListFail(error))
	}
}

function* createVoucher({ payload: { voucher } }) {
	try {
		const url = CREATE_VOUCHER_ENDPOINT
		const input = {
			data: {
				voucher: voucher,
			},
		}
		const response = yield call(post, url, input)
		yield put(createVoucherSuccess(response))
	} catch (error) {
		yield put(createVoucherFail(error))
	}
}

function* updateVoucherByCode({ payload: { voucher } }) {
	try {
		const url = UPDATE_VOUCHER_BY_CODE_ENPOINT
		const input = {
			data: {
				voucher: voucher,
			},
		}
		const response = yield call(post, url, input)
		yield put(updateVoucherByCodeSuccess(response))
	} catch (error) {
		yield put(updateVoucherByCodeFail(error))
	}
}

function* getDomainOrders({ payload: { accountId } }) {
	try {
		const url = GET_DOMAIN_ORDERS_ENDPOINT + `/${accountId}`
		const response = yield call(get, url)
		yield put(getDomainOrdersSuccess(response))
	} catch (error) {
		yield put(getDomainOrdersFail(error))
	}
}

function* getBoxList() {
	try {
		const url = GET_BOX_LIST_ENDPOINT
		const response = yield call(ApiHelper.get, url)
		yield put(getBoxListSuccess(response))
	} catch (error) {
		yield put(getBoxListFail(error))
	}
}

function* updateBoxesByRef({ payload }) {
	try {
		const url = UPDATE_BOXES_BY_REF_ENDPOINT
		const response = yield call(post, url, payload)
		yield put(updateBoxesByRefSuccess(response))
	} catch (error) {
		yield put(updateBoxesByRefFail(error))
	}
}

function* getBillingList({ payload: { page, pageSize, dateRange } }) {
	try {
		const urlQueries = []
		if (page && pageSize) {
			urlQueries.push([`page=${page}`])
			urlQueries.push([`pageSize=${pageSize}`])
		}
		if (dateRange?.startDate && dateRange?.endDate) {
			urlQueries.push([`startDate=${dateRange?.startDate}`])
			urlQueries.push([`endDate=${dateRange?.endDate}`])
		}
		const url = `${GET_BILLING_LIST_ENDPOINT}${urlQueries.length > 0 ? `?${urlQueries.join('&')}` : ''}`
		const response = yield call(get, url)
		yield put(getBillingListSuccess(response))
	} catch (error) {
		yield put(getBillingListFail(error))
	}
}
function* getExportedPartListReport({ payload: { dateRange, pagination } }) {
	try {
		const input = { data: { dateRange, pagination } }
		const url = `${GET_EXPORTED_PART_LIST_BY_DATE_ENDPOINT}`
		const response = yield call(post, url, input)
		if (pagination == null) {
			yield put(getExportedPartListByDateNoPaginatedSuccess(response))
		} else {
			yield put(getExportedPartListByDateSuccess(response))
		}
	} catch (error) {
		yield put(getExportedPartListByDateFail(error))
	}
}

function* getFilteredOrders({ payload: { data, signal } }) {
	try {

		const url = GET_FILTERED_ORDERS_ENDPOINT
		const response = yield call(post, url, data, { signal })
		yield put(getFilteredOrdersSuccess(response))
	} catch (error) {
		yield put(getFilteredOrdersFail(error))
	}
}

function* getOpenedOrders() {
	try {
		const url = GET_OPENED_ORDERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getOpenedOrdersSuccess(response))
	} catch (error) {
		yield put(getOpenedOrdersFail(error))
	}
}

function* getOpenedVerificationOrders() {
	try {
		const url = GET_OPENED_VERIFICATION_ORDERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getOpenedVerificationOrdersSuccess(response))
	} catch (error) {
		yield put(getOpenedVerificationOrdersFail(error))
	}
}

function* updateProductionItemReceivedQuantity({ payload }) {
	try {
		const url = UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_ENDPOINT
		const response = yield call(ApiHelper.put, url, payload)
		yield put(updateProductionItemReceivedQuantitySuccess(response))
	} catch (error) {
		yield put(updateProductionItemReceivedQuantityFail(error))
	}
}

function* getOpenedExpeditionOrders() {
	try {
		const url = GET_OPENED_EXPEDITION_ORDERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getOpenedExpeditionOrdersSuccess(response))

	} catch (error) {
		yield put(getOpenedExpeditionOrdersFail(error))
	}
}

function* updateOrderExpeditionInfo({ payload }) {
	try {
		const url = UPDATE_ORDER_EXPEDITION_INFO_ENDPOINT
		const response = yield call(ApiHelper.put, url, payload)
		yield put(updateOrderExpeditionInfoSuccess(response))
	} catch (error) {
		yield put(updateOrderExpeditionInfoFail(error))
	}
}

function* updateOrderItem({ payload }) {
	try {
		const url = UPDATE_ORDER_ITEM_ENDPOINT
		const response = yield call(ApiHelper.put, url, payload)
		yield put(updateOrderItemSuccess(response))
	} catch (error) {
		yield put(updateOrderItemFail(error))
	}
}

function* scanOrderExpeditionPart({ payload }) {
	try {
		const url = SCAN_ORDER_EXPEDITION_PART_ENDPOINT
		const response = yield call(ApiHelper.put, url, payload)
		yield put(scanOrderExpeditionPartSuccess(response))
	} catch (error) {
		yield put(scanOrderExpeditionPartFail(error))
	}
}

function* createShipmentExport({ payload }) {
	try {
		const url = CREATE_SHIPMENT_EXPORT_ENDPOINT
		const response = yield call(ApiHelper.post, url, payload)
		yield put(createShipmentExportSuccess(response))
	} catch (error) {
		yield put(createShipmentExportFail(error))
	}
}

function* getLastThreeDaysShipments({ payload: { clientId } }) {
	try {
		const url = `${GET_LAST_THREE_DAYS_SHIPMENTS_ENDPOINT}/${clientId}`
		const response = yield call(get, url)
		yield put(getLastThreeDaysShipmentsSuccess(response))
	} catch (error) {
		yield put(getLastThreeDaysShipmentsFail(error))
	}
}

function* getQualityQuantities() {
	try {
		const url = GET_QUALITY_QUANTITIES_ENDPOINT
		const response = yield call(get, url)
		yield put(getQualityQuantitiesSuccess(response))
	} catch (error) {
		yield put(getQualityQuantitiesFail(error))
	}
}

function* getQualityProblems() {
	try {
		const url = GET_QUALITY_PROBLEMS_ENDPOINT
		const response = yield call(get, url)
		yield put(getQualityProblemsSuccess(response))
	} catch (error) {
		yield put(getQualityProblemsFail(error))
	}
}

function* getQualitySolutions() {
	try {
		const url = GET_QUALITY_SOLUTIONS_ENDPOINT
		const response = yield call(get, url)
		yield put(getQualitySolutionsSuccess(response))
	} catch (error) {
		yield put(getQualitySolutionsFail(error))
	}
}

function* createPartVerification({ payload }) {
	try {
		const url = CREATE_PART_VERIFICATION_ENDPOINT
		const response = yield call(ApiHelper.post, url, payload)
		yield put(createPartVerificationSuccess(response))
	} catch (error) {
		yield put(createPartVerificationFail(error))
	}
}

function* createUPSExpedition({ payload: { data } }) {
	try {
		const url = PUT_EXPEDITION_UPS_ENDPOINT
		const response = yield call(ApiHelper.put, url, data)
		yield put(createNewUpsExpeditionSuccess(response))
	}
	catch (error) {
		yield put(createNewUpsExpeditionFail(error))
	}
}

function* getExpeditionNacexLabels({ payload: { data } }) {
	try {
		const url = RETRIEVE_NACEX_EXPEDITION_LABELS_ENDPOINT
		const { response } = yield call(ApiHelper.post, url, data)

		// Imprimir la respuesta original para depuración

		// Reemplazar caracteres URL-safe de Base64 por los originales
		let base64Data = response.replace(/-/g, '+').replace(/_/g, '/')
		base64Data = base64Data.replace(/\n/g, '') // Eliminar saltos de línea

		// Eliminar caracteres no válidos para Base64
		base64Data = base64Data.replace(/[^A-Za-z0-9+/=]/g, '')

		// Asegurar que la longitud de la cadena sea múltiplo de 4
		while (base64Data.length % 4 !== 0) {
			base64Data += '='
		}

		// Decodificar Base64 a un array de bytes
		let byteCharacters
		try {
			byteCharacters = atob(base64Data)
		} catch (error) {
			throw new Error('Error al decodificar la cadena Base64: ' + error.message)
		}

		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)

		// Crear un Blob a partir del array de bytes
		const blob = new Blob([byteArray], { type: 'application/pdf' })
		const blobUrl = URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = blobUrl
		a.target = '_blank' // Abrir en una nueva pestaña
		a.click() // Simular clic en el enlace

		yield put(getExpeditionNacexLabelsSuccess(blob))
	} catch (error) {

		yield put(getExpeditionNacexLabelsFail(error))
	}
}

function* getExpeditionUpsLabels({ payload: { data } }) {
	try {
		const url = RETRIEVE_UPS_EXPEDITION_LABELS_ENDPOINT
		const { response } = yield call(ApiHelper.post, url, data)
		const graphicImage = response.LabelRecoveryResponse.LabelResults.LabelImage.GraphicImage
		var binaryString = atob(graphicImage)
		var bytes = new Uint8Array(binaryString.length)
		for (var i = 0; i < binaryString.length; i++) {
			bytes[i] = binaryString.charCodeAt(i)
		}
		const byteArray = new Uint8Array(bytes)

		// Crear un Blob a partir del array de bytes
		const blob = new Blob([byteArray], { type: 'application/pdf' })
		var blobUrl = URL.createObjectURL(blob)
		var a = document.createElement('a')
		a.href = blobUrl
		a.target = '_blank' // Abrir en una nueva pestaña
		a.click() // Simular clic en el enlace

		yield put(getExpeditionUpsLabelsSuccess(blob))
	}
	catch (error) {
		yield put(getExpeditionUpsLabelsFail(error))
	}
}

function* createRemit({ payload }) {
	try {
		const url = CREATE_REMIT_ENDPOINT
		const response = yield call(ApiHelper.post, url, { data: payload })
		yield put(createRemitSuccess(response))
	}
	catch (error) {
		yield put(createRemitFail(error))
	}
}

function* createInvoice({ payload }) {
	try {
		const url = CREATE_INVOICE_ENDPOINT
		const response = yield call(ApiHelper.post, url, { data: payload })
		yield put(createInvoiceSuccess(response))
	}
	catch (error) {
		yield put(createInvoiceFail(error))
	}
}

function* getIncidencesList({ payload: { data, signal } }) {
	try {
		const url = GET_INCIDENCES_LIST_ENDPOINT
		const response = yield call(ApiHelper.post, url, data, { signal })
		yield put(getIncidencesListSuccess(response))
	}
	catch (error) {
		yield put(getIncidencesListFail(error))
	}
}

function* getPendingInvoiceOrders({ payload }) {
	try {
		const url = GET_PENDING_INVOICE_ORDERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getPendingInvoiceOrdersSuccess(response))
	}
	catch (error) {
		yield put(getPendingInvoiceOrdersFail(error))
	}
}

function* handleSentOrder({ payload: { orderId, orderItems } }) {
	try {
		const url = HANDLE_SENT_ORDER_ENDPOINT
		const input = {
			data: {
				orderId,
				orderItems
			},
		}
		const response = yield call(post, url, input)
		yield put(handleSentOrderSuccess(response))
	} catch (error) {
		yield put(handleSentOrderFail(error))
	}
}


function* OrdersSaga() {
	yield takeEvery(GET_ORDER_DETAIL, getOrderDetail)
	yield takeEvery(UPDATE_ORDER_SHIPPING_ADDRESS, updateOrderShippingAddress)
	yield takeEvery(UPDATE_ORDER, updateOrder)
	yield takeEvery(CREATE_INTERNAL_COMMENT, createInternalComment)
	yield takeEvery(GET_ORDER_DETAIL_BY_ID, getOrderDetailById)
	yield takeEvery(GET_ORDER_LIST, getOrderList)
	yield takeEvery(GET_BILLING_DASHBOARD, getBillingDashboard)
	yield takeEvery(HANDLE_SUCCESSFUL_ORDER, handleSuccessfulOrder)
	yield takeEvery(HANDLE_CANCELLED_ORDER, handleCancelledOrder)
	yield takeEvery(CREATE_NEW_NACEX_EXPEDITION, createNacexExpedition)
	yield takeEvery(GET_VOUCHER_LIST, getVoucherList)
	yield takeEvery(CREATE_VOUCHER, createVoucher)
	yield takeEvery(UPDATE_VOUCHER_BY_C0DE, updateVoucherByCode)
	yield takeEvery(GET_DOMAIN_ORDERS, getDomainOrders)
	yield takeEvery(GET_BOX_LIST, getBoxList)
	yield takeEvery(UPDATE_BOXES_BY_REF, updateBoxesByRef)
	yield takeEvery(GET_BILLING_LIST, getBillingList)
	yield takeEvery(GET_EXPORTED_PART_BY_DATE_LIST, getExportedPartListReport)
	yield takeEvery(GET_FILTERED_ORDERS, getFilteredOrders)
	yield takeEvery(GET_OPEPENED_ORDERS, getOpenedOrders)
	yield takeEvery(GET_OPENED_VERIFICATION_ORDERS, getOpenedVerificationOrders)
	yield takeEvery(UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY, updateProductionItemReceivedQuantity)
	yield takeEvery(GET_OPENED_EXPEDITION_ORDERS, getOpenedExpeditionOrders)
	yield takeEvery(UPDATE_ORDER_EXPEDITION_INFO, updateOrderExpeditionInfo)
	yield takeEvery(UPDATE_ORDER_ITEM, updateOrderItem)
	yield takeEvery(SCAN_ORDER_EXPEDITION_PART, scanOrderExpeditionPart)
	yield takeEvery(CREATE_SHIPMENT_EXPORT, createShipmentExport)
	yield takeEvery(GET_LAST_THREE_DAYS_SHIPMENTS, getLastThreeDaysShipments)
	yield takeEvery(GET_QUALITY_QUANTITIES, getQualityQuantities)
	yield takeEvery(GET_QUALITY_PROBLEMS, getQualityProblems)
	yield takeEvery(GET_QUALITY_SOLUTIONS, getQualitySolutions)
	yield takeEvery(CREATE_PART_VERIFICATION, createPartVerification)
	yield takeEvery(CREATE_NEW_UPS_EXPEDITION, createUPSExpedition)
	yield takeEvery(GET_EXPEDITION_NACEX_LABELS, getExpeditionNacexLabels)
	yield takeEvery(GET_EXPEDITION_UPS_LABELS, getExpeditionUpsLabels)
	yield takeEvery(CREATE_REMIT, createRemit)
	yield takeEvery(CREATE_INVOICE, createInvoice)
	yield takeEvery(GET_INCIDENCES_LIST, getIncidencesList)
	yield takeEvery(GET_PENDING_INVOICE_ORDERS, getPendingInvoiceOrders)
	yield takeEvery(HANDLE_SENT_ORDER, handleSentOrder)
	yield takeEvery(UPDATE_QUALITY_VERIFICATION, updateQualityVerification)
	yield takeEvery(GET_ORDER_ITEM_BY_ORDER_ID_SEARCH, getOrderItemByOrderIdSearch)
}

export default OrdersSaga
