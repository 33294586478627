import { useObjectState } from 'common/Hooks/UseObjectState'
import FileInput from 'components/Common/FileInput'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addQuatlityVerificationImages, createPartVerification, uploadQualityVerificationImages } from 'store/actions'

export const RegisterIncidenceModal = ({ t, isOpen, suppliers, closeModal, orderId, partId, accountId, quantityList, problems, solutions, isModalInOrderDetail, createPartVerification, qualityVerification, uploadQualityVerificationImages, images, isLoading, addQuatlityVerificationImages, productions, }) => {
	const parseOptions = options =>
		options.map(option => {
			return {
				value: option.id,
				label: t(option.name, { ns: 'quality' }),
			}
		})

	const partHasMultipleProductionItems = () => {
		const allProductionItems = productions?.flatMap(({ productionItemList, productionItems }) => {
			return (productionItemList || productionItems || []).map(({ part }) => part.id)
		})

		return allProductionItems?.filter(id => id === partId).length > 1
	}

	const problemsOptions = parseOptions(problems)
	const solutionsOptions = parseOptions(solutions)
	const notifiedOptions = [
		{
			value: 'quality',
			label: t('quality', { ns: 'naming' }),
		},
		{
			value: 'client',
			label: t('client', { ns: 'naming' }),
		},
	]

	const INIT_STATE = {
		problem: null,
		solution: null,
		partsOK: 0,
		partsWrong: 0,
		observation: null,
		images: [],
		notifiedBy: 'quality',
		orderFragmentId: undefined,
	}
	const { handleOnChange, objectData, resetState } = useObjectState(INIT_STATE)

	const [imageFiles, setImageFiles] = useState()
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)

	const close = () => {
		resetState()
		closeModal()
	}

	const getOrderFragmentId = () => {
		const haveMultipleProductionItems = partHasMultipleProductionItems()
		if (haveMultipleProductionItems) return objectData.orderFragmentId
		return productions.find(({ productionItemList, productionItems }) => {
			const allItems = productionItemList || productionItems || []
			return allItems.find(({ part }) => part.id === partId)
		})?.orderFragmentId
	}

	const getProductionsOptions = () => {
		return productions?.map(({ orderId, orderFragmentId, supplierId }) => ({
			value: orderFragmentId,
			label: `${orderId}-${orderFragmentId} ${suppliers.find(({ id }) => id === supplierId)?.personalInformation?.firstName}`,
		}))
	}

	const saveIncidence = () => {
		if (!objectData.observation) {
			setIsMessageModalOpen(true)
			return
		}
		const incidenceInput = {
			qualityPartState: {
				partId,
				orderId,
				quantity: Number(objectData.partsOK) + Number(objectData.partsWrong),
				state: false,
			},
			qualityVerification: {
				partsOK: Number(objectData.partsOK),
				partsWrong: Number(objectData.partsWrong),
				problem: objectData.problem,
				agent: accountId,
				solution: objectData.solution,
				notifiedBy: objectData.notifiedBy,
				observation: objectData.observation,
				orderFragmentId: getOrderFragmentId(),
			},
		}
		createPartVerification({ data: incidenceInput })
	}


	useEffect(() => {
		if (qualityVerification != null && imageFiles != null) {
			const imagesInput = {
				data: {
					qualityVerificationId: qualityVerification.id,
					partId: qualityVerification.partId,
				},
				images: imageFiles,
			}
			uploadQualityVerificationImages(imagesInput)
			if (isModalInOrderDetail) {
				setTimeout(() => {
					close()
				}, 3200)
			} else {
				close()
			}
		}

	}, [qualityVerification])

	useEffect(() => {
		if (images.length != 0) {
			addQuatlityVerificationImages({ orderId, qualityVerificationId: qualityVerification.id, images })
		}
	}, [images])

	return (
		<>
			<Modal
				className="modal-lg"
				isOpen={isOpen}
				closeModal={close}
				title={`${t('register-incidence', { ns: 'naming' })} ${t('of-the-part', { ns: 'naming' })} ${partId} ${t('of-the-order', { ns: 'naming' })} ${orderId}`}
				body={
					<div className="px-4 py-2 d-flex flex-column">
						{
							partHasMultipleProductionItems() && (
								<OptionsInput
									className="w-90"
									value={objectData.orderFragmentId}
									objectElement="orderFragmentId"
									setValue={handleOnChange}
									options={getProductionsOptions()}
									automaticMappedValue
									title={t('production', { ns: 'naming' })}
								/>
							)
						}
						<div className="d-flex flex-row">
							<OptionsInput
								className="w-50"
								value={objectData.problem}
								objectElement="problem"
								setValue={handleOnChange}
								options={problemsOptions}
								automaticMappedValue
								title={t('incidence', { ns: 'naming' })}
							/>
							<OptionsInput
								className="w-50"
								value={objectData.solution}
								objectElement="solution"
								setValue={handleOnChange}
								options={solutionsOptions}
								automaticMappedValue
								title={t('solution', { ns: 'naming' })}
							/>
						</div>
						<div className="d-flex flex-row w-50">
							<OptionsInput
								className="w-100"
								value={objectData.partsWrong}
								objectElement="partsWrong"
								setValue={handleOnChange}
								automaticMappedValue
								title={t('parts-wrong', { ns: 'naming' })}
								type="number"
								onBlur={value => {
									if (value == '') handleOnChange('partsWrong', 0)
								}}
								options={quantityList}
							/>
						</div>
						<TextInput
							value={objectData.observation}
							objectElement="observation"
							setValue={handleOnChange}
							title={t('observation', { ns: 'naming' })}
							type="text"
							isRequired={true}
						/>
						<div className="d-flex flex-row">
							<FileInput
								className="w-50"
								value={objectData.images}
								objectElement="images"
								setValue={handleOnChange}
								setFiles={setImageFiles}
								title={t('images', { ns: 'naming' })}
								multiple
								accept="image/*"
							/>
							<OptionsInput
								className="w-50"
								value={objectData.notifiedBy}
								objectElement="notifiedBy"
								setValue={handleOnChange}
								options={notifiedOptions}
								automaticMappedValue
								title={t('notified-by', { ns: 'naming' })}
							/>
						</div>
					</div>
				}
				buttons={[
					<button
						type="button"
						key="saveButton"
						className="btn btn-primary"
						disabled={objectData.partsWrong == 0}
						onClick={e => {
							saveIncidence()
						}}

					>
						{isLoading ? <Loader /> : t('save', { ns: 'naming' })}
					</button>,
				]}
			/>
			<ModalMessage
				closeModal={() => setIsMessageModalOpen(false)}
				isModalOpen={isMessageModalOpen}
				message={t("field-observation-empty", { ns: "naming" })}

			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		problems: state.Orders.qualityProblems,
		solutions: state.Orders.qualitySolutions,
		qualityVerification: state.Orders.qualityVerification,
		images: state.CloudStorage.images,
		isLoading: state.Orders.isLoadingQuality || state.CloudStorage.isLoading,
		suppliers: state.Users.suppliers,
	}
}

RegisterIncidenceModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partId: PropTypes.number,
	orderId: PropTypes.number,
	accountId: PropTypes.number,
	quantityList: PropTypes.array,
	problems: PropTypes.array,
	solutions: PropTypes.array,
	createPartVerification: PropTypes.func,
	qualityVerification: PropTypes.object,
	uploadQualityVerificationImages: PropTypes.func,
	images: PropTypes.bool,
	isLoading: PropTypes.bool,
	addQuatlityVerificationImages: PropTypes.func,
	productions: PropTypes.array,
	suppliers: PropTypes.array,
}

export default connect(mapStateToProps, { createPartVerification, uploadQualityVerificationImages, addQuatlityVerificationImages })(withTranslation()(RegisterIncidenceModal))
