import { CANCELED_CODE } from 'constants/errorsCode'
import {
	CREATE_PARTS_QUANTITIES,
	CREATE_PARTS_QUANTITIES_FAIL,
	CREATE_PARTS_QUANTITIES_SUCCESS,
	DELETE_BINDING_NOTES,
	DELETE_BINDING_NOTES_FAIL,
	DELETE_BINDING_NOTES_SUCCESS,
	GET_FILTERED_PARTS,
	GET_FILTERED_PARTS_FAIL,
	GET_FILTERED_PARTS_SUCCESS,
	GET_PART_CONFIG_OPTIONS,
	GET_PART_CONFIG_OPTIONS_FAIL,
	GET_PART_CONFIG_OPTIONS_SUCCESS,
	GET_PARTS_PREDICTS_AND_REAL,
	GET_PARTS_PREDICTS_AND_REAL_FAIL,
	GET_PARTS_PREDICTS_AND_REAL_SUCCESS,
	GET_SIMILAR_PARTS_DETAIL,
	GET_SIMILAR_PARTS_DETAIL_FAIL,
	GET_SIMILAR_PARTS_DETAIL_SUCCESS,
	GET_TECHNOLOGIES_DASHBOARD,
	GET_TECHNOLOGIES_DASHBOARD_FAIL,
	GET_TECHNOLOGIES_DASHBOARD_SUCCESS,
	UPDATE_BINDING_NOTES,
	UPDATE_BINDING_NOTES_FAIL,
	UPDATE_BINDING_NOTES_SUCCESS,
	UPDATE_PARTS,
	UPDATE_PARTS_FAIL,
	UPDATE_PARTS_STATUS,
	UPDATE_PARTS_STATUS_FAIL,
	UPDATE_PARTS_STATUS_SUCCESS,
	UPDATE_PARTS_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	createPartsQuantitiesSuccess: null,
	isLoading: false,
	error: null,
	errorUpdateStatus: null,
	errorQuantities: null,
	partConfigOptions: null,
	partConfigOptionsLoading: false,
	partConfigOptionsError: null,
	updatePartsLoading: false,
	updatePartsError: null,
	updatePartsSuccess: false,
	filtredParts: null,
	similarParts: null,
	updateBindingNotesStatus: {
		isLoading: null,
		success: null,
		error: null,
	},
	deleteBindingNotesStatus: {
		isLoading: null,
		success: null,
		error: null,
	},
	updatePartsState: {
		status: undefined,
		response: undefined,
		error: undefined,
	},
	technologiesDashboardItems: [],
	technologiesDashboardStats: null,
	partsWithPredict: null,
}

const Parts = (state = INIT_STATE, action) => {
	switch (action.type) {

		case GET_PARTS_PREDICTS_AND_REAL:
			return {
				...state,
				isLoading: true,
				error: null,
				partsWithPredict: null,
			}
		case GET_PARTS_PREDICTS_AND_REAL_SUCCESS:
			return {
				...state,
				isLoading: false,
				partsWithPredict: action.payload,
			}

		case GET_PARTS_PREDICTS_AND_REAL_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.message,
				partsWithPredict: null,
			}

		case UPDATE_PARTS_STATUS:
			if (!action.payload.editingWithinBindingNoteModal) {
				return {
					...state,
					errorQuantities: null, //TODO review this 
					errorUpdateStatus: null,
					successUpdateStatus: null,
					isLoading: true,
				}
			} else {
				return {
					...state,
					successUpdatePartsStatusWithinBindingNoteModal: null,
					isLoading: true,
				}
			}
		case UPDATE_PARTS_STATUS_SUCCESS:
			if (!action.payload.editingWithinBindingNoteModal) {
				return {
					...state,
					errorUpdateStatus: false,
					successUpdateStatus: true,
					isLoading: false,
				}
			} else {
				return {
					...state,
					successUpdatePartsStatusWithinBindingNoteModal: true,
					isLoading: false,
				}
			}
		case UPDATE_PARTS_STATUS_FAIL:
			if (!action.payload.editingWithinBindingNoteModal) {
				return {
					...state,
					errorUpdateStatus: true,
					successUpdateStatus: false,
					isLoading: false,
				}
			} else {
				return {
					...state,
					successUpdatePartsStatusWithinBindingNoteModal: false,
					isLoading: false,
				}
			}
		case CREATE_PARTS_QUANTITIES:
			return {
				...state,
				errorQuantities: null,
				isLoading: true,
			}
		case CREATE_PARTS_QUANTITIES_SUCCESS:
			return {
				...state,
				createPartsQuantitiesSuccess: true,
				errorQuantities: false,
				isLoading: false,
			}
		case CREATE_PARTS_QUANTITIES_FAIL:
			return {
				...state,
				errorQuantities: true,
				isLoading: false,
			}

		case GET_PART_CONFIG_OPTIONS:
			return {
				...state,
				partConfigOptionsLoading: true,
				partConfigOptionsError: null,
			}
		case GET_PART_CONFIG_OPTIONS_SUCCESS:
			return {
				...state,
				partConfigOptionsLoading: false,
				partConfigOptions: action.payload,
			}
		case GET_PART_CONFIG_OPTIONS_FAIL:
			return {
				...state,
				partConfigOptionsLoading: false,
				partConfigOptionsError: action.payload.message,
			}

		case UPDATE_PARTS:
			return {
				...state,
				updatePartsLoading: true,
				updatePartsError: null,
				updatePartsSuccess: false,
				updatePartsState: {
					status: 'fetching',
					response: undefined,
					error: undefined,
				},
			}
		case UPDATE_PARTS_SUCCESS:
			return {
				...state,
				updatePartsLoading: false,
				updateParts: action.payload,
				updatePartsSuccess: true,
				updatePartsState: {
					status: 'finished',
					response: action.payload.response,
					error: undefined,
				},
			}
		case UPDATE_PARTS_FAIL:
			return {
				...state,
				updatePartsLoading: false,
				updatePartsError: action.payload.message,
				updatePartsSuccess: false,
				updatePartsState: {
					status: 'finished',
					response: undefined,
					error: action.payload.error,
				},
			}

		case GET_FILTERED_PARTS:
			return {
				...state,
				error: null,
				isLoading: true,
				filtredParts: null,
			}
		case GET_FILTERED_PARTS_SUCCESS:
			return {
				...state,
				filtredParts: action.payload.parts,
				error: false,
				isLoading: false,
			}
		case GET_FILTERED_PARTS_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: action.payload.error.code === CANCELED_CODE,
			}

		case GET_SIMILAR_PARTS_DETAIL:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case GET_SIMILAR_PARTS_DETAIL_SUCCESS:
			return {
				...state,
				similarParts: action.payload.parts,
				error: false,
				isLoading: false,
			}
		case GET_SIMILAR_PARTS_DETAIL_FAIL:
			return {
				...state,
				error: true,
				isLoading: false,
			}

		case UPDATE_BINDING_NOTES:
			return {
				...state,
				updateBindingNotesStatus: {
					isLoading: true,
					success: null,
					error: null,
				},
			}
		case UPDATE_BINDING_NOTES_SUCCESS:
			return {
				...state,
				updateBindingNotesStatus: {
					isLoading: false,
					success: true,
					error: null,
				},
			}
		case UPDATE_BINDING_NOTES_FAIL:
			return {
				...state,
				updateBindingNotesStatus: {
					isLoading: false,
					success: false,
					error: action.payload,
				},
			}

		case DELETE_BINDING_NOTES:
			return {
				...state,
				deleteBindingNotesStatus: {
					isLoading: true,
					success: null,
					error: null,
				},
			}
		case DELETE_BINDING_NOTES_SUCCESS:
			return {
				...state,
				deleteBindingNotesStatus: {
					isLoading: false,
					success: true,
					error: null,
				},
			}
		case DELETE_BINDING_NOTES_FAIL:
			return {
				...state,
				deleteBindingNotesStatus: {
					isLoading: false,
					success: false,
					error: action.payload,
				},
			}
		case GET_TECHNOLOGIES_DASHBOARD:
			return {
				...state,
				isLoading: true,
				error: null,
				technologiesDashboardItems: [],
				technologiesDashboardStats: null,
			}
		case GET_TECHNOLOGIES_DASHBOARD_SUCCESS:
			return {
				...state,
				isLoading: false,
				technologiesDashboardItems: action.payload.items,
				technologiesDashboardStats: action.payload.dashboardStats,
			}
		case GET_TECHNOLOGIES_DASHBOARD_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.message,
				technologiesDashboardItems: [],
				technologiesDashboardStats: null,
			}
		default:
			return state
	}
}
export default Parts
