import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { getDate } from 'common/Utils/StringUtilities'
import { weekdays } from 'constants/dates'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
const ArriveAndShipmentSemaphore = ({
	estArriveDate,
	realArriveDate,
	estShipmentDate,
	realShipmentDate,
	t,
}) => {
	const DAY = t('day', { ns: 'naming' })
	const DAYS = t('days', { ns: 'naming' })
	const REMAINING = t('remaining', { ns: 'naming' })
	const REMAININGS = t('remainings', { ns: 'naming' })
	const LATE = t('late', { ns: 'naming' })

	const getWeekDay = (dateStr, language) => {
		if (dateStr) {
			const date = new Date(dateStr)
			const days = date.getDay()
			return weekdays[days][language] + ' '
		}
	}

	const getRemainingDays = () => {
		let actualDate = new Date(estArriveDate)
		if (realArriveDate) {
			actualDate = new Date(realArriveDate)
		}
		const shipmentDate = new Date(estShipmentDate)
		shipmentDate.setHours(0, 0, 0, 0)
		actualDate.setHours(0, 0, 0, 0)
		return Math.floor((shipmentDate - actualDate) / 86400000)
	}

	useEffect(() => {
		const remainingDays = getRemainingDays()
		setRemainingDays(remainingDays)
		setClassNameIcon(getClassName(remainingDays))
	}, [estArriveDate])

	let leftOrLate = t('remaining', { ns: 'naming' })

	const getClassName = days => {
		switch (true) {
			case 1 == days || 0 == days:
				return 'bx bxs-circle text-warning me-1'
			case 0 > days:
				leftOrLate = t('late', { ns: 'naming' })
				return 'bx bxs-circle text-danger me-1'
			case 0 < days:
				return 'bx bxs-circle text-success me-1'
		}
	}
	const [remainingDays, setRemainingDays] = useState(getRemainingDays())
	const [classNanemIcon, setClassNameIcon] = useState(
		getClassName(remainingDays),
	)
	return (
		<div>
			<div>
				{realArriveDate != '' && realArriveDate != null ? (
					<strong>
						{getDate(realArriveDate, getLanguage())}
						{' ' + getWeekDay(realArriveDate, getLanguage())}
						<small>(REAL)</small>
					</strong>
				) : (
					<span>
						{estArriveDate != null ? getDate(estArriveDate, getLanguage()) : '--/--/--'}
						{estArriveDate != null && ' ' + getWeekDay(estArriveDate, getLanguage())}
						<small>(ESTM)</small>
					</span>
				)}
			</div>
			<div>
				{realShipmentDate != '' && realShipmentDate != null ? (
					<strong>
						{getDate(realShipmentDate, getLanguage())}
						{' ' + getWeekDay(realShipmentDate, getLanguage())}
						<small>(REAL)</small>
					</strong>
				) : (
					<span>
						<small>{realShipmentDate}</small>
						{estShipmentDate != null ? getDate(estShipmentDate, getLanguage()) : '--/--/--'}
						{estShipmentDate && ' ' + getWeekDay(estShipmentDate, getLanguage())}
						<small>(ESTM)</small>
					</span>
				)}
			</div>
			<div>
				{(realShipmentDate === '' || realShipmentDate == null) && estShipmentDate && (
					<div className="pb-1">
						<i className={classNanemIcon}></i>
						{remainingDays != null && remainingDays != NaN &&
							<span>
								{remainingDays}
								{Math.abs(remainingDays) == 1
									? ` ${DAY} ${leftOrLate}`
									: ` ${DAYS} ${leftOrLate}`}
							</span>
						}
					</div>
				)}
			</div>
		</div>
	)
}

ArriveAndShipmentSemaphore.propTypes = {
	estArriveDate: PropTypes.string,
	realArriveDate: PropTypes.string,
	estShipmentDate: PropTypes.string,
	realShipmentDate: PropTypes.string,
	t: PropTypes.func,
}
export default withTranslation()(ArriveAndShipmentSemaphore)
