import { showDate } from 'common/Utils/DateUtils'
import FileInput from 'components/Common/FileInput'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import Table from 'components/Table/Table'
import { Couriers } from 'constants/couriers'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { getTrackingNumbersFromProvider } from 'store/actions'

export const FinishProductionModal = ({
	t,
	isLoading,
	supplierCountry,
	isOpen,
	closeModal,
	step,
	setStep,
	itemsToRender,
	handleFinishProduction,
	newShippingCompany,
	setNewShippingCompany,
	newShipmentTracking,
	setNewShipmentTracking,
	declaredValue,
	setDeclaredValue,
	setInvoiceFile,
	setPartImagesFiles,
	newShipmentDate,
	setNewShipmentDate,
	isShippingAllParts,
	selectShipmentTrackingValue,
	setSelectShipmentTrackingValue,
	getTrackingNumbersFromProvider,
	getTrackingNumbersFromProvider_Status,
	providerId,
	currency
}) => {

	const [openMessageModal, setOpenMessageModal] = useState(false)
	const [messageModalContent, setMessageModalContent] = useState('')
	useEffect(() => {
		if (getTrackingNumbersFromProvider_Status.loading == false) {
			if (getTrackingNumbersFromProvider_Status.response == null) {
				getTrackingNumbersFromProvider({
					data: {
						providerId: providerId,
					},
				})
			} else if (getTrackingNumbersFromProvider_Status.error != null) {
				alert(t('error_message', { ns: 'naming' }))
			}
		}
	}, [])

	const shipmentTrackingOptions = useMemo(() => {
		if (getTrackingNumbersFromProvider_Status.response != null) {
			const options = []
			getTrackingNumbersFromProvider_Status.response.shipmentTrackingGroups.map(x => {
				options.push({
					label: `${t('shipped_on', { ns: 'naming' })} ${showDate(x.date)}`,
					options: x.shipmentTrackings.map(y => ({ label: y, value: y })),
				})
			})
			return [
				{ label: <i>{t('new_tracking_number', { ns: 'naming' })}</i>, value: 0 },
				...options,
			]
		}
	}, [getTrackingNumbersFromProvider_Status.response])

	const [invoice, setInvoice] = useState()
	const [partImages, setPartImages] = useState()

	const internationalSupplier = supplierCountry.toLowerCase() != 'es'
	const isSupplierChinese = supplierCountry.toLowerCase() == 'cn'

	const header = {
		partId: 'part-id',
		part: 'part',
		technology: 'technology',
		description: 'description',
		pendingQuantity: 'pending-quantity',
		shipping: 'shipping',
		total: 'total'
	}

	const shippingCompanies = [
		{
			value: Couriers.DHL,
			label: 'DHL',
			maxCharacters: 10,
		},
		{
			value: Couriers.NACEX,
			label: 'NACEX',
			maxCharacters: 8,
		},
		{
			value: Couriers.HAND_DELIVERY,
			label: t('hand-delivery', { ns: 'naming' }),
			maxCharacters: 0,
		},
	]

	const getShippingCompanies = () => {
		if (internationalSupplier) {
			return [shippingCompanies[0]]
		}
		return shippingCompanies
	}

	const isButtonDisabled = () => {
		if (internationalSupplier) {
			return !(newShippingCompany && newShipmentTracking?.length > 0 && declaredValue > 0 && invoice && partImages?.length > 0)
		}
		return false
	}

	const openMessage = (message) => {
		setMessageModalContent(t(message, { ns: 'naming' }))
		setOpenMessageModal(true)
	}


	return (
		<>
			<Modal
				isOpen={isOpen}
				closeModal={() => closeModal()}
				title={t('finish-production', { ns: 'naming' })}
				className={step === 2 ? 'largeModal' : ''}
				body={
					<div className="mx-3">
						{step === 1 && <div>
							<h5 className="mt-2">{t('finish-production-title', { ns: 'naming' })}</h5>
							<p>{t('finish-production-1', { ns: 'naming' })}</p>
							<p className="mb-1">{t('finish-production-2', { ns: 'naming' })}</p>
							{internationalSupplier && <p className="my-1">{t('finish-production-3', { ns: 'naming' })}</p>}
							{internationalSupplier && <p className="my-1">{t('finish-production-4', { ns: 'naming' })}</p>}
							{internationalSupplier && <p className="mt-1">{t('finish-production-5', { ns: 'naming' })}</p>}
							<p>{t('finish-production-6', { ns: 'naming' })}</p>
						</div>}
						{step === 2 &&
							<div className='d-flex flex-column'>
								<Table
									disableInteractions
									header={header}
									items={itemsToRender}
									selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
									language={'en'}
									rowQuantityList={[{ text: '1', value: 1 }]}
									allowAllItemsPerPage
									defaultSort={{ dataField: 'id', order: 'asc' }}
									styleForFieldList={[
										{
											field: 'description',
											styles: [{ type: 'list', class: 'd-block' }],
										},
									]}
								/>
								<div className='text-end m-4'>
									<span className='me-2 fw-bold fs-5'>{t('value-sent', { ns: 'naming' })}</span><span className='fs-5'>{itemsToRender.reduce((acc, item) => acc + parseFloat(item.total.value), 0).toFixed(2)} {currency}</span>
								</div>
							</div>
						}
						{!isShippingAllParts && step === 3 && <div className='m-2'>
							<div className='mb-1'>{t('new-shipment-date-message', { ns: 'naming' })}</div>
							<Flatpickr
								className="form-control"
								placeholder={t('new-shipment-date', { ns: 'naming' })}
								options={{
									dateFormat: 'd/m/Y',
									locale: {
										firstDayOfWeek: 1,
									},
								}}
								value={newShipmentDate}
								onChange={(e, dateStr) => {
									setNewShipmentDate(e[0])
								}}
							/>
						</div>}
						{(isShippingAllParts ? step === 3 : step === 4) && <div>
							<OptionsInput
								isMulti={false}
								value={newShippingCompany}
								automaticMappedValue
								setValue={setNewShippingCompany}
								title={t('courier', { ns: 'naming' })}
								options={getShippingCompanies()}
								isRequired={isSupplierChinese}
							/>
							{newShippingCompany != Couriers.HAND_DELIVERY && (
								<>
									<h6>
										{<span className="text-primary me-1">✲</span>}
										{t('shipment-tracking', { ns: 'naming' })}
										{isSupplierChinese && <i
											className="bx bx-info-circle ms-1 fs-6"
											onClick={() =>
												openMessage(
													'shipment-tracking-info-message'
												)}
											style={{ cursor: 'pointer' }}
										></i>}

									</h6>
									<Select
										id="trackingNumber"
										defaultValue={shipmentTrackingOptions[0]}
										options={shipmentTrackingOptions}
										styles={{
											groupHeading: () => ({
												fontWeight: 'bold',
												color: 'black',
												fontSize: '1em',
												marginLeft: '0.5em',
											}),
										}}
										className="basic-multi-select m-2"
										classNamePrefix="select"
										onChange={e => {
											setSelectShipmentTrackingValue(e.value)
											if (e.value != 0) setNewShipmentTracking(e.value)
											else setNewShipmentTracking('')
										}}
									/>
									{selectShipmentTrackingValue == 0 && <TextInput
										id="trackingIdentifier"
										maxLength={shippingCompanies.find(e => e.value == newShippingCompany)?.maxCharacters || 10}
										placeholder={t('shipment-tracking-placeholder', { ns: 'naming' })}
										value={newShipmentTracking}
										setValue={setNewShipmentTracking}
									/>}
								</>
							)}
							{internationalSupplier &&
								<div>
									<TextInput
										id="internationalSupplier"
										title={
											<span className="flex align-items-center">
												{t('declared-value', { ns: 'naming' })}
												{isSupplierChinese && <i
													className="bx bx-info-circle ms-1 fs-6"
													onClick={() => openMessage(
														'declared-value-info-message'
													)}
													style={{ cursor: 'pointer' }}
												></i>
												}

											</span>
										}
										value={declaredValue}
										setValue={setDeclaredValue}
										type='number'
										isRequired={true}
									/>
								</div>
							}
							<FileInput
								id="PDFFileInput"
								title={internationalSupplier ? t('invoice-pdf', { ns: 'naming' }) : t('invoice-dispatch-note-pdf', { ns: 'naming' })}
								value={invoice}
								setValue={setInvoice}
								setFiles={setInvoiceFile}
								accept={'.pdf'}
								isRequired={isSupplierChinese}
							/>
							<FileInput
								id="imgFileInput"
								title={t('part-images', { ns: 'naming' })}
								value={partImages}
								setValue={setPartImages}
								setFiles={setPartImagesFiles}
								multiple={true}
								accept={'.png, .jpg'}
								isRequired={isSupplierChinese}
							/>
						</div>}
					</div>
				}
				buttons={[
					<button
						key="finishProductionNextButton"
						type="button"
						className="btn btn-primary"
						hidden={(isShippingAllParts ? step === 3 : step === 4)}
						onClick={() => setStep(step + 1)}
					>
						<span className="p-2">{t('continue', { ns: 'naming' })}</span>
					</button>,
					<button
						key="finishProductionButton"
						type="button"
						className="btn btn-primary"
						hidden={(isShippingAllParts ? step !== 3 : step !== 4)}
						onClick={() => handleFinishProduction()}
						disabled={isButtonDisabled()}
					>
						{isLoading ? <Loader /> : <span className="p-2">{t('finish-production', { ns: 'naming' })}</span>}
					</button>,
				]}
			/>

			<ModalMessage isModalOpen={openMessageModal} closeModal={() => setOpenMessageModal(false)} message={messageModalContent} />
		</>
	)
}

FinishProductionModal.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	supplierCountry: PropTypes.string,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	step: PropTypes.number,
	setStep: PropTypes.func,
	itemsToRender: PropTypes.array,
	handleFinishProduction: PropTypes.func,
	newShippingCompany: PropTypes.object,
	setNewShippingCompany: PropTypes.func,
	newShipmentTracking: PropTypes.string,
	setNewShipmentTracking: PropTypes.func,
	declaredValue: PropTypes.number,
	setDeclaredValue: PropTypes.func,
	setInvoiceFile: PropTypes.func,
	setPartImagesFiles: PropTypes.func,
	newShipmentDate: PropTypes.any,
	setNewShipmentDate: PropTypes.func,
	isShippingAllParts: PropTypes.bool,
	currency: PropTypes.string,
}

const mapStateToProps = (state) => ({
	isLoading: state.Productions.isLoading,
	getTrackingNumbersFromProvider_Status: state.Productions.getTrackingNumbersFromProvider_Status,
})

export default connect(mapStateToProps, {
	getTrackingNumbersFromProvider,
})(withTranslation()(FinishProductionModal))
