import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useObjectState } from 'common/Hooks/UseObjectState'
import { getQuotesStatusKeyValuePair } from 'common/Utils/StatusUtils'
import { getSupplierViewId } from 'common/Utils/SuppliersUtils'
import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SearchInput from 'components/Common/SearchInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getQuoteList } from 'store/actions'

export const QuoteListFilters = ({ t, page, pageSize, handleOnChangePagination, getQuoteList, supplierIdView }) => {

	const [keyword, setKeyword] = useState('')
	const [isTheFirstApiCall, setIsTheFirstApiCall] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const supplierId = getSupplierViewId(parseInt(supplierIdView))

	const { handleOnChange, handleOnChangeDateRange, objectData, resetDateRange, resetState } = useObjectState({
		availableStatus: [],
		dateRange: {
			startDate: null,
			endDate: null,
		},
	})

	const requestBody = {
		data: {
			supplierId, keyword, pagination: { page, pageSize }, ...objectData,
		},
	}

	const { handleApiCall, cancelApiCall } = useHandleCancelableApi(getQuoteList, requestBody, 0)

	const handleGetQuoteList = () => {
		if (supplierId) {
			handleApiCall()
			if (page !== 1) {
				handleOnChangePagination({ pageSize, page: 1 })
			} else {
				handleApiCall()
			}
		}
	}

	useEffect(() => {
		handleGetQuoteList()
	}, [keyword, supplierId])

	useEffect(() => {
		if (supplierId) {
			handleApiCall()
		}
	}, [page, pageSize])

	const handleOnSave = () => {
		setIsModalOpen(false)
		handleGetQuoteList()
	}

	return (
		<div className='d-flex'>
			<div className="position-relative me-3">
				<SearchInput
					onChange={(e) => setKeyword(e)}
				/>
			</div>
			<div>
				<button id={'Filter' + 'Button'} className='btn btn-secondary' onClick={() => setIsModalOpen(true)}>
					{t('filter', { ns: 'naming' })}
				</button>
				<Modal
					title={t('filter', { ns: 'naming' })}
					body={
						<div className='p-4'>
							<OptionsInput
								isMulti
								value={objectData.availableStatus}
								setValue={handleOnChange}
								objectElement='availableStatus'
								title={t('status', { ns: 'naming' })}
								options={getQuotesStatusKeyValuePair(t)}
								automaticMappedValue
							/>
							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.dateRange?.startDate, objectData.dateRange?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('production_date', { ns: 'naming' })}
								objectElement={'dateRange'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>
						</div>
					}
					buttons={[
						<button id={'Apply' + 'Button'} key="saveButtonOfferFilterList" className='btn btn-primary' onClick={(e) => handleOnSave()}>
							{t('apply', { ns: 'naming' })}</button>,
					]}
					isOpen={isModalOpen}
					closeModal={() => setIsModalOpen(false)}
				/>
			</div>
		</div>
	)
}

QuoteListFilters.propTypes = {
	t: PropTypes.func,
	pagination: PropTypes.object,
	handleOnChangePagination: PropTypes.func,
	getQuoteList: PropTypes.func,
	supplierIdView: PropTypes.number,
	pageSize: PropTypes.number,
	page: PropTypes.number,

}

const mapStateToProps = (state) => ({
	supplierIdView: state.Quotes.supplierIdView,
	userRoles: state.Login.roles,
})

const mapDispatchToProps = { getQuoteList }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuoteListFilters))
