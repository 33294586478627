import { CANCELED_CODE } from 'constants/errorsCode'

import {
	ADD_EXPEDITION_ORDER,
	ADD_EXPEDITION_TO_ORDER,
	ADD_INTERNAL_COMMENT,
	ADD_QUALITY_VERIFICATION_IMAGES,
	ADD_SCANNED_ORDER,
	ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER,
	ADD_SCANNED_PIECE_TO_ORDER,
	ADD_SCANNED_VERIFICATION_ORDER,
	CREATE_INTERNAL_COMMENT,
	CREATE_INTERNAL_COMMENT_FAIL,
	CREATE_INTERNAL_COMMENT_SUCCESS,
	CREATE_INVOICE,
	CREATE_INVOICE_FAIL,
	CREATE_INVOICE_SUCCESS,
	CREATE_NEW_NACEX_EXPEDITION,
	CREATE_NEW_NACEX_EXPEDITION_FAIL,
	CREATE_NEW_NACEX_EXPEDITION_SUCCESS,
	CREATE_NEW_UPS_EXPEDITION,
	CREATE_NEW_UPS_EXPEDITION_FAIL,
	CREATE_NEW_UPS_EXPEDITION_SUCCESS,
	CREATE_PART_VERIFICATION,
	CREATE_PART_VERIFICATION_FAIL,
	CREATE_PART_VERIFICATION_SUCCESS,
	CREATE_REMIT,
	CREATE_REMIT_FAIL,
	CREATE_REMIT_SUCCESS,
	CREATE_SHIPMENT_EXPORT,
	CREATE_SHIPMENT_EXPORT_FAIL,
	CREATE_SHIPMENT_EXPORT_SUCCESS,
	CREATE_VOUCHER,
	CREATE_VOUCHER_FAIL,
	CREATE_VOUCHER_SUCCESS,
	GET_BILLING_DASHBOARD,
	GET_BILLING_DASHBOARD_FAIL,
	GET_BILLING_DASHBOARD_SUCCESS,
	GET_BILLING_LIST,
	GET_BILLING_LIST_FAIL,
	GET_BILLING_LIST_SUCCESS,
	GET_BOX_LIST,
	GET_BOX_LIST_FAIL,
	GET_BOX_LIST_SUCCESS,
	GET_DOMAIN_ORDERS,
	GET_DOMAIN_ORDERS_FAIL,
	GET_DOMAIN_ORDERS_SUCCESS,
	GET_EXPEDITION_NACEX_LABELS,
	GET_EXPEDITION_NACEX_LABELS_FAIL,
	GET_EXPEDITION_NACEX_LABELS_SUCCESS,
	GET_EXPEDITION_UPS_LABELS,
	GET_EXPEDITION_UPS_LABELS_FAIL,
	GET_EXPEDITION_UPS_LABELS_SUCCESS,
	GET_EXPORTED_PART_BY_DATE_LIST,
	GET_EXPORTED_PART_BY_DATE_LIST_FAIL,
	GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS,
	GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS,
	GET_FILTERED_ORDERS,
	GET_FILTERED_ORDERS_FAIL,
	GET_FILTERED_ORDERS_SUCCESS,
	GET_INCIDENCES_LIST,
	GET_INCIDENCES_LIST_FAIL,
	GET_INCIDENCES_LIST_SUCCESS,
	GET_LAST_THREE_DAYS_SHIPMENTS,
	GET_LAST_THREE_DAYS_SHIPMENTS_FAIL,
	GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS,
	GET_OPENED_EXPEDITION_ORDERS,
	GET_OPENED_EXPEDITION_ORDERS_FAIL,
	GET_OPENED_EXPEDITION_ORDERS_SUCCESS,
	GET_OPENED_VERIFICATION_ORDERS,
	GET_OPENED_VERIFICATION_ORDERS_FAIL,
	GET_OPENED_VERIFICATION_ORDERS_SUCCESS,
	GET_OPEPENED_ORDERS,
	GET_OPEPENED_ORDERS_FAIL,
	GET_OPEPENED_ORDERS_SUCCESS,
	GET_ORDER_DETAIL,
	GET_ORDER_DETAIL_BY_ID,
	GET_ORDER_DETAIL_BY_ID_FAIL,
	GET_ORDER_DETAIL_BY_ID_SUCCESS,
	GET_ORDER_DETAIL_FAIL,
	GET_ORDER_DETAIL_SUCCESS,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS,
	GET_ORDER_LIST,
	GET_ORDER_LIST_FAIL,
	GET_ORDER_LIST_SUCCESS,
	GET_PENDING_INVOICE_ORDERS,
	GET_PENDING_INVOICE_ORDERS_FAIL,
	GET_PENDING_INVOICE_ORDERS_SUCCESS,
	GET_QUALITY_PROBLEMS,
	GET_QUALITY_PROBLEMS_FAIL,
	GET_QUALITY_PROBLEMS_SUCCESS,
	GET_QUALITY_QUANTITIES,
	GET_QUALITY_QUANTITIES_FAIL,
	GET_QUALITY_QUANTITIES_SUCCESS,
	GET_QUALITY_SOLUTIONS,
	GET_QUALITY_SOLUTIONS_FAIL,
	GET_QUALITY_SOLUTIONS_SUCCESS,
	GET_VOUCHER_LIST,
	GET_VOUCHER_LIST_FAIL,
	GET_VOUCHER_LIST_SUCCESS,
	HANDLE_CANCELLED_ORDER,
	HANDLE_CANCELLED_ORDER_FAIL,
	HANDLE_CANCELLED_ORDER_SUCCESS,
	HANDLE_SENT_ORDER,
	HANDLE_SENT_ORDER_FAIL,
	HANDLE_SENT_ORDER_SUCCESS,
	HANDLE_SUCCESSFUL_ORDER,
	HANDLE_SUCCESSFUL_ORDER_FAIL,
	HANDLE_SUCCESSFUL_ORDER_SUCCESS,
	MANAGE_SCANNED_QUANTITY_ON_DELIVERY,
	RESET_EXPEDITION_ORDER_BOXCODE,
	RESET_NACEX_INFO,
	RESET_ORDER_BOXCODE,
	RESET_ORDER_DETAIL,
	RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES,
	RESET_SCANNED_PIECES_FROM_ALL_PIECES,
	RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE,
	RESET_SCANNED_PIECES_FROM_PIECE,
	SAVE_GENERATED_PDF,
	SCAN_ORDER_EXPEDITION_PART,
	SCAN_ORDER_EXPEDITION_PART_FAIL,
	SCAN_ORDER_EXPEDITION_PART_SUCCESS,
	SET_COMPLETED_EXPEDITION_ORDER,
	SET_EXPEDITION_BOX_LABEL_GENERATED,
	SET_EXPEDITION_COMMENT_READED,
	SET_EXPEDITION_DELIVERY_NOTE_PRINTED,
	SET_EXPEDITION_ORDER_BOXCODE,
	SET_LAST_SCANNED_PART,
	SET_ORDER_BOXCODE,
	SET_SELECTED_EXPEDITION_ORDER_ID,
	SET_SELECTED_ORDER_ID,
	SET_SELECTED_VERIFICATION_ORDER_ID,
	UPDATE_BOXES_BY_REF,
	UPDATE_BOXES_BY_REF_FAIL,
	UPDATE_BOXES_BY_REF_SUCCESS,
	UPDATE_ORDER,
	UPDATE_ORDER_EXPEDITION_SHIPMENT,
	UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES,
	UPDATE_ORDER_FAIL,
	UPDATE_ORDER_ITEM,
	UPDATE_ORDER_ITEM_FAIL,
	UPDATE_ORDER_ITEM_SUCCESS,
	UPDATE_ORDER_SHIPPING_ADDRESS,
	UPDATE_ORDER_SHIPPING_ADDRESS_FAIL,
	UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS,
	UPDATE_ORDER_SUCCESS,
	UPDATE_QUALITY_VERIFICATION,
	UPDATE_QUALITY_VERIFICATION_FAIL,
	UPDATE_QUALITY_VERIFICATION_SUCCESS,
	UPDATE_VOUCHER_BY_C0DE,
	UPDATE_VOUCHER_BY_C0DE_FAIL,
	UPDATE_VOUCHER_BY_C0DE_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	orderList: [],
	orderListCount: null,
	isOrderHandledSuccessfully: false,
	isOrderUpdatedSuccessfully: false,
	isLoadingOrderList: false,
	billingDashboard: [],
	orderDetail: {},
	scannedOrderList: [],
	scannedVerificationOrderList: [],
	lastScannedPart: null,
	expeditionOrderList: [],
	nacexExpeditionInfo: null,
	selectedOrderId: null,
	selectedExpeditionOrderId: null,
	voucherList: [],
	voucher: {},
	orderShippingAddress: {},
	error: null,
	isLoading: false,
	domainOrders: [],
	isLoadingNacex: false,
	billingList: [],
	isLoadingBillingList: false,
	boxList: [],
	getBoxListStatus: {
		isLoading: false,
		success: null,
		error: null,
	},
	updateBoxesByRefStatus: {
		isLoading: false,
		success: null,
		error: null,
	},
	exportedPartListReport: {
		isLoading: false,
		isLoadingNoPaginated: false,
		success: null,
		error: null,
		parts: [],
		noPaginatedParts: [],
		totalRows: null,
	},
	openedOrders: [],
	openedExpeditonOrders: [],
	openedVerificationOrders: [],
	billingStats: null,
	previousBillingStats: null,
	lastThreeDaysShipments: [],
	billingCustomersStats: [],
	isLoadingUPS: false,
	upsExpeditionInfo: null,
	generatedLabel: null,
	generatedPdf: null,
	isLoadingQuality: false,
	qualityQuantities: [],
	qualityProblems: [],
	qualitySolutions: [],
	qualityPartState: null,
	qualityVerification: null,
	isLoadingComment: false,
	newComment: undefined,
	isShipmentCreated: null,
	shipmentExport: null,
	remit: null,
	invoice: null,
	incidences: [],
	pendingOrders: null,
	isQualityIncidenceCreatedSuccessfully: null,
	scanOrderExpeditionPart_Status: {
		loading: false,
		success: null,
		error: null,
	},
	searchedOrderItems: [null],
}

const Orders = (state = INIT_STATE, action) => {
	switch (action.type) {

		case GET_ORDER_ITEM_BY_ORDER_ID_SEARCH:
			return {
				...state,
				isLoading: true,
				error: null,
			}

		case GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS:
			return {
				...state,
				searchedOrderItems: action.payload,
				error: null,
				isLoading: false,
			}
		case GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL:
			return {
				...state,
				searchedOrderItems: null,
				error: action.payload,
				isLoading: false,
			}

		case UPDATE_QUALITY_VERIFICATION:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case UPDATE_QUALITY_VERIFICATION_SUCCESS:
			return {
				...state,
				error: null,
				isLoading: false,
			}

		case UPDATE_QUALITY_VERIFICATION_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case GET_ORDER_DETAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_ORDER_DETAIL_SUCCESS:
			return {
				...state,
				orderDetail: {
					order: action.payload.order,
					account: action.payload.account,
					organization: action.payload.organization,
				},
				error: null,
				isLoading: false,
			}
		case GET_ORDER_DETAIL_FAIL:
			return {
				...state,
				orderDetail: {},
				error: action.payload.message,
				isLoading: false,
			}
		case GET_ORDER_DETAIL_BY_ID:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_ORDER_DETAIL_BY_ID_SUCCESS:
			return {
				...state,
				orderDetail: action.payload,
				error: null,
				isLoading: false,
			}
		case GET_ORDER_DETAIL_BY_ID_FAIL:
			return {
				...state,
				orderDetail: {},
				error: action.payload,
				isLoading: false,
			}

		case UPDATE_ORDER_SHIPPING_ADDRESS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS:
			return {
				...state,
				orderShippingAddress: action.payload,
				error: null,
				isLoading: false,
			}
		case UPDATE_ORDER_SHIPPING_ADDRESS_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case UPDATE_ORDER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case UPDATE_ORDER_SUCCESS:
			return {
				...state,
				isOrderUpdatedSuccessfully: true,
				orderDetail: {
					...state.orderDetail,
					order: {
						...state.orderDetail.order,
						...action.payload.order,
					},
				},
				error: null,
				isLoading: false,
			}
		case UPDATE_ORDER_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case CREATE_INTERNAL_COMMENT:
			return {
				...state,
				isLoadingComment: true,
				error: null,
				isCommentCreated: null,
			}
		case CREATE_INTERNAL_COMMENT_SUCCESS:
			return {
				...state,
				error: null,
				newComment: action.payload.comment,
				isLoadingComment: false,
				isCommentCreated: true,
			}
		case CREATE_INTERNAL_COMMENT_FAIL:
			return {
				...state,
				error: action.payload,
				isLoadingComment: false,
				isCommentCreated: false,
			}

		case GET_ORDER_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_ORDER_LIST_SUCCESS:
			return {
				...state,
				orderList: action.payload.orderList,
				error: null,
				isLoading: false,
			}

		case GET_ORDER_LIST_FAIL:
			return {
				...state,
				orderList: [],
				error: action.payload,
				isLoading: false,
			}

		case GET_BILLING_DASHBOARD:
			return {
				...state,
				isLoading: true,
				billingDashboard: [],
				billingStats: null,
				billingCustomersStats: [],
				previousBillingStats: null,
				error: null,
			}
		case GET_BILLING_DASHBOARD_SUCCESS:
			return {
				...state,
				billingDashboard: action.payload.billingData,
				billingStats: action.payload.billingStats,
				previousBillingStats: action.payload.previousBillingStats,
				billingCustomersStats: action.payload.currentBillingCustomersStats,
				error: null,
				isLoading: false,
			}

		case GET_BILLING_DASHBOARD_FAIL:
			return {
				...state,
				billingDashboard: [],
				billingStats: null,
				previousBillingStats: null,
				billingCustomersStats: [],
				error: action.payload.message,
				isLoading: false,
			}

		case HANDLE_SUCCESSFUL_ORDER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case HANDLE_SUCCESSFUL_ORDER_SUCCESS:
			return {
				...state,
				orderDetail: {
					...state.orderDetail,
					order: {
						...state.orderDetail.order,
						...action.payload.order,
					},
				},
				isOrderHandledSuccessfully: true,
				isLoading: false,
				error: null,
			}
		case HANDLE_SUCCESSFUL_ORDER_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}

		case HANDLE_CANCELLED_ORDER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case HANDLE_CANCELLED_ORDER_SUCCESS:
			return {
				...state,
				orderDetail: {
					...state.orderDetail,
					order: {
						...state.orderDetail.order,
						...action.payload.order,
					},
				},
				isLoading: false,
				error: null,
			}
		case HANDLE_CANCELLED_ORDER_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case SET_SELECTED_ORDER_ID:
			return {
				...state,
				selectedOrderId: action.payload,
			}
		case SET_SELECTED_VERIFICATION_ORDER_ID:
			return {
				...state,
				selectedVerificationOrderId: action.payload,
			}
		case SET_SELECTED_EXPEDITION_ORDER_ID:
			return {
				...state,
				selectedExpeditionOrderId: action.payload,
			}
		case ADD_SCANNED_ORDER: {
			const newScannedOrderList = [...state.scannedOrderList]
			const index = newScannedOrderList.findIndex(x => x.id == action.payload.order.id)
			if (index != -1) {
				newScannedOrderList[index] = action.payload.order
			} else {
				newScannedOrderList.push(action.payload.order)
			}
			return {
				...state,
				scannedOrderList: newScannedOrderList,
			}
		}
		case SET_LAST_SCANNED_PART:
			return {
				...state,
				lastScannedPart: action.payload,
			}
		case SET_ORDER_BOXCODE:
			return {
				...state,
				scannedOrderList: state.scannedOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						let newBoxCode
						if (order.boxCode) {
							newBoxCode = order.boxCode + ', ' + action.payload.boxCode
						} else {
							newBoxCode = action.payload.boxCode
						}
						return {
							...order,
							boxCode: newBoxCode,
						}
					}
					return order
				}),
			}
		case ADD_SCANNED_PIECE_TO_ORDER:
			return {
				...state,
				scannedOrderList: state.scannedOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						const updatedOrderItems = order.orderItems.map((item) => {
							if (item.part.id === action.payload.pieceId) {
								return {
									...item,
									receivedQuantity: (item.receivedQuantity || 0) + action.payload.quantity,
								}
							}
							return item
						})

						const completed = updatedOrderItems.every(
							(item) => item.quantity <= item.receivedQuantity,
						)

						return {
							...order,
							orderItems: updatedOrderItems,
							completed: completed,
						}
					}
					return order
				}),
			}
		case RESET_SCANNED_PIECES_FROM_PIECE:
			return {
				...state,
				scannedOrderList: state.scannedOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							completed: false,
							orderItems: order.orderItems.map((item) => {
								if (item.part.id == action.payload.pieceId) {
									return {
										...item,
										receivedQuantity: 0,
									}
								}
								return item
							}),
						}
					}
					return order
				}),
			}
		case RESET_SCANNED_PIECES_FROM_ALL_PIECES:
			return {
				...state,
				scannedOrderList: state.scannedOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							completed: false,
							orderItems: order.orderItems.map((item) => {
								return {
									...item,
									receivedQuantity: 0,
								}
							},
							),
						}
					}
					return order
				}),
			}
		case RESET_ORDER_BOXCODE:
			return {
				...state,
				scannedOrderList: state.scannedOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							boxCode: null,
						}
					}
					return order
				}),
			}
		case ADD_EXPEDITION_ORDER: {
			const orderExists = state.expeditionOrderList.some((order) => order.id === action.payload.orderId)
			if (!orderExists) {
				return {
					...state,
					expeditionOrderList: [...state.expeditionOrderList, action.payload.order],
				}
			}
		}

		case ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER: {
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							orderItems: order.orderItems.map((item) => {
								if (item.part.id === action.payload.pieceId) {
									return {
										...item,
										scannedQuantity: item.scannedQuantity + action.payload.quantity,
									}
								}
								return item
							}),
						}
					}
					return order
				}),
			}
		}
		case RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							orderItems: order.orderItems.map((item) => {
								if (item.part.id === action.payload.pieceId) {
									return {
										...item,
										scannedQuantity: 0,
									}
								}
								return item
							}),
						}
					}
					return order
				}),
			}
		case RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							orderItems: order.orderItems.map((item) => {
								return {
									...item,
									scannedQuantity: 0,
								}
							}),
						}
					}
					return order
				}),
			}

		case SET_EXPEDITION_ORDER_BOXCODE:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						let newBoxCode
						if (order.boxCode) {
							newBoxCode = order.boxCode + ', ' + action.payload.boxCode
						} else {
							newBoxCode = action.payload.boxCode
						}
						return {
							...order,
							boxCode: newBoxCode,
						}
					}
					return order
				}),
			}
		case CREATE_NEW_NACEX_EXPEDITION:
			return {
				...state,
				isLoadingNacex: true,
				error: null,
				isShipmentCreated: null,
			}
		case CREATE_NEW_NACEX_EXPEDITION_SUCCESS:
			return {
				...state,
				nacexExpeditionInfo: action.payload.response,
				isLoadingNacex: false,
				isShipmentCreated: true,
				error: null,
			}
		case CREATE_NEW_NACEX_EXPEDITION_FAIL:
			return {
				...state,
				isLoadingNacex: false,
				error: action.payload,
				isShipmentCreated: false,
			}
		case RESET_NACEX_INFO:
			return {
				...state,
				nacexExpeditionInfo: null,
				upsExpeditionInfo: null,
			}
		case SET_COMPLETED_EXPEDITION_ORDER:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						const completed = order.orderItems.every(
							(item) => item.shippedQuantity === item.quantity,
						)
						return {
							...order,
							completed: completed,
						}
					}
					return order
				}),
			}
		case ADD_EXPEDITION_TO_ORDER:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						if (order.shipments.find((shipment) => shipment.id === action.payload.expedition.id)) {
							return {
								...order,
								shipments: order.shipments.map((shipment) => {
									if (shipment.id === action.payload.expedition.id) {
										return action.payload.expedition
									}
									return shipment
								}),
							}
						}
						return {
							...order,
							shipments: [...order.shipments, action.payload.expedition],
						}
					}
					return order
				}),
			}
		case UPDATE_ORDER_EXPEDITION_SHIPMENT:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							shipments: order.shipments.map((shipment) => {
								if (shipment.id === action.payload.shipment.id) {
									return action.payload.shipment
								}
								return shipment
							}),
						}
					}
					return order
				}),
			}
		case UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					return {
						...order,
						shipments: order.shipments.map((shipment) => {
							const shipmentFiles = action.payload.shipmentFiles.find((file) => file.shipmentId === shipment.id)
							if (shipmentFiles) {
								return {
									...shipment,
									...shipmentFiles,
								}
							}
							return shipment
						}),
					}
				}),
			}
		case MANAGE_SCANNED_QUANTITY_ON_DELIVERY:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							orderItems: order.orderItems.map((item) => {
								return {
									...item,
									shippedQuantity: item.shippedQuantity + item.scannedQuantity,
									scannedQuantity: 0,
								}
							}),
						}
					}
					return order
				}),
			}
		case GET_VOUCHER_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_VOUCHER_LIST_SUCCESS:
			return {
				...state,
				voucherList: action.payload.voucherList.vouchers,
				isLoading: false,
				error: null,
			}
		case GET_VOUCHER_LIST_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}

		case CREATE_VOUCHER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case CREATE_VOUCHER_SUCCESS:
			return {
				...state,
				voucher: action.payload.voucher,
				isLoading: false,
				error: null,
			}
		case CREATE_VOUCHER_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}

		case UPDATE_VOUCHER_BY_C0DE:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case UPDATE_VOUCHER_BY_C0DE_SUCCESS:
			return {
				...state,
				voucher: action.payload.voucher,
				isLoading: false,
				error: null,
			}
		case UPDATE_VOUCHER_BY_C0DE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case RESET_ORDER_DETAIL:
			return {
				...state,
				orderDetail: {},
			}
		case GET_DOMAIN_ORDERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_DOMAIN_ORDERS_SUCCESS:
			return {
				...state,
				domainOrders: action.payload.response.orders,
				isLoading: false,
				error: null,
			}
		case GET_DOMAIN_ORDERS_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload.error.message,
			}

		case GET_BOX_LIST:
			return {
				...state,
				getBoxListStatus: {
					isLoading: true,
					success: null,
					error: null,
				},
			}
		case GET_BOX_LIST_SUCCESS:
			return {
				...state,
				boxList: action.payload.boxList,
				getBoxListStatus: {
					isLoading: false,
					success: true,
					error: null,
				},
			}
		case GET_BOX_LIST_FAIL:
			return {
				...state,
				getBoxListStatus: {
					isLoading: false,
					success: null,
					error: action.payload?.error?.message || 'error',
				},
			}

		case UPDATE_BOXES_BY_REF:
			return {
				...state,
				boxList: state.boxList.map((box) => {
					const boxRef = action.payload.data.boxes.find((ref) => ref.ref === box.ref)
					if (boxRef != null) {
						return {
							...box,
							orderId: boxRef.orderId,
						}
					}
					return box
				}),
				updateBoxesByRefStatus: {
					isLoading: true,
					success: null,
					error: null,
				},
			}
		case UPDATE_BOXES_BY_REF_SUCCESS:
			return {
				...state,
				updateBoxesByRefStatus: {
					isLoading: false,
					success: true,
					error: null,
				},
			}
		case UPDATE_BOXES_BY_REF_FAIL:
			return {
				...state,
				updateBoxesByRefStatus: {
					isLoading: false,
					success: null,
					error: action.payload?.error?.message || 'error',
				},
			}

		case GET_BILLING_LIST:
			return {
				...state,
				isLoadingBillingList: true,
				error: null,
			}
		case GET_BILLING_LIST_SUCCESS:
			return {
				...state,
				billingList: action.payload.billingList,
				count: action.payload.count,
				error: null,
				isLoadingBillingList: false,
			}
		case GET_BILLING_LIST_FAIL:
			return {
				...state,
				billingList: [],
				error: action.payload,
				isLoadingBillingList: false,
			}
		case GET_EXPORTED_PART_BY_DATE_LIST:
			return {
				...state,
				exportedPartListReport: {
					...state.exportedPartListReport,
					isLoading: action.payload.pagination != null,
					isLoadingNoPaginated: action.payload.pagination == null,
					success: null,
					error: null,
					noPaginatedParts: [],
				},
			}
		case GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS:
			return {
				...state,
				exportedPartListReport: {
					isLoading: false,
					success: true,
					error: null,
					parts: action.payload.exportedParts,
					totalRows: action.payload.totalRows,
				},
			}
		case GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS:
			return {
				...state,
				exportedPartListReport: {
					...state.exportedPartListReport,
					isLoading: false,
					error: null,
					noPaginatedParts: action.payload.exportedParts,
					isLoadingNoPaginated: false,
				},
			}
		case GET_EXPORTED_PART_BY_DATE_LIST_FAIL:
			return {
				...state,
				exportedPartListReport: {
					isLoading: false,
					success: null,
					error: action.payload?.error?.message || 'error',
				},
			}
		case GET_FILTERED_ORDERS:
			return {
				...state,
				isLoadingOrderList: true,
				orderList: [],
				error: null,
			}
		case GET_FILTERED_ORDERS_SUCCESS:
			return {
				...state,
				orderList: action.payload.items,
				orderListCount: action.payload.totalCount,
				error: null,
				isLoadingOrderList: false,
			}
		case GET_FILTERED_ORDERS_FAIL:
			return {
				...state,
				orderList: [],
				orderListCount: 0,
				error: action.payload.error.message,
				isLoadingOrderList: action.payload.error.code === CANCELED_CODE,
			}
		case GET_OPEPENED_ORDERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OPEPENED_ORDERS_SUCCESS:
			return {
				...state,
				openedOrders: action.payload.data.openedOrders,
				error: null,
				isLoading: false,
			}
		case GET_OPEPENED_ORDERS_FAIL:
			return {
				...state,
				openedOrders: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_OPENED_VERIFICATION_ORDERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OPENED_VERIFICATION_ORDERS_SUCCESS:
			return {
				...state,
				openedVerificationOrders: action.payload.data.openedOrders,
				error: null,
				isLoading: false,
			}
		case GET_OPENED_VERIFICATION_ORDERS_FAIL:
			return {
				...state,
				openedVerificationOrders: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_OPENED_EXPEDITION_ORDERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OPENED_EXPEDITION_ORDERS_SUCCESS:
			return {
				...state,
				openedExpeditonOrders: action.payload.data.openedOrders,
				error: null,
				isLoading: false,
			}
		case GET_OPENED_EXPEDITION_ORDERS_FAIL:
			return {
				...state,
				openedExpeditonOrders: [],
				error: action.payload,
				isLoading: false,
			}
		case SET_EXPEDITION_COMMENT_READED:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							expeditionInfo: {
								...order.expeditionInfo,
								readCommentsExpedition: true,
							},
						}
					}
					return order
				}),
			}
		case SET_EXPEDITION_BOX_LABEL_GENERATED:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							expeditionInfo: {
								...order.expeditionInfo,
								printedLabels: true,
							},
						}
					}
					return order
				}),
			}
		case SET_EXPEDITION_DELIVERY_NOTE_PRINTED:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							expeditionInfo: {
								...order.expeditionInfo,
								printedDeliveryNote: true,
							},
						}
					}
					return order
				}),
			}
		case CREATE_SHIPMENT_EXPORT:
			return {
				...state,
				isLoading: true,
				error: null,
				shipmentExport: null,
			}
		case CREATE_SHIPMENT_EXPORT_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				shipmentExport: action.payload.shipment,
			}
		case CREATE_SHIPMENT_EXPORT_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				shipmentExport: null,
			}

		case UPDATE_ORDER_ITEM:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case UPDATE_ORDER_ITEM_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
			}
		case UPDATE_ORDER_ITEM_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case GET_LAST_THREE_DAYS_SHIPMENTS:
			return {
				...state,
				error: null,
			}
		case GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS:
			return {
				...state,
				lastThreeDaysShipments: action.payload.data.shipments,
				error: null,
				isLoading: false,
			}
		case GET_LAST_THREE_DAYS_SHIPMENTS_FAIL:
			return {
				...state,
				lastThreeDaysShipments: [],
				error: action.payload,
				isLoading: false,
			}
		case RESET_EXPEDITION_ORDER_BOXCODE:
			return {
				...state,
				expeditionOrderList: state.expeditionOrderList.map((order) => {
					if (order.id === action.payload.orderId) {
						return {
							...order,
							boxCode: null,
						}
					}
					return order
				}),
			}

		case ADD_SCANNED_VERIFICATION_ORDER:
			return {
				...state,
				scannedVerificationOrderList: [...state.scannedVerificationOrderList, action.payload.order],
			}

		case GET_QUALITY_QUANTITIES:
			return {
				...state,
				isLoadingQuality: true,
				error: null,
			}
		case GET_QUALITY_QUANTITIES_SUCCESS:
			return {
				...state,
				qualityQuantities: action.payload.qualityQuantities,
				error: null,
				isLoadingQuality: false,
			}
		case GET_QUALITY_QUANTITIES_FAIL:
			return {
				...state,
				qualityQuantities: [],
				error: action.payload,
				isLoadingQuality: false,
			}

		case GET_QUALITY_PROBLEMS:
			return {
				...state,
				isLoadingQuality: true,
				error: null,
			}
		case GET_QUALITY_PROBLEMS_SUCCESS:
			return {
				...state,
				qualityProblems: action.payload.qualityProblems.qualityProblems,
				error: null,
				isLoadingQuality: false,
			}
		case GET_QUALITY_PROBLEMS_FAIL:
			return {
				...state,
				qualityProblems: [],
				error: action.payload.error.message,
				isLoadingQuality: false,
			}

		case GET_QUALITY_SOLUTIONS:
			return {
				...state,
				isLoadingQuality: true,
				error: null,
			}
		case GET_QUALITY_SOLUTIONS_SUCCESS:
			return {
				...state,
				qualitySolutions: action.payload.qualitySolutions.qualitySolutions,
				error: null,
				isLoadingQuality: false,
			}
		case GET_QUALITY_SOLUTIONS_FAIL:
			return {
				...state,
				qualitySolutions: [],
				error: action.payload.error.message,
				isLoadingQuality: false,
			}

		case CREATE_PART_VERIFICATION:
			return {
				...state,
				isLoadingQuality: true,
				error: null,
				isQualityIncidenceCreatedSuccessfully: null,

			}
		case CREATE_PART_VERIFICATION_SUCCESS:
			return {
				...state,
				qualityPartState: action.payload.qualityPartState,
				qualityVerification: action.payload.qualityVerification || null,
				scannedVerificationOrderList: state.scannedVerificationOrderList.map((scannedOrder) => {
					if (scannedOrder.order.id != action.payload.qualityPartState.orderId) {
						return scannedOrder
					}
					const isNewVerification = scannedOrder.qualityPartStates.find((qualityPartState) => qualityPartState.partId == action.payload.qualityPartState.partId) == null
					return {
						...scannedOrder,
						qualityPartStates: isNewVerification ? [...scannedOrder.qualityPartStates, action.payload.qualityPartState] : scannedOrder.qualityPartStates.map((qualityPartState) => {
							if (qualityPartState.partId != action.payload.qualityPartState.partId) {
								return qualityPartState
							}
							return action.payload.qualityPartState
						}),
						qualityVerifications: action.payload.qualityVerification != null ? [
							...scannedOrder.qualityVerifications,
							action.payload.qualityVerification,
						] : [...scannedOrder.qualityVerifications],
					}
				}),
				isQualityIncidenceCreatedSuccessfully: true,
				error: null,
				isLoadingQuality: false,
			}
		case CREATE_PART_VERIFICATION_FAIL:
			return {
				...state,
				qualityPartState: null,
				qualityVerification: null,
				error: action.payload,
				isLoadingQuality: false,
				isQualityIncidenceCreatedSuccessfully: false,

			}

		case CREATE_NEW_UPS_EXPEDITION:
			return {
				...state,
				isLoadingUPS: true,
				error: null,
				isShipmentCreated: null,
			}
		case CREATE_NEW_UPS_EXPEDITION_SUCCESS:
			return {
				...state,
				upsExpeditionInfo: action.payload.response,
				isLoadingUPS: false,
				isShipmentCreated: true,
				error: null,
			}
		case CREATE_NEW_UPS_EXPEDITION_FAIL:
			return {
				...state,
				isLoadingUPS: false,
				error: action.payload,
				upsExpeditionInfo: action.payload,
				isShipmentCreated: false,
			}
		case GET_EXPEDITION_NACEX_LABELS:
			return {
				...state,
				error: null,
			}
		case GET_EXPEDITION_NACEX_LABELS_SUCCESS:
			return {
				...state,
				generatedLabel: action.payload.response,
				error: null,
			}

		case GET_EXPEDITION_NACEX_LABELS_FAIL:
			return {
				...state,
				generatedLabel: null,
				error: action.payload,
			}
		case GET_EXPEDITION_UPS_LABELS:
			return {
				...state,
				error: null,
			}
		case GET_EXPEDITION_UPS_LABELS_SUCCESS:
			return {
				...state,
				generatedLabel: action.payload,
				error: null,
			}
		case GET_EXPEDITION_UPS_LABELS_FAIL:
			return {
				...state,
				generatedLabel: null,
				error: action.payload,
			}
		case SAVE_GENERATED_PDF:
			return {
				...state,
				generatedPdf: action.payload,
			}

		case ADD_QUALITY_VERIFICATION_IMAGES:
			return {
				...state,
				scannedVerificationOrderList: state.scannedVerificationOrderList.map((scannedOrder) => {
					if (scannedOrder.order.id != action.payload.orderId) {
						return scannedOrder
					}
					return {
						...scannedOrder,
						qualityVerifications: scannedOrder.qualityVerifications.map((qualityVerification) => {
							if (qualityVerification.id != action.payload.qualityVerificationId) {
								return qualityVerification
							}
							return {
								...qualityVerification,
								images: action.payload.images.map((imageUrl) => {
									return {
										src: imageUrl,
									}
								}),
							}
						}),
					}
				}),
			}

		case ADD_INTERNAL_COMMENT:
			return {
				...state,
				scannedVerificationOrderList: state.scannedVerificationOrderList.map((scannedOrder) => {
					if (scannedOrder.order.id != action.payload.comment.orderId) {
						return scannedOrder
					}
					return {
						...scannedOrder,
						order: {
							...scannedOrder.order,
							internalComment: [...scannedOrder.order.internalComment, action.payload.comment],
						},
					}
				}),
			}

		case CREATE_REMIT:
			return {
				...state,
				isLoading: true,
				error: null,
				remit: null,
			}
		case CREATE_REMIT_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				remit: action.payload.remit,
			}
		case CREATE_REMIT_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				remit: null,
			}

		case CREATE_INVOICE:
			return {
				...state,
				isLoading: true,
				error: null,
				invoice: null,
			}
		case CREATE_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				invoice: action.payload.invoice,
			}
		case CREATE_INVOICE_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				invoice: null,
			}
		case GET_INCIDENCES_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_INCIDENCES_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				incidences: action.payload.incidences,
				error: null,
			}
		case GET_INCIDENCES_LIST_FAIL:
			return {
				...state,
				isLoading: action.payload.error.code === CANCELED_CODE,
				error: action.payload.error.code !== CANCELED_CODE ? action.payload.error.message : null,
			}
		case GET_PENDING_INVOICE_ORDERS:
			return {
				...state,
				isLoading: true,
				error: null,
				pendingOrders: null,
			}
		case GET_PENDING_INVOICE_ORDERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				pendingOrders: action.payload.orders,
			}
		case GET_PENDING_INVOICE_ORDERS_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				pendingOrders: null,
			}

		case HANDLE_SENT_ORDER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case HANDLE_SENT_ORDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
			}
		case HANDLE_SENT_ORDER_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}

		case SCAN_ORDER_EXPEDITION_PART:
			return {
				...state,
				scanOrderExpeditionPart_Status: {
					loading: true,
					success: null,
					error: null,
				},
			}
		case SCAN_ORDER_EXPEDITION_PART_SUCCESS:
			return {
				...state,
				scanOrderExpeditionPart_Status: {
					loading: false,
					success: true,
					error: false,
				},
			}
		case SCAN_ORDER_EXPEDITION_PART_FAIL:
			return {
				...state,
				scanOrderExpeditionPart_Status: {
					loading: false,
					success: false,
					error: action.payload,
				},
			}

		default:
			return state

	}
}

export default Orders
