import { useRef, useState } from 'react'

export const useHandleCancelableApi = (apiMethod, apiBody, waitingTime, firstTimeNoWaitingTime) => {
	const controllerRef = useRef(new AbortController())
	const timeoutIdRef = useRef(null)

	const [isFirstApiCall, setIsFirstApiCall] = useState(true)
	const handleApiCall = () => {
		if (isFirstApiCall && firstTimeNoWaitingTime) {
			apiCall()
			setIsFirstApiCall(false)
			return
		}
		timeoutIdRef.current && clearTimeout(timeoutIdRef.current)
		timeoutIdRef.current = setTimeout(() => {
			apiCall()
		}, waitingTime)
	}

	const apiCall = () => {
		controllerRef.current.abort()
		controllerRef.current = new AbortController()
		apiMethod(apiBody, controllerRef.current.signal)
	}

	const cancelApiCall = () => {
		timeoutIdRef.current && clearTimeout(timeoutIdRef.current)
		controllerRef.current.abort()
	}
	return { handleApiCall, cancelApiCall }
}
