const { useRef } = require('react')

/**
 * - props.buttonContent string
 * - props.onUpload (file) => void
 */
export const FileUploadButton = (props) => {
	const fileInputRef = useRef(null)
	return (
		<>
			<button
				onClick={() => fileInputRef.current.click()}
				{...props.additionalButtonProps}
			/>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				{...props.additionalInputProps}
			/>
		</>
	)
}
