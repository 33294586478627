import Modal from 'components/Common/Modal/Modal'
import { ResponsiveGeometryViewer } from 'components/Common/ResponsiveGeometryViewer'
import PropTypes from 'prop-types'

// TODO rename file to GeometryViewerModal
const GeometryViewerModal = ({
	isGeometryViewerModalOpen,
	closeGeometryViewerModal,
	geometryViewerData,
	hideData,
	extension,
}) => {
	return <Modal
		isOpen={isGeometryViewerModalOpen}
		closeModal={closeGeometryViewerModal}
		title={geometryViewerData.partName && geometryViewerData.partName}
		className={'ResponsiveGeometryViewer'}
		body={
			<ResponsiveGeometryViewer
				modelSrc={geometryViewerData.modelSrc && geometryViewerData.modelSrc}
				partId={geometryViewerData.partId && geometryViewerData.partId}
				size={geometryViewerData.size && geometryViewerData.size}
				weight={geometryViewerData.weight && geometryViewerData.weight}
				hideData={hideData}
				extension={extension}
			/>
		}
	/>
}

GeometryViewerModal.propTypes = {
	isGeometryViewerModalOpen: PropTypes.bool,
	closeGeometryViewerModal: PropTypes.func,
	geometryViewerData: PropTypes.object,
}

export default GeometryViewerModal
