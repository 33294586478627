import { PropTypes } from 'prop-types'
import React, { Component } from 'react'

import FileIcon from './FileIcon.js'

class OBJFileIcon extends Component {
	render() {
		return (
			<React.Fragment>
				<FileIcon
					src={this.props.src}
					fileExtension="OBJ"
					fileBackgroundColor="#7146CD"
					tooltipMessage={this.props.tooltipMessage}
				/>
			</React.Fragment>
		)
	}
}
OBJFileIcon.propTypes = {
	tooltipMessage: PropTypes.string,
	src: PropTypes.string,
}
export default OBJFileIcon
