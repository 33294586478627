
import { useObjectState } from 'common/Hooks/UseObjectState'
import { getDateWithHour } from 'common/Utils/StringUtilities'
import FileInput from 'components/Common/FileInput'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SimpleTable from 'components/Common/SimpleTable'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getResolutionsImagesByIncidenceId, updateQualityVerification, uploadQualityVerificationImages, uploadResolutionImage } from 'store/actions'

const ResolutionIncidenceModal = ({
	t, isOpen, onClose, incidence, partId, getResolutionsImagesByIncidenceId, resolutionsImages,
	uploadResolutionImage, updateQualityVerification, problems, solutions, qualityVerficationImages, quantity, uploadQualityVerificationImages
}) => {

	const INIT_STATE = {
		resolutionMessage: '',
		uploadImages: [],
		images: [],
		resolutionDate: '',
		partsSolved: 0,
		observation: null,
		problem: null,
		solution: null,
		partsWrong: 0,
		partsOK: 0,
		notifiedBy: 'quality',
		creationDate: '',
	}

	const { handleOnChange, objectData, setObjectData } = useObjectState(INIT_STATE)
	const [imageFiles, setImageFiles] = useState()
	const [imageFilesVerification, setImageFilesVerification] = useState()
	const [isSolved, setIsSolved] = useState(false)
	const [partQuantityList, setPartQuantityList] = useState([])
	const parseOptions = options =>
		options.map(option => {
			return {
				value: option.id,
				label: t(option.name, { ns: 'quality' }),
			}
		})
	const problemsOptions = parseOptions(problems)
	const solutionsOptions = parseOptions(solutions)
	const notifiedOptions = [
		{
			value: 'quality',
			label: t('quality', { ns: 'naming' }),
		},
		{
			value: 'client',
			label: t('client', { ns: 'naming' }),
		},
	]

	useEffect(() => {
		if (incidence) {
			const maxQuantity = incidence.partsOK + incidence.partsWrong
			const calculatedQuantityList = calculatePartQuantityList(maxQuantity)
			setPartQuantityList(calculatedQuantityList)
			getResolutionsImagesByIncidenceId({
				data: {
					incidenceId: incidence.id,
					partId: partId,
				}
			})
			setObjectData({
				resolutionMessage: incidence.resolutionMessage || '',
				resolutionDate: getDateWithHour(incidence.resolutionDate) || '',
				partsSolved: incidence.partsSolved || 0,
				problem: incidence.problem || null,
				solution: incidence.solution || null,
				observation: incidence.observation || null,
				partsOK: incidence.partsOK || 0,
				partsWrong: incidence.partsWrong || 0,
				notifiedBy: incidence.notifiedBy || '',
				creationDate: getDateWithHour(incidence.creationDate) || '',

			})

			/* getOrderDetailById(incidence.orderId) */

		}
	}, [incidence, partId, getResolutionsImagesByIncidenceId])



	const handleSave = () => {
		if (incidence) {
			const incidenceToUpdate = {
				data: {
					qualityVerification: {
						id: incidence.id,
						resolutionMessage: objectData.resolutionMessage,
						solved: isSolved,
						partsSolved: objectData.partsSolved,
						resolutionDate: new Date(),
						problem: objectData.problem,
						solution: objectData.solution,
						partsOK: objectData.partsOK,
						partsWrong: objectData.partsWrong,
						observation: objectData.observation,
						notifiedBy: objectData.notifiedBy,
						creationDate: new Date(),
					},
				},
			}
			if (imageFiles != null) {
				const resolutionImageToUpload = {
					data: {
						incidenceId: incidence.id,
						partId: partId,
					},
					resolutionsImages: imageFiles,
				}
				uploadResolutionImage(resolutionImageToUpload)
			}
			if (imageFilesVerification != null) {
				const verificationImageToUpload = {
					data: {
						qualityVerificationId: incidence.id,
						partId: partId,
					},
					images: imageFilesVerification,
				}
				uploadQualityVerificationImages(verificationImageToUpload)
			}
			updateQualityVerification(incidenceToUpdate)
		}
	}

	useEffect(() => {
		if (objectData.partsSolved >= objectData?.partsWrong) {
			setIsSolved(true)
		}
		else {
			setIsSolved(false)
		}
	}, [objectData, incidence])

	useEffect(() => {
	}, [quantity])

	const calculatePartQuantityList = (maxQuantity) => {
		return [0, ...Array(maxQuantity).fill().map((element, index) => index + 1)]
	}

	return (
		<Modal
			className="modal-lg"
			isOpen={isOpen}
			closeModal={onClose}
			buttons={[<button
				className={'btn ms-2 mt-2 btn-primary'}
				onClick={handleSave}
				key="save_incidence"
			>
				{t(('save'), { ns: 'naming' })}
			</button>]}
			title={`${t('resolution-of-the-part', { ns: 'naming' })} ${partId}`}
			body={
				<div className="px-4 py-2 d-flex flex-column">
					<h3>{`${t('incidence-of-the-part', { ns: 'naming' })} ${partId}`}</h3>
					{incidence && (
						<div className="d-flex flex-column">
							<div>
								<TextInput
									className='w-50'
									title={t('creation-date', { ns: 'naming' })}
									value={objectData.creationDate}
									disabled
								/>
							</div>
							<div className="d-flex flex-row">

								<OptionsInput
									className="w-50"
									value={objectData.problem}
									objectElement="problem"
									setValue={handleOnChange}
									options={problemsOptions}
									automaticMappedValue
									title={t('incidence', { ns: 'naming' })}
								/>
								<OptionsInput
									className="w-50"
									value={objectData.solution}
									objectElement="solution"
									setValue={handleOnChange}
									options={solutionsOptions}
									automaticMappedValue
									title={t('solution', { ns: 'naming' })}
								/>
							</div>
							<div className="d-flex flex-row">
								<OptionsInput
									className="w-50"
									value={objectData.partsOK}
									objectElement="partsOK"
									setValue={handleOnChange}
									automaticMappedValue
									title={t('parts-ok', { ns: 'naming' })}
									type="number"
									onBlur={value => {
										if (value == '') handleOnChange('partsOK', 0)
									}}
									options={partQuantityList}
								/>
								<OptionsInput
									className="w-50"
									value={objectData.partsWrong}
									objectElement="partsWrong"
									setValue={handleOnChange}
									automaticMappedValue
									title={t('parts-wrong', { ns: 'naming' })}
									type="number"
									onBlur={value => {
										if (value == '') handleOnChange('partsWrong', 0)
									}}
									options={partQuantityList}
								/>
							</div>
							<TextInput
								title={t('observation', { ns: 'naming' })}
								value={objectData.observation}
								setValue={handleOnChange}
								objectElement='observation'
								enabled
							/>
							<div className="d-flex flex-row">
								<FileInput
									className="w-50"
									value={objectData.images}
									objectElement="images"
									setValue={handleOnChange}
									setFiles={setImageFilesVerification}
									title={t('images', { ns: 'naming' })}
									multiple
									accept="image/*"
								/>
								<OptionsInput
									className="w-50"
									value={objectData.notifiedBy}
									objectElement="notifiedBy"
									setValue={handleOnChange}
									options={notifiedOptions}
									automaticMappedValue
									title={t('notified-by', { ns: 'naming' })}
								/>
							</div>

							{qualityVerficationImages && qualityVerficationImages.length > 0 && (
								<SimpleTable
									header={['images']}
									getTableBody={() => (
										<tr key={`images_${incidence.id}`}>
											<td>{qualityVerficationImages.map((image, i) => (
												<a key={`quality-verification-image-${i}`} href={image.src} target="_blank" rel="noopener noreferrer">
													<img className="verificationImage" src={image.src} alt={`Quality Verification ${i}`} />
												</a>
											))}</td>
										</tr>
									)}
								/>
							)}
						</div>
					)}
					<div className="mt-3">
						<h3>{t('resolution', { ns: 'naming' })}</h3>
						{objectData.resolutionDate && (
							<TextInput
								className='w-50'
								title={t('resolution-date', { ns: 'naming' })}
								value={objectData.resolutionDate}
								disabled
							/>
						)}
						<TextInput
							title={t('Indica cómo se ha resuelto la incidencia', { ns: 'naming' })}
							value={objectData.resolutionMessage}
							setValue={handleOnChange}
							objectElement='resolutionMessage'
							enabled
						/>
						<FileInput
							className="w-50"
							value={objectData.uploadImages}
							objectElement="uploadImages"
							setValue={handleOnChange}
							setFiles={setImageFiles}
							title={t('images', { ns: 'naming' })}
							multiple
							accept="image/*"
						/>
						{resolutionsImages && resolutionsImages.length > 0 && (
							resolutionsImages.map((image, i) => (
								<a key={`resolution-image-${i}`} href={image.src} target="_blank" rel="noopener noreferrer">
									<img className="verificationImage" src={image.src} alt={`Resolution ${i}`} />
								</a>
							))
						)}

						<div className='mt-4'>
							<OptionsInput
								className="w-50"
								value={objectData.partsSolved}
								objectElement="partsSolved"
								setValue={handleOnChange}
								automaticMappedValue
								title={t('partsSolved', { ns: 'naming' })}
								type="number"
								onBlur={value => {
									if (value == '') handleOnChange('partsSolved', 0)
								}}
								options={partQuantityList}
							/>
						</div>
					</div>
				</div>
			}
		/>
	)
}
const mapStateToProps = state => ({
	resolutionsImages: state.CloudStorage.resolutionsImages,
	qualityVerficationImages: state.CloudStorage.qualityVerficationImages,

})

ResolutionIncidenceModal.propTypes = {
	t: PropTypes.func.isRequired,

	orderInfo: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	incidence: PropTypes.object,
	partId: PropTypes.number,
	getResolutionsImagesByIncidenceId: PropTypes.func.isRequired,
	uploadQualityVerificationImages: PropTypes.func,
	uploadResolutionImage: PropTypes.func.isRequired,
	updateQualityVerification: PropTypes.func.isRequired,
	qualityVerficationImages: PropTypes.array,
	resolutionsImages: PropTypes.array,
	problems: PropTypes.array, // Añadido
	solutions: PropTypes.array,

	quantity: PropTypes.number,
}

const mapDispatchToProps = {
	getResolutionsImagesByIncidenceId,
	uploadResolutionImage,
	updateQualityVerification,
	uploadQualityVerificationImages
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResolutionIncidenceModal))
