/**
 * Calculate boundary box volume.
 *
 * @param {{
*   x?: number,
*   y?: number,
*   z?: number
* }} size
* @returns {number}
*/
export const calculateBoundaryBoxVolume = (size) => {

	// Contemplate undefined values:

	const { x, y, z } = size || {}

	if (x == null || y == null || z == null) return 0

	// Calculate:

	return x * y * z
}
