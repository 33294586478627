import {
	DELETE_COMPLETE_PRODUCTION_FILE,
	DELETE_COMPLETE_PRODUCTION_FILE_FAIL,
	DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS,
	DELETE_MODIFIED_BLUEPRINTS,
	DELETE_MODIFIED_BLUEPRINTS_FAIL,
	DELETE_MODIFIED_BLUEPRINTS_SUCCESS,
	DELETE_OLD_PART_FILES,
	DELETE_OLD_PART_FILES_FAIL,
	DELETE_OLD_PART_FILES_SUCCESS,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS,
	GET_ALL_ORDER_SHIPMENT_FILES,
	GET_ALL_ORDER_SHIPMENT_FILES_FAIL,
	GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS,
	GET_COMPLETE_PRODUCTION_FILES,
	GET_COMPLETE_PRODUCTION_FILES_FAIL,
	GET_COMPLETE_PRODUCTION_FILES_SUCCESS,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_FAIL,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_SUCCESS,
	GET_PARTS_FILES,
	GET_PARTS_FILES_FAIL,
	GET_PARTS_FILES_SUCCESS,
	GET_PART_LIST_BLUEPRINTS_AND_STP,
	GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL,
	GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS,
	GET_PART_LIST_IMAGES,
	GET_PART_LIST_IMAGES_FAIL,
	GET_PART_LIST_IMAGES_SUCCESS,
	GET_PRODUCTION_CHAT_IMAGES,
	GET_PRODUCTION_CHAT_IMAGES_FAIL,
	GET_PRODUCTION_CHAT_IMAGES_SUCCESS,
	GET_QUALITY_VERIFICATION_IMAGES,
	GET_QUALITY_VERIFICATION_IMAGES_FAIL,
	GET_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	GET_QUOTE_CHAT_IMAGES,
	GET_QUOTE_CHAT_IMAGES_FAIL,
	GET_QUOTE_CHAT_IMAGES_SUCCESS,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS,
	UPLOAD_COMPLETE_PRODUCTION_FILES,
	UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL,
	UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS,
	UPLOAD_MANAGER_TASK_IMAGE,
	UPLOAD_MANAGER_TASK_IMAGE_FAIL,
	UPLOAD_MANAGER_TASK_IMAGE_SUCCESS,
	UPLOAD_ORDER_DELIVERY_NOTE,
	UPLOAD_ORDER_DELIVERY_NOTE_FAIL,
	UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS,
	UPLOAD_ORDER_SHIPMENT_FILES,
	UPLOAD_ORDER_SHIPMENT_FILES_FAIL,
	UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS,
	UPLOAD_PART_FILES,
	UPLOAD_PART_FILES_FAIL,
	UPLOAD_PART_FILES_SUCCESS,
	UPLOAD_PART_IMAGE,
	UPLOAD_PART_IMAGE_FAIL,
	UPLOAD_PART_IMAGE_SUCCESS,
	UPLOAD_PRODUCTION_CHAT_IMAGE,
	UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL,
	UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS,
	UPLOAD_PROFILE_IMAGE,
	UPLOAD_PROFILE_IMAGE_FAIL,
	UPLOAD_PROFILE_IMAGE_SUCCESS,
	UPLOAD_QUALITY_VERIFICATION_IMAGES,
	UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL,
	UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	UPLOAD_QUOTE_CHAT_IMAGE,
	UPLOAD_QUOTE_CHAT_IMAGE_FAIL,
	UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS,
	UPLOAD_RESOLUTION_IMAGE,
	UPLOAD_RESOLUTION_IMAGE_FAIL,
	UPLOAD_RESOLUTION_IMAGE_SUCCESS,
	UPLOAD_TO_CAD,
	UPLOAD_TO_CAD_FAIL,
	UPLOAD_TO_CAD_SUCCESS,
} from './actionTypes'


export const uploadManagerTaskImage = ({ data, taskImages }) => ({
	type: UPLOAD_MANAGER_TASK_IMAGE,
	payload: { data, taskImages },
})

export const uploadManagerTaskImageSuccess = (response) => ({
	type: UPLOAD_MANAGER_TASK_IMAGE_SUCCESS,
	payload: { response },
})

export const uploadManagerTaskImageFail = (error) => ({
	type: UPLOAD_MANAGER_TASK_IMAGE_FAIL,
	payload: { error },
})

export const getManagerTaskImagesByNotificationId = (data) => ({
	type: GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID,
	payload: { data },
})

export const getManagerTaskImagesByNotificationIdSuccess = (response) => ({
	type: GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_SUCCESS,
	payload: { response },
})

export const getManagerTaskImagesByNotificationIdFail = (error) => ({
	type: GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_FAIL,
	payload: { error },
})

export const uploadResolutionImage = ({ data, resolutionsImages }) => ({
	type: UPLOAD_RESOLUTION_IMAGE,
	payload: { data, resolutionsImages },
})

export const uploadResolutionImageSuccess = (response) => ({
	type: UPLOAD_RESOLUTION_IMAGE_SUCCESS,
	payload: { response },
})

export const uploadResolutionImageFail = (error) => ({
	type: UPLOAD_RESOLUTION_IMAGE_FAIL,
	payload: { error },
})


export const getResolutionsImagesByIncidenceId = (data) => ({
	type: GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID,
	payload: { data },
})

export const getResolutionsImagesByIncidenceIdSuccess = (response) => ({
	type: GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS,
	payload: { response },
})

export const getResolutionsImagesByIncidenceIdFail = (error) => ({
	type: GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL,
	payload: { error }
})

export const uploadProfileImage = (accountId, accountImage) => ({
	type: UPLOAD_PROFILE_IMAGE,
	payload: { accountId, accountImage },
})

export const uploadProfileImageSuccess = (response) => ({
	type: UPLOAD_PROFILE_IMAGE_SUCCESS,
	payload: { response },
})

export const uploadProfileImageFail = (error) => ({
	type: UPLOAD_PROFILE_IMAGE_FAIL,
	payload: { error },
})

export const getSupplierQuotationFilesByOfferId = (offerId, supplierId) => ({
	type: GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID,
	payload: { offerId, supplierId },
})
export const getSupplierQuotationFilesByOfferIdSuccess = (response) => ({
	type: GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS,
	payload: { response },
})
export const getSupplierQuotationFilesByOfferIdFail = (error) => ({
	type: GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL,
	payload: { error },
})

export const getPartListImages = (partListId) => ({
	type: GET_PART_LIST_IMAGES,
	payload: { partListId },
})

export const getPartListImagesSuccess = (response) => ({
	type: GET_PART_LIST_IMAGES_SUCCESS,
	payload: { response },
})
export const getPartListImagesFail = (error) => ({
	type: GET_PART_LIST_IMAGES_FAIL,
	payload: { error },
})
export const uploadPartImage = (partListId, file) => ({
	type: UPLOAD_PART_IMAGE,
	payload: { partListId, file },
})
export const uploadPartImageSuccess = (response) => ({
	type: UPLOAD_PART_IMAGE_SUCCESS,
	payload: { response },
})
export const uploadPartImageFail = (error) => ({
	type: UPLOAD_PART_IMAGE_FAIL,
	payload: { error },
})

// ------------- uploadPartFiles
export const uploadPartFiles = ({ body }) => ({
	type: UPLOAD_PART_FILES,
	payload: { body },
})
export const uploadPartFilesSuccess = ({ response }) => ({
	type: UPLOAD_PART_FILES_SUCCESS,
	payload: { response },
})
export const uploadPartFilesFail = ({ error }) => ({
	type: UPLOAD_PART_FILES_FAIL,
	payload: { error },
})

// ------------- uploadToCad
export const uploadToCad = ({ body }) => ({
	type: UPLOAD_TO_CAD,
	payload: { body },
})
export const uploadToCadSuccess = ({ response }) => ({
	type: UPLOAD_TO_CAD_SUCCESS,
	payload: { response },
})
export const uploadToCadFail = ({ error }) => ({
	type: UPLOAD_TO_CAD_FAIL,
	payload: { error },
})

// ------------- deleteOldPartFiles
export const deleteOldPartFiles = ({ body }) => ({
	type: DELETE_OLD_PART_FILES,
	payload: { body },
})
export const deleteOldPartFilesSuccess = ({ response }) => ({
	type: DELETE_OLD_PART_FILES_SUCCESS,
	payload: { response },
})
export const deleteOldPartFilesFail = ({ error }) => ({
	type: DELETE_OLD_PART_FILES_FAIL,
	payload: { error },
})

export const uploadQuoteChatImage = (accountId, partId, supplierId, images) => ({
	type: UPLOAD_QUOTE_CHAT_IMAGE,
	payload: { accountId, partId, supplierId, images },
})
export const uploadQuoteChatImageSuccess = (response) => ({
	type: UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS,
	payload: { response },
})
export const uploadQuoteChatImageFail = (error) => ({
	type: UPLOAD_QUOTE_CHAT_IMAGE_FAIL,
	payload: { error },
})
export const getQuoteChatImages = (partId, supplierId) => ({
	type: GET_QUOTE_CHAT_IMAGES,
	payload: { partId, supplierId },
})
export const getQuoteChatImagesSuccess = (response) => ({
	type: GET_QUOTE_CHAT_IMAGES_SUCCESS,
	payload: { response },
})
export const getQuoteChatImagesFail = (error) => ({
	type: GET_QUOTE_CHAT_IMAGES_FAIL,
	payload: { error },
})

export const getPartListBlueprintsAndStp = (partListId) => ({
	type: GET_PART_LIST_BLUEPRINTS_AND_STP,
	payload: { partListId },
})

export const getPartListBlueprintsAndStpSuccess = (response) => ({
	type: GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS,
	payload: { response },
})
export const getPartListBlueprintsAndStpFail = (error) => ({
	type: GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL,
	payload: { error },
})

export const getPartsFiles = (partListId) => ({
	type: GET_PARTS_FILES,
	payload: { partListId },
})
export const getPartsFilesSuccess = (response) => ({
	type: GET_PARTS_FILES_SUCCESS,
	payload: { response },
})
export const getPartsFilesFail = (error) => ({
	type: GET_PARTS_FILES_FAIL,
	payload: { error },
})

export const getProductionChatImages = (orderId, orderFragmentId) => ({
	type: GET_PRODUCTION_CHAT_IMAGES,
	payload: { orderId, orderFragmentId },
})

export const getProductionChatImagesSuccess = (response) => ({
	type: GET_PRODUCTION_CHAT_IMAGES_SUCCESS,
	payload: { response },
})

export const getProductionChatImagesFail = (error) => ({
	type: GET_PRODUCTION_CHAT_IMAGES_FAIL,
	payload: { error },
})

export const uploadProductionChatImage = (accountId, orderId, orderFragmentId, chatMessageId, images) => ({
	type: UPLOAD_PRODUCTION_CHAT_IMAGE,
	payload: { accountId, orderId, orderFragmentId, chatMessageId, images },
})

export const uploadProductionChatImageSuccess = (response) => ({
	type: UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS,
	payload: { response },
})

export const uploadProductionChatImageFail = (error) => ({
	type: UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL,
	payload: { error },
})

export const uploadCompleteProductionFiles = ({ data, files }) => ({
	type: UPLOAD_COMPLETE_PRODUCTION_FILES,
	payload: { data, files },
})
export const uploadCompleteProductionFilesSuccess = (response) => ({
	type: UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS,
	payload: { response },
})
export const uploadCompleteProductionFilesFail = (error) => ({
	type: UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL,
	payload: { error },
})

export const getCompleteProductionFiles = (input) => ({
	type: GET_COMPLETE_PRODUCTION_FILES,
	payload: input,
})
export const getCompleteProductionFilesSuccess = (response) => ({
	type: GET_COMPLETE_PRODUCTION_FILES_SUCCESS,
	payload: { response },
})
export const getCompleteProductionFilesFail = (error) => ({
	type: GET_COMPLETE_PRODUCTION_FILES_FAIL,
	payload: { error },
})

export const deleteModifiedBlueprints = (data) => ({
	type: DELETE_MODIFIED_BLUEPRINTS,
	payload: { data },
})

export const deleteModifiedBlueprintsSuccess = ({ response }) => ({
	type: DELETE_MODIFIED_BLUEPRINTS_SUCCESS,
	payload: { response },
})

export const deleteModifiedBlueprintsFail = (error) => ({
	type: DELETE_MODIFIED_BLUEPRINTS_FAIL,
	payload: { error },
})

export const uploadOrderShipmentFiles = (data) => ({
	type: UPLOAD_ORDER_SHIPMENT_FILES,
	payload: { data },
})

export const uploadOrderShipmentFilesSuccess = ({ response }) => ({
	type: UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS,
	payload: { response },
})

export const uploadOrderShipmentFilesFail = (error) => ({
	type: UPLOAD_ORDER_SHIPMENT_FILES_FAIL,
	payload: { error },
})
export const uploadQualityVerificationImages = ({ data, images }) => ({
	type: UPLOAD_QUALITY_VERIFICATION_IMAGES,
	payload: { data, images },
})
export const uploadQualityVerificationImagesSuccess = response => ({
	type: UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	payload: { response },
})
export const uploadQualityVerificationImagesFail = error => ({
	type: UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL,
	payload: { error },
})

export const downloadPartListImagesInBase64 = (data) => ({
	type: DOWNLOAD_PART_LIST_IMAGES_IN_BASE64,
	payload: { data },
})
export const downloadPartListImagesInBase64Success = response => ({
	type: DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS,
	payload: { response },
})
export const downloadPartListImagesInBase64Fail = error => ({
	type: DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL,
	payload: { error },
})

export const uploadOrderDeliveryNote = ({ data, deliveryNote }) => ({
	type: UPLOAD_ORDER_DELIVERY_NOTE,
	payload: { data, deliveryNote },
})
export const uploadOrderDeliveryNoteSuccess = response => ({
	type: UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS,
	payload: { response },
})
export const uploadOrderDeliveryNoteFail = error => ({
	type: UPLOAD_ORDER_DELIVERY_NOTE_FAIL,
	payload: { error },
})

export const getQualityVerificationImages = (data, dontShowModalAutomatic) => ({
	type: GET_QUALITY_VERIFICATION_IMAGES,
	payload: { data, dontShowModalAutomatic },
})
export const getQualityVerificationImagesSuccess = response => ({
	type: GET_QUALITY_VERIFICATION_IMAGES_SUCCESS,
	payload: { response },
})
export const getQualityVerificationImagesFail = error => ({
	type: GET_QUALITY_VERIFICATION_IMAGES_FAIL,
	payload: { error },
})

export const getAllOrderShipmentFiles = (orderId) => ({
	type: GET_ALL_ORDER_SHIPMENT_FILES,
	payload: orderId,
})

export const getAllOrderShipmentFilesSuccess = response => ({
	type: GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS,
	payload: { response },
})
export const getAllOrderShipmentFilesFail = error => ({
	type: GET_ALL_ORDER_SHIPMENT_FILES_FAIL,
	payload: { error },
})

export const deleteCompleteProductionFile = ({ orderId, orderFragmentId, filename }) => ({
	type: DELETE_COMPLETE_PRODUCTION_FILE,
	payload: { orderId, orderFragmentId, filename },
})
export const deleteCompleteProductionFileSuccess = (response) => ({
	type: DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS,
	payload: { response },
})
export const deleteCompleteProductionFileFail = (error) => ({
	type: DELETE_COMPLETE_PRODUCTION_FILE_FAIL,
	payload: { error },
})
