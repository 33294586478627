import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { FIELD_NAMES, updateVisibility } from 'common/Utils/PartUtils'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { PartName } from 'components/Common/PartName'
import PartThumbnail from 'components/Common/PartThumbnail'
import TooltipMessage from 'components/Common/TooltipMessage'
import { TECHNOLOGY_TYPE } from 'constants/technologies'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

export const EditConfigurationModal = (props) => {

	const selectedParts = useMemo(() => {
		if (props.offer == null || props.selectedRows == null) return []
		const selectedPartIds = props.selectedRows.map(x => parseInt(x.id.value))
		return props.offer?.parts?.filter(part => selectedPartIds.includes(part.id))
	}, [props.selectedRows])

	const {
		t,
		isOpen,
		closeModal,
		selectedRows,
		updateParts,
		updatePartsLoading,
		updatePartsSuccess,
		updatePartsError,
		partConfigOptions,
	} = props

	const {
		fields,
		fieldVisibilityConditions: fieldsVisibilityConditions,
	} = partConfigOptions || {}

	const [
		{
			allFieldsSpecified,
			configurationSelectedReal,
			fieldsVisible,
		},
		setVisibility,
	] = useState({})

	const [optionsSelected, setOptionsSelected] = useState({})
	const [recalculatedWeights, setRecalculatedWeights] = useState({})
	const [resultModalIsOpen, setResultModalIsOpen] = useState(false)

	useEffect(() => {
		if (partConfigOptions) {
			setVisibility(updateVisibility({
				fields,
				fieldsVisibilityConditions,
				optionsSelected,
			}))
		}
	}, [partConfigOptions, optionsSelected])

	useEffect(() => {
		if (!isOpen) return
		if (updatePartsSuccess || updatePartsError) {
			setResultModalIsOpen(true)
		}
	}, [updatePartsSuccess, updatePartsError])

	const handleUpdate = () => {
		const partIds = selectedRows.map(row => row.id.value)
		updateParts({
			parts: partIds.map(partId => ({
				id: partId,
				config: {
					technologyType: configurationSelectedReal.technologyType || -1,
					material: configurationSelectedReal.material || -1,
					alloy: configurationSelectedReal.alloy || -1,
					finish: configurationSelectedReal.finish || -1,
					palette: configurationSelectedReal.palette || -1,
					color: configurationSelectedReal.color || -1,
					colorFinish: configurationSelectedReal.colorFinish || -1,
					hardness: configurationSelectedReal.hardness || -1,
				},
				weight: recalculatedWeights[partId],
			})),
		})
	}

	const getPartCard = (part) => {
		return (
			<div key={`row-${part.id}`}>
				<div className="part-card m-1">
					<div className="d-flex justify-content-center p-2">
						<PartThumbnail
							propsImageUrl={part.imageUrl}
							propsGeometryUrl={part.fileLinks?.stlModel || part.fileLinks?.objModel}
							geometryData={{
								partId: part.id,
								partName: part.name,
								size: part.size,
								weight: part.weight,
							}}
						/>
					</div>
					<ul className="list-unstyled m-2">
						<li>
							<strong className="title">
								{part.id}
							</strong>
						</li>
						<li>
							<PartName
								name={part.name}
								maxLength={19}
							/>
						</li>
						<li>
							{FIELD_NAMES.map((fieldName, index) => {
								if (fieldName == 'materialType' || fieldName == 'palette') return <span key={`${fieldName}_${index}`} />
								const value = part.config?.[fieldName]
								if (value == null) return <React.Fragment key={`${fieldName}_${index}`} />
								const option = fields[fieldName].options[value]
								const translated = option?.[`name_${getLanguage()}`] || option?.name || ''
								return (
									<span key={`part-${part.id}-${fieldName}`}>
										{translated}
									</span>
								)
							})}
						</li>
						<li className="fw-bold">
							Peso:
							<br />
							{recalculatedWeights[part.id]} g
						</li>
					</ul>
				</div>
			</div>
		)
	}

	const ResultModal = (
		<Modal
			isOpen={resultModalIsOpen}
			closeModal={() => {
				if (updatePartsSuccess) history.go(0)
				else setResultModalIsOpen(false)
			}}
			title={updatePartsSuccess
				? t('update_success', { ns: 'naming' })
				: t('error_message', { ns: 'naming' })}
		/>
	)

	const EditConfigModal = (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			title={t('edit_configuration', { ns: 'naming' })}
			className={selectedRows?.length > 3 ? 'modal-lg' : ''}
			body={(
				<div>
					{(selectedParts?.length || 0) > 0 ? (
						<div className="d-flex flex-column p-2">
							<div className="d-flex flex-row flex-wrap justify-content-center pb-3 border-bottom border-grey ">
								{selectedParts?.map(getPartCard)}
							</div>
							{fieldsVisible.map(({ fieldName, optionIds }) => {
								const exception_objAndStlCanOnlyBe3d = (
									fieldName === 'technologyType' &&
									optionsSelected[fieldName] != TECHNOLOGY_TYPE.ADDITIVE_MANUFACTURING
								)
								const warningTooltip = (
									<TooltipMessage place="top" message={'Para archivos STL o OBJ solo puedes seleccionar tecnología 3D'}>
										<div>
											<div style={{ marginTop: '5px' }}>
												<i className="bx bxs-error text-warning ms-1"></i>
											</div>
										</div>
									</TooltipMessage>
								)
								return (
									<div id={fieldName} key={`field-${fieldName}`}>
										{t(fieldName, { ns: 'naming' })}
										{exception_objAndStlCanOnlyBe3d && warningTooltip}
										<Select
											id={`select-${fieldName}`}
											key={`edit-config-modal-${fieldName}`}
											name={`select-${fieldName}`}
											options={optionIds.map(optionId => {
												const option = fields[fieldName].options[optionId]
												const translated = option?.[`name_${getLanguage()}`] || option?.name || ''
												return {
													label: translated,
													value: optionId,
												}
											})}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={(e) => {
												if (fieldName == 'material') {
													setRecalculatedWeights(selectedParts.reduce((prev, curr) => {
														const density = fields.material?.options[e?.value]?.density || 0
														const weight = density * curr.volume
														return { ...prev, [curr.id]: weight }
													}, {}))
												}
												setOptionsSelected({
													...optionsSelected,
													[fieldName]: e?.value,
												})
											}}

										/>
									</div>
								)
							})}
						</div>
					) : (
						<div className="p-4">
							<h3>
								{t('at_least_one_valid_part', { ns: 'naming' })}
							</h3>
						</div>
					)}
				</div>
			)}
			buttons={[
				<button
					type="button"
					key="saveQuantitiesButton"
					disabled={!(allFieldsSpecified && !updatePartsLoading)}
					className="btn btn-primary"
					onClick={e => {
						e.preventDefault()
						handleUpdate()
					}}
				>
					{updatePartsLoading ? (
						<Loader />
					) : (
						t('save', { ns: 'naming' })
					)}
				</button>,
			]}
		/>
	)

	return (
		<React.Fragment>
			{EditConfigModal}
			{ResultModal}
		</React.Fragment>
	)
}

EditConfigurationModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	selectedRows: PropTypes.array,
	updateParts: PropTypes.func,
	updatePartsLoading: PropTypes.bool,
	updatePartsSuccess: PropTypes.bool,
	updatePartsError: PropTypes.any,
	partConfigOptions: PropTypes.any,
}
