import { Unit } from './UnitConversionUtils'

export const inferMeasure = (size) => {

	const { x, y, z } = size || {}

	if (x == null || y == null || z == null) return Unit.millimeters

	return inferUnit({ x, y, z })
}

/**
 * Guess unit by size
 */
const inferUnit = (size) => {
	const maxDimension = Math.max(size.x, size.y, size.z)
	if (maxDimension < 0.1) return Unit.meters // Likely in meters if dimensions are very small numerically
	if (maxDimension < 10) return Unit.inches // Likely in inches
	return Unit.millimeters // Likely in millimeters
}
