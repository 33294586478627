// useZoomDetector.ts
import { useEffect, useState } from 'react'

export const useZoomDetector = () => {
	const getZoomLevel = () => {
		return window.devicePixelRatio || 1
	}

	const [zoomInfo, setZoomInfo] = useState({
		zoomLevel: getZoomLevel(),
	})

	useEffect(() => {
		const handleResizeOrZoom = () => {
			const currentZoom = getZoomLevel()
			setZoomInfo({
				zoomLevel: currentZoom,
			})
		}

		// Listen for resize events which can indicate a zoom change
		window.addEventListener('resize', handleResizeOrZoom)

		// Set up media query to detect changes in devicePixelRatio (zoom level)
		const mediaQuery = window.matchMedia(`(resolution: ${getZoomLevel()}dppx)`)

		// Add event listener using addEventListener if supported
		// This is the modern approach
		if (mediaQuery.addEventListener) {
			mediaQuery.addEventListener('change', handleResizeOrZoom)
		} else {
			// Fallback: Some browsers may not support addEventListener on MediaQueryList
			// Here, we simply do not add the listener to avoid using deprecated methods
			console.warn('MediaQueryList.addEventListener is not supported in this browser.')
		}

		return () => {
			window.removeEventListener('resize', handleResizeOrZoom)
			if (mediaQuery.removeEventListener) {
				mediaQuery.removeEventListener('change', handleResizeOrZoom)
			}
		}
	}, [])

	return zoomInfo
}
