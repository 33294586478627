import { calculateBoundaryBoxVolume } from './calculateBoundaryBoxVolume'
import { calculateChipVolume } from './calculateChipVolume'
import { convertUnit, Unit, UnitExponent } from './UnitConversionUtils'

/**
 * Convierte las medidas a las correctas y calcula el resto de datos
 * 
 * @param {{
 *   size?: {
 *     value?: {
 *       x?: number,
 *       y?: number,
 *       z?: number
 *     },
 *     unit?: string
 *   },
 *   area?: {
 *     value?: number,
 *     unit?: string
 *   },
 *   volume?: {
 *     value?: number,
 *     unit?: string
 *   }
 * }} args
*
* @returns {{
*   size_mm: { x: number, y: number, z: number },
*   area_cm2: number,
*   volume_cm3: number,
*   boundaryBoxVolume_cm3: number,
*   chipVolume_cm3: number
* }}
*/
export const recalculatePartCadData = (args) => {

	const { size, area, volume } = args

	// Convert units:
	// > SIZE UNIT: MM ------ the width, height and depth are stored in mm
	// > AREA UNIT: CM2 ----- the area is stored in cm2
	// > VOLUME UNIT: CM3 --- the volume is stored in cm3

	const size_mm = {
		x: convertUnit(size.value.x, size.unit, Unit.millimeters, UnitExponent.none),
		y: convertUnit(size.value.y, size.unit, Unit.millimeters, UnitExponent.none),
		z: convertUnit(size.value.z, size.unit, Unit.millimeters, UnitExponent.none),
	}
	const area_cm2 = convertUnit(area.value, area.unit, Unit.centimeters, UnitExponent.square)
	const volume_cm3 = convertUnit(volume.value, volume.unit, Unit.centimeters, UnitExponent.cubic)

	// Calculate extra values (and convert units if needed):

	const boundaryBoxVolume_mm3 = calculateBoundaryBoxVolume(size_mm)
	const boundaryBoxVolume_cm3 = convertUnit(boundaryBoxVolume_mm3, Unit.millimeters, Unit.centimeters, UnitExponent.cubic)
	const chipVolume_cm3 = calculateChipVolume({ volume: volume_cm3, boundaryBoxVolume: boundaryBoxVolume_cm3 })

	// Arrange data in an object:

	let res = {
		size_mm,
		area_cm2,
		volume_cm3,
		boundaryBoxVolume_cm3,
		chipVolume_cm3,
	}

	// Round values to 2 decimals:

	res = {
		size_mm: {
			x: Number(res.size_mm.x.toFixed(2)),
			y: Number(res.size_mm.y.toFixed(2)),
			z: Number(res.size_mm.z.toFixed(2)),
		},
		area_cm2: Number(res.area_cm2.toFixed(2)),
		volume_cm3: Number(res.volume_cm3.toFixed(2)),
		boundaryBoxVolume_cm3: Number(res.boundaryBoxVolume_cm3.toFixed(2)),
		chipVolume_cm3: Number(res.chipVolume_cm3.toFixed(2)),
	}

	// Return:

	return res
}
