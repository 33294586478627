import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'

class MultiSelect extends Component {
	render() {
		return (
			<div className={this.props.className}>
				<Select
					id={this.props.id}
					placeholder={this.props.placeholder}
					//value={selectedMulti}
					isMulti={true}
					onChange={e => {
						this.props.onChange(e)
					}}
					options={this.props.options}
					classNamePrefix="select2-selection"
				/>
			</div>
		)
	}
}
MultiSelect.propTypes = {
	id: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			isDisabled: PropTypes.bool,
		}),
	),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
}

export default MultiSelect
