import {
	ADD_EXPEDITION_ORDER,
	ADD_EXPEDITION_TO_ORDER,
	ADD_INTERNAL_COMMENT,
	ADD_QUALITY_VERIFICATION_IMAGES,
	ADD_SCANNED_ORDER,
	ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER,
	ADD_SCANNED_PIECE_TO_ORDER,
	ADD_SCANNED_VERIFICATION_ORDER,
	CREATE_INTERNAL_COMMENT,
	CREATE_INTERNAL_COMMENT_FAIL,
	CREATE_INTERNAL_COMMENT_SUCCESS,
	CREATE_INVOICE,
	CREATE_INVOICE_FAIL,
	CREATE_INVOICE_SUCCESS,
	CREATE_NEW_NACEX_EXPEDITION,
	CREATE_NEW_NACEX_EXPEDITION_FAIL,
	CREATE_NEW_NACEX_EXPEDITION_SUCCESS,
	CREATE_NEW_UPS_EXPEDITION,
	CREATE_NEW_UPS_EXPEDITION_FAIL,
	CREATE_NEW_UPS_EXPEDITION_SUCCESS,
	CREATE_PART_VERIFICATION,
	CREATE_PART_VERIFICATION_FAIL,
	CREATE_PART_VERIFICATION_SUCCESS,
	CREATE_REMIT,
	CREATE_REMIT_FAIL,
	CREATE_REMIT_SUCCESS,
	CREATE_SHIPMENT_EXPORT,
	CREATE_SHIPMENT_EXPORT_FAIL,
	CREATE_SHIPMENT_EXPORT_SUCCESS,
	CREATE_VOUCHER,
	CREATE_VOUCHER_FAIL,
	CREATE_VOUCHER_SUCCESS,
	GET_BILLING_DASHBOARD,
	GET_BILLING_DASHBOARD_FAIL,
	GET_BILLING_DASHBOARD_SUCCESS,
	GET_BILLING_LIST,
	GET_BILLING_LIST_FAIL,
	GET_BILLING_LIST_SUCCESS,
	GET_BOX_LIST,
	GET_BOX_LIST_FAIL,
	GET_BOX_LIST_SUCCESS,
	GET_DOMAIN_ORDERS,
	GET_DOMAIN_ORDERS_FAIL,
	GET_DOMAIN_ORDERS_SUCCESS,
	GET_EXPEDITION_NACEX_LABELS,
	GET_EXPEDITION_NACEX_LABELS_FAIL,
	GET_EXPEDITION_NACEX_LABELS_SUCCESS,
	GET_EXPEDITION_UPS_LABELS,
	GET_EXPEDITION_UPS_LABELS_FAIL,
	GET_EXPEDITION_UPS_LABELS_SUCCESS,
	GET_EXPORTED_PART_BY_DATE_LIST,
	GET_EXPORTED_PART_BY_DATE_LIST_FAIL,
	GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS,
	GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS,
	GET_FILTERED_ORDERS,
	GET_FILTERED_ORDERS_FAIL,
	GET_FILTERED_ORDERS_SUCCESS,
	GET_INCIDENCES_LIST,
	GET_INCIDENCES_LIST_FAIL,
	GET_INCIDENCES_LIST_SUCCESS,
	GET_LAST_THREE_DAYS_SHIPMENTS,
	GET_LAST_THREE_DAYS_SHIPMENTS_FAIL,
	GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS,
	GET_OPENED_EXPEDITION_ORDERS,
	GET_OPENED_EXPEDITION_ORDERS_FAIL,
	GET_OPENED_EXPEDITION_ORDERS_SUCCESS,
	GET_OPENED_VERIFICATION_ORDERS,
	GET_OPENED_VERIFICATION_ORDERS_FAIL,
	GET_OPENED_VERIFICATION_ORDERS_SUCCESS,
	GET_OPEPENED_ORDERS,
	GET_OPEPENED_ORDERS_FAIL,
	GET_OPEPENED_ORDERS_SUCCESS,
	GET_ORDER_DETAIL,
	GET_ORDER_DETAIL_BY_ID,
	GET_ORDER_DETAIL_BY_ID_FAIL,
	GET_ORDER_DETAIL_BY_ID_SUCCESS,
	GET_ORDER_DETAIL_FAIL,
	GET_ORDER_DETAIL_SUCCESS,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL,
	GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS,
	GET_ORDER_LIST,
	GET_ORDER_LIST_FAIL,
	GET_ORDER_LIST_SUCCESS,
	GET_PENDING_INVOICE_ORDERS,
	GET_PENDING_INVOICE_ORDERS_FAIL,
	GET_PENDING_INVOICE_ORDERS_SUCCESS,
	GET_QUALITY_PROBLEMS,
	GET_QUALITY_PROBLEMS_FAIL,
	GET_QUALITY_PROBLEMS_SUCCESS,
	GET_QUALITY_QUANTITIES,
	GET_QUALITY_QUANTITIES_FAIL,
	GET_QUALITY_QUANTITIES_SUCCESS,
	GET_QUALITY_SOLUTIONS,
	GET_QUALITY_SOLUTIONS_FAIL,
	GET_QUALITY_SOLUTIONS_SUCCESS,
	GET_VOUCHER_LIST,
	GET_VOUCHER_LIST_FAIL,
	GET_VOUCHER_LIST_SUCCESS,
	HANDLE_CANCELLED_ORDER,
	HANDLE_CANCELLED_ORDER_FAIL,
	HANDLE_CANCELLED_ORDER_SUCCESS,
	HANDLE_SENT_ORDER,
	HANDLE_SENT_ORDER_FAIL,
	HANDLE_SENT_ORDER_SUCCESS,
	HANDLE_SUCCESSFUL_ORDER,
	HANDLE_SUCCESSFUL_ORDER_FAIL,
	HANDLE_SUCCESSFUL_ORDER_SUCCESS,
	MANAGE_SCANNED_QUANTITY_ON_DELIVERY,
	RESET_EXPEDITION_ORDER_BOXCODE,
	RESET_NACEX_INFO,
	RESET_ORDER_BOXCODE,
	RESET_ORDER_DETAIL,
	RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES,
	RESET_SCANNED_PIECES_FROM_ALL_PIECES,
	RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE,
	RESET_SCANNED_PIECES_FROM_PIECE,
	SAVE_GENERATED_PDF,
	SCAN_ORDER_EXPEDITION_PART,
	SCAN_ORDER_EXPEDITION_PART_FAIL,
	SCAN_ORDER_EXPEDITION_PART_SUCCESS,
	SET_COMPLETED_EXPEDITION_ORDER,
	SET_EXPEDITION_BOX_LABEL_GENERATED,
	SET_EXPEDITION_COMMENT_READED,
	SET_EXPEDITION_DELIVERY_NOTE_PRINTED,
	SET_EXPEDITION_ORDER_BOXCODE,
	SET_LAST_SCANNED_PART,
	SET_ORDER_BOXCODE,
	SET_SELECTED_EXPEDITION_ORDER_ID,
	SET_SELECTED_ORDER_ID,
	SET_SELECTED_VERIFICATION_ORDER_ID,
	UPDATE_BOXES_BY_REF,
	UPDATE_BOXES_BY_REF_FAIL,
	UPDATE_BOXES_BY_REF_SUCCESS,
	UPDATE_ORDER,
	UPDATE_ORDER_EXPEDITION_INFO,
	UPDATE_ORDER_EXPEDITION_INFO_FAIL,
	UPDATE_ORDER_EXPEDITION_INFO_SUCCESS,
	UPDATE_ORDER_EXPEDITION_SHIPMENT,
	UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES,
	UPDATE_ORDER_FAIL,
	UPDATE_ORDER_ITEM,
	UPDATE_ORDER_ITEM_FAIL,
	UPDATE_ORDER_ITEM_SUCCESS,
	UPDATE_ORDER_SHIPPING_ADDRESS,
	UPDATE_ORDER_SHIPPING_ADDRESS_FAIL,
	UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS,
	UPDATE_ORDER_SUCCESS,
	UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY,
	UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_FAIL,
	UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_SUCCESS,
	UPDATE_QUALITY_VERIFICATION,
	UPDATE_QUALITY_VERIFICATION_FAIL,
	UPDATE_QUALITY_VERIFICATION_SUCCESS,
	UPDATE_VOUCHER_BY_C0DE,
	UPDATE_VOUCHER_BY_C0DE_FAIL,
	UPDATE_VOUCHER_BY_C0DE_SUCCESS,
} from './actionTypes'


export const getOrderItemByOrderIdSearch = (orderId, partId) => ({
	type: GET_ORDER_ITEM_BY_ORDER_ID_SEARCH,
	payload: { orderId, partId },
})

export const getOrderItemByOrderIdSearchSuccess = ({ orderItems }) => ({
	type: GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS,
	payload: orderItems,
})

export const getOrderItemByOrderIdSearchFail = error => ({
	type: GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL,
	payload: error,
})

export const updateQualityVerification = ({ data }) => ({
	type: UPDATE_QUALITY_VERIFICATION,
	payload: { data },
})

export const updateQualityVerificationSuccess = (qualityVerification) => {
	return {
		type: UPDATE_QUALITY_VERIFICATION_SUCCESS,
		payload: qualityVerification,
	}
}

export const updateQualityVerificationFail = (error) => ({
	type: UPDATE_QUALITY_VERIFICATION_FAIL,
	payload: { error },
})


export const getOrderDetail = orderId => ({
	type: GET_ORDER_DETAIL,
	payload: { orderId },
})

export const getOrderDetailSuccess = ({ data: { order, account, organization } }) => ({
	type: GET_ORDER_DETAIL_SUCCESS,
	payload: { order, account, organization },
})

export const getOrderDetailFail = error => ({
	type: GET_ORDER_DETAIL_FAIL,
	payload: error,
})

export const updateOrderShippingAddress = ({ data: { orderId, shippingAddress } }) => ({
	type: UPDATE_ORDER_SHIPPING_ADDRESS,
	payload: { orderId, shippingAddress },
})

export const updateOrderShippingAddressSuccess = ({ shippingAddress }) => ({
	type: UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS,
	payload: { shippingAddress },
})

export const updateOrderShippingAddressFail = error => ({
	type: UPDATE_ORDER_SHIPPING_ADDRESS_FAIL,
	payload: error,
})

export const updateOrder = ({ data: { order } }) => ({
	type: UPDATE_ORDER,
	payload: { order },
})

export const updateOrderSuccess = ({ order }) => {
	return ({
		type: UPDATE_ORDER_SUCCESS,
		payload: { order },
	})
}

export const updateOrderFail = error => ({
	type: UPDATE_ORDER_FAIL,
	payload: error,
})

export const createInternalComment = ({ data: { comment } }) => ({
	type: CREATE_INTERNAL_COMMENT,
	payload: { comment },
})

export const createInternalCommentSuccess = (comment) => ({
	type: CREATE_INTERNAL_COMMENT_SUCCESS,
	payload: { comment },
})

export const createInternalCommentFail = error => ({
	type: CREATE_INTERNAL_COMMENT_FAIL,
	payload: error,
})

export const getOrderDetailById = orderId => ({
	type: GET_ORDER_DETAIL_BY_ID,
	payload: orderId,
})

export const getOrderDetailByIdSuccess = order => {
	return {
		type: GET_ORDER_DETAIL_BY_ID_SUCCESS,
		payload: { order },
	}
}

export const getOrderDetailByIdFail = error => ({
	type: GET_ORDER_DETAIL_BY_ID_FAIL,
	payload: error,
})

export const getOrderList = () => ({
	type: GET_ORDER_LIST,
})

export const getOrderListSuccess = ({ data: { orderList } }) => ({
	type: GET_ORDER_LIST_SUCCESS,
	payload: { orderList },
})

export const getOrderListFail = error => ({
	type: GET_ORDER_LIST_FAIL,
	payload: error,
})

export const getBillingDashboard = (data) => ({
	type: GET_BILLING_DASHBOARD,
	payload: { data },
})

export const getBillingDashboardSuccess = billingData => ({
	type: GET_BILLING_DASHBOARD_SUCCESS,
	payload: billingData,
})

export const getBillingDashboardFail = error => ({
	type: GET_BILLING_DASHBOARD_FAIL,
	payload: error,
})

export const handleSuccessfulOrder = ({ orderId, paymentDate, emailOnlyToCustomer, statusId, sendEmail }) => ({
	type: HANDLE_SUCCESSFUL_ORDER,
	payload: { orderId, paymentDate, emailOnlyToCustomer, statusId, sendEmail },
})
export const handleSuccessfulOrderSuccess = (order) => ({
	type: HANDLE_SUCCESSFUL_ORDER_SUCCESS,
	payload: { order },
})
export const handleSuccessfulOrderFail = (error) => ({
	type: HANDLE_SUCCESSFUL_ORDER_FAIL,
	payload: error,
})

export const handleCancelledOrder = ({ orderId }) => ({
	type: HANDLE_CANCELLED_ORDER,
	payload: { orderId },
})
export const handleCancelledOrderSuccess = (order) => ({
	type: HANDLE_CANCELLED_ORDER_SUCCESS,
	payload: { order },
})
export const handleCancelledOrderFail = (error) => ({
	type: HANDLE_CANCELLED_ORDER_FAIL,
	payload: error,
})

export const setSelectedOrderId = (orderId) => ({
	type: SET_SELECTED_ORDER_ID,
	payload: { orderId },
})

export const setSelectedVerificationOrderId = (orderId) => ({
	type: SET_SELECTED_VERIFICATION_ORDER_ID,
	payload: { orderId },
})

export const setSelectedExpeditionOrderId = (orderId) => ({
	type: SET_SELECTED_EXPEDITION_ORDER_ID,
	payload: { orderId },
})

export const addScannedOrder = (order) => ({
	type: ADD_SCANNED_ORDER,
	payload: { order },
})

export const setLastScannedPart = (partId) => ({
	type: SET_LAST_SCANNED_PART,
	payload: { partId },
})

export const setOrderBoxCode = (boxCode, orderId) => ({
	type: SET_ORDER_BOXCODE,
	payload: { boxCode, orderId },
})

export const addScannedPieceToOrder = (pieceId, orderId, quantity) => ({
	type: ADD_SCANNED_PIECE_TO_ORDER,
	payload: { pieceId, orderId, quantity },
})

export const resetScannedPiecesFromPiece = (pieceId, orderId) => ({
	type: RESET_SCANNED_PIECES_FROM_PIECE,
	payload: { pieceId, orderId },
})

export const resetScannedPiecesFromAllPieces = (orderId) => ({
	type: RESET_SCANNED_PIECES_FROM_ALL_PIECES,
	payload: { orderId },
})

export const resetOrderBoxCode = (orderId) => ({
	type: RESET_ORDER_BOXCODE,
	payload: { orderId },
})

export const addExpeditionOrder = (order, orderId) => ({
	type: ADD_EXPEDITION_ORDER,
	payload: { order, orderId },
})

export const addScannedPieceToExpeditionOrder = (pieceId, orderId, quantity) => ({
	type: ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER,
	payload: { pieceId, orderId, quantity },
})

export const resetScannedPiecesFromExpeditionPiece = (pieceId, orderId) => ({
	type: RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE,
	payload: { pieceId, orderId },
})

export const resetScannedPiecesFromAllExpeditionPieces = (orderId) => ({
	type: RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES,
	payload: { orderId },
})

export const setExpeditionOrderBoxCode = (boxCode, orderId) => ({
	type: SET_EXPEDITION_ORDER_BOXCODE,
	payload: { boxCode, orderId },
})

export const createNewNacexExpedition = (data) => ({
	type: CREATE_NEW_NACEX_EXPEDITION,
	payload: { data },
})

export const createNewNacexExpeditionSuccess = (response) => ({
	type: CREATE_NEW_NACEX_EXPEDITION_SUCCESS,
	payload: { response },
})

export const createNewNacexExpeditionFail = (error) => ({
	type: CREATE_NEW_NACEX_EXPEDITION_FAIL,
	payload: error,
})

export const setCompletedExpeditionOrder = (orderId) => ({
	type: SET_COMPLETED_EXPEDITION_ORDER,
	payload: { orderId },
})

export const addExpeditionToOrder = (orderId, expedition) => ({
	type: ADD_EXPEDITION_TO_ORDER,
	payload: { orderId, expedition },
})

export const resetOrderDetail = () => ({
	type: RESET_ORDER_DETAIL,
})

export const manageScannedQuantityOnDelivery = (orderId) => ({
	type: MANAGE_SCANNED_QUANTITY_ON_DELIVERY,
	payload: { orderId },
})

export const getVoucherList = () => ({
	type: GET_VOUCHER_LIST,
})
export const getVoucherListSuccess = (voucherList) => ({
	type: GET_VOUCHER_LIST_SUCCESS,
	payload: { voucherList },
})
export const getVoucherListFail = (error) => ({
	type: GET_VOUCHER_LIST_FAIL,
	payload: error,
})

export const createVoucher = ({ voucher }) => ({
	type: CREATE_VOUCHER,
	payload: { voucher },
})
export const createVoucherSuccess = (voucher) => ({
	type: CREATE_VOUCHER_SUCCESS,
	payload: { voucher },
})
export const createVoucherFail = (error) => ({
	type: CREATE_VOUCHER_FAIL,
	payload: error,
})

export const updateVoucherByCode = ({ voucher }) => ({
	type: UPDATE_VOUCHER_BY_C0DE,
	payload: { voucher },
})
export const updateVoucherByCodeSuccess = (voucher) => ({
	type: UPDATE_VOUCHER_BY_C0DE_SUCCESS,
	payload: { voucher },
})
export const updateVoucherByCodeFail = (error) => ({
	type: UPDATE_VOUCHER_BY_C0DE_FAIL,
	payload: error,
})
export const getDomainOrders = (accountId) => ({
	type: GET_DOMAIN_ORDERS,
	payload: { accountId },
})
export const getDomainOrdersSuccess = (response) => ({
	type: GET_DOMAIN_ORDERS_SUCCESS,
	payload: { response },
})
export const getDomainOrdersFail = (error) => ({
	type: GET_DOMAIN_ORDERS_FAIL,
	payload: { error },
})

export const getBoxList = () => ({
	type: GET_BOX_LIST,
})
export const getBoxListSuccess = ({ data: { boxList } }) => ({
	type: GET_BOX_LIST_SUCCESS,
	payload: { boxList },
})
export const getBoxListFail = error => ({
	type: GET_BOX_LIST_FAIL,
	payload: error,
})

export const updateBoxesByRef = ({ data }) => ({
	type: UPDATE_BOXES_BY_REF,
	payload: { data },
})
export const updateBoxesByRefSuccess = ({ data }) => ({
	type: UPDATE_BOXES_BY_REF_SUCCESS,
	payload: { data },
})
export const updateBoxesByRefFail = error => ({
	type: UPDATE_BOXES_BY_REF_FAIL,
	payload: error,
})

export const getBillingList = ({ page, pageSize, dateRange }) => ({
	type: GET_BILLING_LIST,
	payload: { page, pageSize, dateRange },
})
export const getBillingListSuccess = ({ billingList, count }) => ({
	type: GET_BILLING_LIST_SUCCESS,
	payload: { billingList, count },
})
export const getBillingListFail = (error) => ({
	type: GET_BILLING_LIST_FAIL,
	payload: error,
})

export const getExportedPartListByDate = (dateRange, pagination) => ({
	type: GET_EXPORTED_PART_BY_DATE_LIST,
	payload: { dateRange, pagination },
})
export const getExportedPartListByDateSuccess = (data) => ({
	type: GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS,
	payload: data,
})
export const getExportedPartListByDateNoPaginatedSuccess = (data) => ({
	type: GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS,
	payload: data,
})
export const getExportedPartListByDateFail = error => ({
	type: GET_EXPORTED_PART_BY_DATE_LIST_FAIL,
	payload: error,
})

export const getFilteredOrders = (data, signal) => ({
	type: GET_FILTERED_ORDERS,
	payload: { data, signal },
})

export const getFilteredOrdersSuccess = ({ items, totalCount }) => ({
	type: GET_FILTERED_ORDERS_SUCCESS,
	payload: { items, totalCount },
})

export const getFilteredOrdersFail = (error) => ({
	type: GET_FILTERED_ORDERS_FAIL,
	payload: { error },
})

export const getOpenedOrders = () => ({
	type: GET_OPEPENED_ORDERS,
})

export const getOpenedOrdersSuccess = (data) => ({
	type: GET_OPEPENED_ORDERS_SUCCESS,
	payload: { data },
})

export const getOpenedOrdersFail = (error) => ({
	type: GET_OPEPENED_ORDERS_FAIL,
	payload: { error },
})

export const addScannedVerificationOrder = (order) => ({
	type: ADD_SCANNED_VERIFICATION_ORDER,
	payload: { order },
})

export const getOpenedVerificationOrders = () => ({
	type: GET_OPENED_VERIFICATION_ORDERS,
})

export const getOpenedVerificationOrdersSuccess = data => ({
	type: GET_OPENED_VERIFICATION_ORDERS_SUCCESS,
	payload: { data },
})

export const getOpenedVerificationOrdersFail = error => ({
	type: GET_OPENED_VERIFICATION_ORDERS_FAIL,
	payload: { error },
})

export const updateProductionItemReceivedQuantity = ({ data }) => ({
	type: UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY,
	payload: { data },
})

export const updateProductionItemReceivedQuantitySuccess = ({ data }) => ({
	type: UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_SUCCESS,
	payload: { data },
})

export const updateProductionItemReceivedQuantityFail = (error) => ({
	type: UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_FAIL,
	payload: { error },
})

export const getOpenedExpeditionOrders = () => ({
	type: GET_OPENED_EXPEDITION_ORDERS,
})

export const getOpenedExpeditionOrdersSuccess = (data) => ({
	type: GET_OPENED_EXPEDITION_ORDERS_SUCCESS,
	payload: { data },
})

export const getOpenedExpeditionOrdersFail = (error) => ({
	type: GET_OPENED_EXPEDITION_ORDERS_FAIL,
	payload: { error },
})

export const updateOrderExpeditionInfo = ({ data }) => ({
	type: UPDATE_ORDER_EXPEDITION_INFO,
	payload: { data },
})

export const updateOrderExpeditionInfoSuccess = ({ data }) => ({
	type: UPDATE_ORDER_EXPEDITION_INFO_SUCCESS,
	payload: { data },
})

export const updateOrderExpeditionInfoFail = (error) => ({
	type: UPDATE_ORDER_EXPEDITION_INFO_FAIL,
	payload: { error },
})

export const updateOrderItem = ({ data }) => ({
	type: UPDATE_ORDER_ITEM,
	payload: { data },
})

export const updateOrderItemSuccess = ({ data }) => ({
	type: UPDATE_ORDER_ITEM_SUCCESS,
	payload: { data },
})

export const updateOrderItemFail = (error) => ({
	type: UPDATE_ORDER_ITEM_FAIL,
	payload: { error },
})

export const scanOrderExpeditionPart = ({ data }) => ({
	type: SCAN_ORDER_EXPEDITION_PART,
	payload: { data },
})

export const scanOrderExpeditionPartSuccess = ({ data }) => ({
	type: SCAN_ORDER_EXPEDITION_PART_SUCCESS,
	payload: { data },
})

export const scanOrderExpeditionPartFail = (error) => ({
	type: SCAN_ORDER_EXPEDITION_PART_FAIL,
	payload: { error },
})

export const setExpeditionCommentReaded = (orderId) => ({
	type: SET_EXPEDITION_COMMENT_READED,
	payload: { orderId },
})

export const setExpeditionBoxLabelGenerated = (orderId) => ({
	type: SET_EXPEDITION_BOX_LABEL_GENERATED,
	payload: { orderId },
})

export const setExpeditionDeliveryNotePrinted = (orderId) => ({
	type: SET_EXPEDITION_DELIVERY_NOTE_PRINTED,
	payload: { orderId },
})

export const createShipmentExport = ({ data }) => ({
	type: CREATE_SHIPMENT_EXPORT,
	payload: { data },
})

export const createShipmentExportSuccess = ({ shipment }) => {
	return {
		type: CREATE_SHIPMENT_EXPORT_SUCCESS,
		payload: { shipment },
	}
}

export const createShipmentExportFail = (error) => ({
	type: CREATE_SHIPMENT_EXPORT_FAIL,
	payload: { error },
})

export const getLastThreeDaysShipments = (clientId) => ({
	type: GET_LAST_THREE_DAYS_SHIPMENTS,
	payload: { clientId },
})

export const getLastThreeDaysShipmentsSuccess = (data) => ({
	type: GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS,
	payload: { data },
})

export const getLastThreeDaysShipmentsFail = (error) => ({
	type: GET_LAST_THREE_DAYS_SHIPMENTS_FAIL,
	payload: { error },
})

export const resetExpeditionOrderBoxCode = (orderId) => ({
	type: RESET_EXPEDITION_ORDER_BOXCODE,
	payload: { orderId },
})

export const resetNacexInfo = () => ({
	type: RESET_NACEX_INFO,
})

export const getQualityQuantities = () => ({
	type: GET_QUALITY_QUANTITIES,
})
export const getQualityQuantitiesSuccess = (qualityQuantities) => ({
	type: GET_QUALITY_QUANTITIES_SUCCESS,
	payload: { qualityQuantities },
})
export const getQualityQuantitiesFail = error => ({
	type: GET_QUALITY_QUANTITIES_FAIL,
	payload: error,
})

export const getQualityProblems = () => ({
	type: GET_QUALITY_PROBLEMS,
})
export const getQualityProblemsSuccess = (qualityProblems) => ({
	type: GET_QUALITY_PROBLEMS_SUCCESS,
	payload: { qualityProblems },
})
export const getQualityProblemsFail = error => ({
	type: GET_QUALITY_PROBLEMS_FAIL,
	payload: error,
})

export const getQualitySolutions = () => ({
	type: GET_QUALITY_SOLUTIONS,
})

export const getQualitySolutionsSuccess = (qualitySolutions) => ({
	type: GET_QUALITY_SOLUTIONS_SUCCESS,
	payload: { qualitySolutions },
})
export const getQualitySolutionsFail = error => ({
	type: GET_QUALITY_SOLUTIONS_FAIL,
	payload: error,
})

export const createPartVerification = ({ data }) => ({
	type: CREATE_PART_VERIFICATION,
	payload: { data },
})
export const createPartVerificationSuccess = ({ qualityPartState, qualityVerification }) => ({
	type: CREATE_PART_VERIFICATION_SUCCESS,
	payload: { qualityPartState, qualityVerification },
})
export const createPartVerificationFail = error => ({
	type: CREATE_PART_VERIFICATION_FAIL,
	payload: error,
})
export const createNewUpsExpedition = (data) => ({
	type: CREATE_NEW_UPS_EXPEDITION,
	payload: { data },
})

export const createNewUpsExpeditionSuccess = (response) => ({
	type: CREATE_NEW_UPS_EXPEDITION_SUCCESS,
	payload: { response },
})

export const createNewUpsExpeditionFail = (error) => ({
	type: CREATE_NEW_UPS_EXPEDITION_FAIL,
	payload: error,
})

export const getExpeditionNacexLabels = (data) => ({
	type: GET_EXPEDITION_NACEX_LABELS,
	payload: { data },
})

export const getExpeditionNacexLabelsSuccess = (response) => ({
	type: GET_EXPEDITION_NACEX_LABELS_SUCCESS,
	payload: { response },
})

export const getExpeditionNacexLabelsFail = (error) => ({
	type: GET_EXPEDITION_NACEX_LABELS_FAIL,
	payload: error,
})

export const getExpeditionUpsLabels = (data) => ({
	type: GET_EXPEDITION_UPS_LABELS,
	payload: { data },
})

export const getExpeditionUpsLabelsSuccess = (response) => ({
	type: GET_EXPEDITION_UPS_LABELS_SUCCESS,
	payload: response,
})

export const getExpeditionUpsLabelsFail = (error) => ({
	type: GET_EXPEDITION_UPS_LABELS_FAIL,
	payload: error,
})

export const saveGeneratedPdf = (pdf) => ({
	type: SAVE_GENERATED_PDF,
	payload: pdf,
})
export const addQuatlityVerificationImages = ({ qualityVerificationId, orderId, images }) => ({
	type: ADD_QUALITY_VERIFICATION_IMAGES,
	payload: { qualityVerificationId, orderId, images },
})

export const addInternalComment = (comment) => ({
	type: ADD_INTERNAL_COMMENT,
	payload: { comment },
})

export const createRemit = ({ orderId, customNumber, items }) => ({
	type: CREATE_REMIT,
	payload: { orderId, customNumber, items },
})
export const createRemitSuccess = (response) => ({
	type: CREATE_REMIT_SUCCESS,
	payload: response,
})

export const createRemitFail = (error) => ({
	type: CREATE_REMIT_FAIL,
	payload: error,
})

export const createInvoice = ({ orderId, customNumber }) => ({
	type: CREATE_INVOICE,
	payload: { orderId, customNumber },
})
export const createInvoiceSuccess = (response) => ({
	type: CREATE_INVOICE_SUCCESS,
	payload: response,
})
export const createInvoiceFail = (error) => ({
	type: CREATE_INVOICE_FAIL,
	payload: error,
})

export const getPendingInvoiceOrders = () => ({
	type: GET_PENDING_INVOICE_ORDERS,
})
export const getPendingInvoiceOrdersSuccess = (response) => ({
	type: GET_PENDING_INVOICE_ORDERS_SUCCESS,
	payload: response,
})
export const getPendingInvoiceOrdersFail = (error) => ({
	type: GET_PENDING_INVOICE_ORDERS_FAIL,
	payload: error,
})

export const updateOrderExpeditionShipment = (orderId, shipment) => ({
	type: UPDATE_ORDER_EXPEDITION_SHIPMENT,
	payload: { orderId, shipment },
})

export const getIncidencesList = (data, signal) => ({
	type: GET_INCIDENCES_LIST,
	payload: { data, signal },
})

export const getIncidencesListSuccess = (response) => ({
	type: GET_INCIDENCES_LIST_SUCCESS,
	payload: response,
})

export const getIncidencesListFail = (error) => ({
	type: GET_INCIDENCES_LIST_FAIL,
	payload: { error },
})

export const updateOrderExpeditionShipmentFiles = (shipmentFiles) => ({
	type: UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES,
	payload: shipmentFiles,
})

export const handleSentOrder = ({ orderId, orderItems }) => ({
	type: HANDLE_SENT_ORDER,
	payload: { orderId, orderItems },
})
export const handleSentOrderSuccess = () => ({
	type: HANDLE_SENT_ORDER_SUCCESS,
	payload: {},
})
export const handleSentOrderFail = (error) => ({
	type: HANDLE_SENT_ORDER_FAIL,
	payload: error,
})
