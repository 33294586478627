import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'

export const ModalMessage = ({ id, message, isModalOpen, closeModal }) => {
	return (
		<Modal
			id={id}
			isOpen={isModalOpen}
			closeModal={() => closeModal(false)}
			body={<h4 className="p-4">{message}</h4>}
			buttons={[]}
		/>
	)
}

ModalMessage.propTypes = {
	message: PropTypes.string,
	isModalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
}
