import TooltipMessage from './Common/TooltipMessage'

/**
 * Este icono de nota con tooltip se usa para mostrar las siguientes notas/comentarios/mensajes:
 * 
 * ---
 * * `Offers."comment"`
 *   - Lo escribe el cliente en APP al crear una oferta
 *   - Se muestra en `/offer-list/`, `/offer/:id`
 * ---
 * * `Parts."comments"`
 *   - Lo escribe el cliente en APP al crear una oferta
 *   - Se muestra en `/offer/:id`
 * ---
 * * `Quotes."comment"`
 *   - Lo escribe el manager en `/offer/:id` al solicitar una cotización a un proveedor
 *   - Se muestra en `/offer/:id`, `/quote-list/`, `/quote/:id`
 * ---
 * * `Orders."customerComment"`
 *   - Lo escribe el cliente en APP al crear una oferta
 *   - Se muestra en `/orders/`, `/order/:id`
 * ---
 * * `Productions."comments"`
 *   - Lo escribe el proveedor en `/new-production/`  
 *   - Se muestra en `/productions/`, `/production/:id`, `/supplier-productions/`, `/supplier-production/:id`  
 */
export const NoteTooltip = ({ id, note, t, isBlack = false }) => {

	return (note != null && note != '') ? (
		<TooltipMessage
			id={id}
			message={note}
			place='top'
		>
			<i
				className={isBlack ? 'bx bxs-note' : 'bx bx-note'}
				style={{ fontSize: '1.2rem', cursor: 'pointer' }}
				onClick={() => {
					if (note != null && note != '') {
						navigator?.clipboard?.writeText(note)
						alert(`${t('note_copied_to_clipboard', { ns: 'naming' })}: ${note}`)
					}
				}}
			/>
		</TooltipMessage>
	) : (
		<div />
	)

}
