import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Card, CardBody, Col, Container, Label, Row } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { ErrorMessage, Field, Form, Formik } from 'formik'

// action
import { sendChangePasswordEmail } from '../../store/actions'

import * as Yup from 'yup'
import BrandLogo from '../../components/Common/BrandLogo'
import Footer from '../../components/Common/Footer'
import Loader from '../../components/Common/Loader'
import { EMAIL_REGEXP } from '../../constants/regexp'

import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'

class ForgetPasswordPage extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<React.Fragment>
				<MetaTags>
					<title>Proto&Go! | {this.props.t('password-recovery', { ns: 'naming' })}</title>
				</MetaTags>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark">
						<i className="bx bx-home h2" />
					</Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-black">
										<BrandLogo />
									</div>
									<CardBody className="pt-0 bg-black text-light">
										<div className="p-2">
											<h1 className="text-primary">Password recovery</h1>
											{this.props.forgetError && this.props.forgetError ? (
												<Alert id='forgetPasswordErrorDiv' color="danger" style={{ marginTop: '13px' }}>
													{this.props.forgetError}
												</Alert>
											) : null}
											{this.props.forgetSuccessMsg ? (
												<Alert id="forgetPasswordSuccessDiv" color="success" style={{ marginTop: '13px' }}>
													{this.props.forgetSuccessMsg}
												</Alert>
											) : null}
											<Formik
												enableReinitialize={true}
												initialValues={{
													email: (this.state && this.state.email) || '',
												}}
												validationSchema={Yup.object().shape({
													email: Yup.string()
														.required('Please Enter Your Email')
														.matches(
															EMAIL_REGEXP,
															'The email provided is not valid.',
														),
												})}
												onSubmit={values => {
													this.props.sendChangePasswordEmail(
														values,
														this.props.history,
													)
												}}
											>
												{({ errors, status, touched }) => (
													<Form className="form-horizontal">
														<div className="mb-3">
															<Label for="email" className="form-label">
																Email
															</Label>
															<Field
																id="forgetPasswordEmailInput"
																name="email"
																type="text"
																className={
																	'form-control' +
																	(errors.email && touched.email
																		? ' is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																id="forgetPasswordEmailErrorDiv"
																name="email"
																component="div"
																className="invalid-feedback"
															/>
														</div>
														<div className="text-end">
															<button
																id="forgetPasswordSubmitButton"
																className="btn btn-primary w-md"
																type="submit"
																disabled={errors.email || !touched.email}
															>
																{this.props.isLoading ? (
																	<Loader className="font-size-18" />
																) : (
																	'Reset'
																)}
															</button>
														</div>
													</Form>
												)}
											</Formik>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Go back to{' '}
										<Link id="ForgotYourPasswordGoToLoginA" to="login" className="fw-medium text-primary">
											Login
										</Link>{' '}
									</p>
									<Footer />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

ForgetPasswordPage.propTypes = {
	forgetError: PropTypes.func,
	forgetSuccessMsg: PropTypes.string,
	history: PropTypes.object,
	sendChangePasswordEmail: PropTypes.any,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
}

const mapStateToProps = state => {
	const { forgetError, forgetSuccessMsg, isLoading } = state.ForgetPassword
	return { forgetError, forgetSuccessMsg, isLoading }
}

export default withRouter(
	connect(mapStateToProps, { sendChangePasswordEmail })(withTranslation()(ForgetPasswordPage)),
)
