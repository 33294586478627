import { ChatItemsSelector } from 'components/Chat/ChatItemSelector'
import ChatMessages from 'components/Chat/ChatMessages'
import ChatPartInfo from 'components/Chat/ChatPartInfo'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'

const OfferChat = ({ parts, selectedPart, handleOnChangeSelectedPart, handleOpenChatMessage, openedChats, t, partConfigOptions }) => {

	const [selectedSupplierId, setSelectedSupplierId] = useState(null)

	const getAvailablePartSuppliers = () => {
		if (selectedPart == null) return []
		return selectedPart.costsAndMargins.suppliers.map(supplier => {
			const hasUnreadMessages = supplier.hasSupplierUnreadChats && !openedChats[selectedPart.id]?.includes(supplier.id)
			return {
				id: supplier.id,
				label: supplier.name,
				hasMessages: supplier.hasSupplierChats,
				hasUnreadMessages,
			}
		})
	}

	const getAvailableParts = () => {
		if (parts == null) return []
		return parts.map(part => {
			const hasMessages = part.costsAndMargins.suppliers.some(supplier => supplier.hasSupplierChats)
			const hasUnreadMessages = part.costsAndMargins.suppliers.some(supplier => {
				return supplier.hasSupplierUnreadChats && !openedChats[part.id]?.includes(supplier.id)
			})
			return {
				id: part.id,
				label: part.id,
				hasMessages,
				hasUnreadMessages,
			}
		})
	}

	const availableSuppliers = useMemo(() => getAvailablePartSuppliers(), [selectedPart, openedChats])
	const availableParts = useMemo(() => getAvailableParts(), [parts, openedChats])

	const handleOnChangeSelectedSupplier = (supplierId) => {
		setSelectedSupplierId(supplierId)
		handleOpenChatMessage(selectedPart.id, supplierId)
	}

	useEffect(() => {
		if (availableSuppliers.length > 0) {
			handleOpenChatMessage(selectedPart.id, availableSuppliers[0].id)
			setSelectedSupplierId(availableSuppliers[0]?.id)
		}
	}, [selectedPart])

	return (
		<div className='row'>
			<div className='col-3'>
				<ChatItemsSelector
					items={availableParts}
					handleOnChangeItem={handleOnChangeSelectedPart}
					selectedItemId={selectedPart?.id}
					sectionClassName='sectionParts'
				/>
				<div className='my-4'>
					<ChatItemsSelector
						items={availableSuppliers}
						handleOnChangeItem={handleOnChangeSelectedSupplier}
						selectedItemId={selectedSupplierId}
						sectionClassName='sectionSuppliers'
					/>
				</div>
			</div>
			<div id={'partInfo' + selectedPart.id} className='d-flex flex-column col-9'>
				{selectedPart != null && <ChatPartInfo partData={selectedPart} partConfigOptions={partConfigOptions} />}
				{availableSuppliers.length !== 0 ? <ChatMessages partId={selectedPart?.id} supplierId={selectedSupplierId} />
					: <div className='d-flex align-items-center justify-content-center h-100 fs-4'>{t('no-suppliers-chat', { ns: 'naming' })}</div>
				}
			</div>
		</div>
	)
}

OfferChat.propTypes = {
	parts: PropTypes.array,
	selectedPart: PropTypes.object,
	handleOnChangeSelectedPart: PropTypes.func,
	handleOpenChatMessage: PropTypes.func,
	openedChats: PropTypes.array,
	t: PropTypes.func,
}

export default (withTranslation()(OfferChat))
