import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useObjectState } from 'common/Hooks/UseObjectState'
import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SearchInput from 'components/Common/SearchInput'
import Switch from 'components/Common/Switch/Switch'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getIncidencesList } from 'store/actions'

export const FilterModal = ({ getIncidencesList, t, problems, solutions }) => {
	const [keyword, setKeyword] = useState('')
	const [isModalOpen, setIsModalOpen] = useState(false)

	const notifiedByOptions = ['quality', 'client']

	const INITIAL_FILTERS = {
		problems: null,
		solutions: null,
		notSolvedParts: null,
		orderSolved: null,
		creationDate: {
			startDate: null,
			endDate: null,
		},
		notifiedBy: null,
	}

	const { handleOnChange, handleOnChangeDateRange, objectData, resetDateRange, resetState, setObjectData } = useObjectState(INITIAL_FILTERS)
	const { handleApiCall, cancelApiCall } = useHandleCancelableApi(getIncidencesList, { data: { filters: objectData, keyword, pagination: {} } }, 500, true)
	const { handleApiCall: handleInitialApiCall } = useHandleCancelableApi(
		getIncidencesList,
		{ data: { filters: INITIAL_FILTERS, keyword, pagination: {} } },
		500,
		true
	)



	useEffect(() => {
		handleApiCall()
	}, [keyword])

	const handleApplyFilters = () => {
		handleApiCall()
		setIsModalOpen(false)
	}
	const handleOnCleanFilters = () => {
		resetState()
		setObjectData(INITIAL_FILTERS)
		handleInitialApiCall()
		setIsModalOpen(false)
	}

	return (
		<div className='mx-2 d-flex flex-row bg-white py-4 ps-2'>
			<SearchInput onChange={(e) => setKeyword(e)} />
			<button className='btn btn-secondary' onClick={() => setIsModalOpen(true)}>{t('filter', { ns: 'naming' })}</button>
			<Modal
				title={t('filter', { ns: 'naming' })}
				isOpen={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
				buttons={[
					<button id="resetFilterButton" key="resetFilter" className='btn btn-primary' onClick={() => { handleOnCleanFilters() }}>{t('clean', { ns: 'naming' })}</button>,
					<button className='btn btn-primary' key="apply-filters-button" onClick={() => { handleApplyFilters() }}>{t('apply', { ns: 'naming' })}</button>,
				]}
				body={
					<div className='p-4'>
						<OptionsInput
							isMulti
							setValue={handleOnChange}
							objectElement='problems'
							value={objectData.problems}
							options={problems?.map((problem) => ({ value: problem.id, label: problem.description }))}
							title={t('Problema', { ns: 'naming' })}
							automaticMappedValue
						/>
						<OptionsInput
							isMulti
							setValue={handleOnChange}
							objectElement='solutions'
							value={objectData.solutions}
							options={solutions?.map((solution) => ({ value: solution.id, label: solution.description }))}
							title={t('solution', { ns: 'naming' })}
							automaticMappedValue
						/>
						<OptionsInput
							setValue={handleOnChange}
							objectElement='notifiedBy'
							value={objectData.notifiedBy}
							options={notifiedByOptions.map(option => ({ value: option, label: t(option, { ns: 'naming' }) }))}
							title={t('notified_by', { ns: 'naming' })}
							automaticMappedValue
						/>
						<div className='my-1 ms-2'>
							<span>{t('partsNotSolved', { ns: 'naming' })}</span>
							<Switch
								id={'switch_new_user'}
								value={objectData.notSolvedParts}
								onChange={(e) => { handleOnChange('notSolvedParts', e.target.checked) }}
								className={'fs-3'} />
						</div>

						<DatePickr
							mode={'range'}
							format={'Y-m-d'}
							value={[objectData.creationDate?.startDate, objectData.creationDate?.endDate]}
							setValue={handleOnChangeDateRange}
							title={t('creationDate', { ns: 'naming' })}
							objectElement={'creationDate'}
							placeholder={t('start_end_date', { ns: 'naming' })}
							cleanData={resetDateRange}
						/>
					</div>
				}
			/>
		</div>
	)
}

FilterModal.propTypes = {
	getIncidencesList: PropTypes.func,
	problems: PropTypes.array,
	solutions: PropTypes.array,
}

const mapStateToProps = (state) => ({
	problems: state.Orders.qualityProblems,
	solutions: state.Orders.qualitySolutions,
})

const mapDispatchToProps = { getIncidencesList }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterModal))
