import { usePdfGenerator } from 'common/Hooks/UsePdfGenerator'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getS3FileName } from 'common/Utils/StringUtilities'
import { getCSVFile } from 'common/Utils/fileUtils'
import PrintLabelsModal from 'components/Common/ProductionLabels/PrintLabelsModal'
import { generateZipFile } from 'components/Common/ZipDownloader'
import { SupplierInvoicePDF } from 'components/PdfTemplates/SupplierInvoicePDF'
import { ProductionStatus } from 'constants/productionStatus'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { downloadPartListImagesInBase64, updateProduction } from 'store/actions'
import RejectProductionModal from './RejectProductionModal'

const ProductionButtons = ({
	t,
	i18n: { language },
	productionId,
	status,
	setStatus,
	setOpenFinishProductionModal,
	setOpenLabelsModal,
	updateProduction,
	productionItemList,
	orderId,
	orderFragmentId,
	supplierId,
	productionDetail,
	supplierAccount,
	supplierInfo,
	downloadPartListImagesInBase64,
	imagesInBase64,
	partConfigOptions,
}) => {

	const [pdfsToGenerate, setPdfsToGenerate] = useState([])
	const [zipFiles, setZipFiles] = useState()

	useEffect(() => {
		if (productionDetail != null) {
			const data = productionDetail.production.productionItemList.map((item) => ({
				id: item.part.id,
				imageUrl: item.part.imageUrl,
			}))
			downloadPartListImagesInBase64(data)
		}
	}, [productionDetail])

	useEffect(() => {
		if (imagesInBase64 != null && imagesInBase64.length != 0 && supplierAccount != null && supplierInfo != null) {
			productionDetail.production.productionItemList = productionDetail.production.productionItemList.map((item) => {
				const imageBase64 = imagesInBase64.find((image) => image.partId == item.part.id)?.imageBase64
				return {
					...item,
					part: {
						...item.part,
						imageBase64,
					},
				}
			})
			setPdfsToGenerate([{
				filename: `Production-${productionId}.pdf`,
				content: SupplierInvoicePDF({ production: productionDetail.production, supplier: { account: supplierAccount, supplierInfo }, t, partConfigOptions }),
			}])
		}
	}, [imagesInBase64, supplierAccount, supplierInfo])

	useEffect(() => {
		if (zipFiles != null) {
			generateZipFile(`Production-${productionId}`, zipFiles)
		}
	}, [zipFiles])

	const getSplitProductionId = (id) => {
		const splitId = id.split('-')
		return { orderId: splitId[0], orderFragmentId: splitId[1] }
	}
	const acceptProduction = () => {
		setStatus(ProductionStatus.IN_PROCESS)
		const { orderId, orderFragmentId } = getSplitProductionId(productionId)
		const production = {
			orderId: Number(orderId),
			orderFragmentId: Number(orderFragmentId),
			statusId: ProductionStatus.IN_PROCESS,
			productionResponseDate: new Date(),
		}
		updateProduction({ production })
	}
	const denyProduction = () => {
		setStatus(ProductionStatus.DENIED)
		const { orderId, orderFragmentId } = getSplitProductionId(productionId)
		const production = {
			orderId: Number(orderId),
			orderFragmentId: Number(orderFragmentId),
			statusId: ProductionStatus.DENIED,
		}
		updateProduction({ production })
	}

	const { elements, getPdfFiles } = usePdfGenerator({
		contentList: pdfsToGenerate,
	})

	const getZipFiles = async () => {
		const files = []
		const csvItems = []
		for (const productionItem of productionItemList) {
			const { part } = productionItem
			const { fileLinks } = part
			const blueprint = fileLinks.modifiedBlueprint ? fileLinks.modifiedBlueprint : fileLinks.originalBlueprint
			files.push(
				{
					file: fileLinks.stepModel,
					name: getS3FileName(fileLinks.stepModel),
				},
				{
					file: blueprint,
					name: getS3FileName(blueprint),
				},
			)
			csvItems.push({
				partId: part.id,
				name: part.name,
				step: getS3FileName(fileLinks.stepModel),
				blueprint: getS3FileName(blueprint),
				technology: PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language }),
				material: PartConfigTranslations.getMaterialName({ part, partConfigOptions, language }),
				alloy: PartConfigTranslations.getAlloyName({ part, partConfigOptions, language }),
				finishing: PartConfigTranslations.getFinishName({ part, partConfigOptions, language }),
				color: PartConfigTranslations.getColorName({ part, partConfigOptions, language }),
				colorFinishing: PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language }),
				quantity: productionItem.totalQuantity,
				unitPrice: productionItem.unitPurchasePrice,
				totalPrice: productionItem.totalPurchasePrice,
			})
		}
		const csvFile = getCSVFile(csvItems)
		const csvFileName = `parts_${productionId}.csv`
		files.push({
			file: csvFile,
			name: csvFileName,
		})
		const pdfFiles = await getPdfFiles()
		files.push({
			file: pdfFiles[0]?.file,
			name: pdfFiles[0]?.filename,
		})
		return files
	}

	return (
		<div className="d-flex flex-row justify-content-between align-items-center">
			<span>
				{status === ProductionStatus.ACCEPTANCE_PENDING && (
					<div className="d-flex flex-row">
						<button
							id="buttonAcceptProduction"
							type="button"
							className="btn btn-success w-auto"
							onClick={acceptProduction}
						>
							<div>
								<span className="d-flex flex-row">
									<i className="bx bx-check fs-4"></i>
									<p className="mx-1 my-0">{t('accept', { ns: 'naming' })}</p>
								</span>
							</div>
						</button>
						<RejectProductionModal
							orderId={orderId}
							orderFragmentId={orderFragmentId}
							supplierId={supplierId} />
					</div>
				)}
				{status === ProductionStatus.IN_PROCESS && (
					<span>
						<button
							type="button"
							className="btn btn-info mx-1 w-auto"
							onClick={() => setOpenFinishProductionModal(true)}
						>
							<div>
								<span className="d-flex flex-row">
									<i className="bx bxs-flag fs-4"></i>
									<p className="mx-1 my-0">{t('mark-production-as-finished', { ns: 'naming' })}</p>
								</span>
							</div>
						</button>
					</span>
				)}
			</span>
			<div className="d-flex flex-row align-items-center justify-content-center">
				{status > ProductionStatus.ACCEPTANCE_PENDING && status !== ProductionStatus.DENIED && (
					<PrintLabelsModal orderFragmentId={orderFragmentId} orderId={orderId} />
				)}
				<div>
					<button type="button" className="btn btn-pink mx-1 w-auto" onClick={async () => setZipFiles(await getZipFiles())}>
						<span className="d-flex flex-row">
							<i className="bx bxs-download fs-4 me-1"></i>
							<p className="mx-1 my-0">{t('download-zip', { ns: 'naming' })}</p>
						</span>
					</button>
				</div>
			</div>
			<div className='d-none'>{elements}</div>
		</div>
	)
}
const mapStateToProps = state => {
	return {
		productionDetail: state.Productions.productionDetail,
		supplierAccount: state.Users.account,
		supplierInfo: state.Users.supplierShippingTiming?.supplierInfo,
		imagesInBase64: state.CloudStorage.imagesInBase64,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}
ProductionButtons.propTypes = {
	t: PropTypes.func,
	productionId: PropTypes.string,
	status: PropTypes.number,
	setStatus: PropTypes.func,
	setOpenFinishProductionModal: PropTypes.func,
	setOpenLabelsModal: PropTypes.func,
	updateProduction: PropTypes.func,
	productionItemList: PropTypes.object,
	supplierId: PropTypes.number,
	orderId: PropTypes.number,
	orderFragmentId: PropTypes.number,
	productionDetail: PropTypes.object,
	supplierAccount: PropTypes.object,
	supplierInfo: PropTypes.object,
	downloadPartListImagesInBase64: PropTypes.object,
	imagesInBase64: PropTypes.array,
	partConfigOptions: PropTypes.object,
}
export default withRouter(
	connect(mapStateToProps, { updateProduction, downloadPartListImagesInBase64 })(
		withTranslation()(ProductionButtons),
	),
)
