import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export const PartName = ({
	name,
	maxLength,
	id,
}) => {

	const tooltipId = `part_${name}`
	const isLongName = name && name.length > (maxLength || 33)
	return (
		<div style={{ minHeight: '19.5px' }}>
			{name ?
				<div id={id} data-tip={name} data-for={tooltipId}>
					{!isLongName ? name : `${name.substring(0, (maxLength / 2 || 15))}...${name.substring(name.length - (maxLength / 2 || 15), name.length)}`}
					{isLongName && <ReactTooltip
						id={tooltipId}
						place="top"
						type="info"
						effect="solid"
						multiline={isLongName}
					/>}
				</div>
				: <div></div>
			}
		</div>
	)
}
PartName.propTypes = {
	name: PropTypes.string,
	maxLength: PropTypes.number,
}
