import { useZoomDetector } from 'common/Hooks/useZoomDetector'
import { useEffect, useState } from 'react'
import { ObjViewer } from './ObjViewer/ObjViewer'
import { StlViewer } from './StlViewer/StlViewer'

// TODO rename file to ResponsiveGeometryViewer
export const ResponsiveGeometryViewer = (props) => {
	const SIZE_MULTIPLIER = 0.6

	const [windowSize, setWindowSize] = useState({
		x: window.innerWidth * SIZE_MULTIPLIER,
		y: window.innerHeight * SIZE_MULTIPLIER,
	})

	const { zoomLevel } = useZoomDetector()

	const handleResize = () => {
		setWindowSize({
			x: window.innerWidth * SIZE_MULTIPLIER,
			y: window.innerHeight * SIZE_MULTIPLIER,
		})
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		setWindowSize({
			x: window.innerWidth * SIZE_MULTIPLIER,
			y: window.innerHeight * SIZE_MULTIPLIER,
		})
	}, [zoomLevel])

	const [extension, setExtension] = useState()

	useEffect(() => {
		setExtension(
			(props.modelSrc.toLowerCase().includes('.stl?') || props.extension == 'stl') ? 'stl' :
				(props.modelSrc.toLowerCase().includes('.obj?') || props.extension == 'obj') ? 'obj' :
					'unknown',
		)
	}, [props.modelSrc])

	return extension === 'stl' ? (
		<StlViewer
			modelSrc={props.modelSrc}
			windowSize={windowSize}
			partId={props.partId}
			size={props.size}
			weight={props.weight}
			hideData={props.hideData}
		/>
	) : extension === 'obj' ? (
		<ObjViewer
			modelSrc={props.modelSrc}
			windowSize={windowSize}
			partId={props.partId}
			size={props.size}
			weight={props.weight}
			hideData={props.hideData}
		/>
	) : (
		<>error</>
	)
}
