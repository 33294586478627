import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import Modal from 'components/Common/Modal/Modal'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import RegressionsGraphic from './RegressionsGraphic'
import RegressionsTable from './RegressionsTable'

const VcPartCostsModal = ({ partId, supplier, isOpen, closeModal, partCosts, partPrices, t }) => {
	const [currentPartData, setCurrentPartData] = useState({})
	const TABLE_CHART = 1
	const GRAPHIC_CHART = 2
	const CHART_OPTIONS = [{ label: t('table', { ns: 'naming' }), value: TABLE_CHART }, { label: t('graph', { ns: 'naming' }), value: GRAPHIC_CHART }]

	const [activeChart, setActiveChart] = useState(TABLE_CHART)

	useEffect(() => {
		if (partId != null && partCosts.length > 0 && partPrices.length > 0) {
			setCurrentPartData(getCurrentPartData(partId))
		}
	}, [partCosts.length, partPrices.length, partId])

	const isVcPart = currentPartData.ppm != null && currentPartData.mpm != null

	const getCurrentPartData = partId => {
		const partPrice = partPrices.find(partPrice => { return partPrice.partId === partId && partPrice.supplierId === supplier.id })
		const partCost = partCosts.find(partPrice => { return partPrice.partId === partId && partPrice.supplierId === supplier.id })
		const regressionItems = getRegressions(partPrice, partCost)
		return {
			mpm: partCost?.mpm,
			ppm: partCost?.ppm,
			regressionItems,
		}
	}

	const getRegressions = (partPrice, partCost) => {
		const regressions = []
		for (let i = 0; i < partPrice.regressionItems.length; i++) {
			const partTotalUnitCost = roundAtDecimals(partCost.regressionItems[i].totalCost / partCost.regressionItems[i].quantity, 2)
			regressions.push({
				quantity: partPrice.regressionItems[i].quantity,
				partCost: partCost.regressionItems[i].partCost,
				moldCost: partCost.regressionItems[i].moldCost ? roundAtDecimals(partCost.regressionItems[i].moldCost, 2) : 0,
				masterCost: partCost.regressionItems[i].masterCost ? roundAtDecimals(partCost.regressionItems[i].masterCost, 2) : 0,
				partTotalUnitCost,
				partTotalCost: roundAtDecimals(partCost.regressionItems[i].totalCost, 2),
				margin: roundAtDecimals(partPrice.regressionItems[i].price / partTotalUnitCost, 2),
				unitPrice: roundAtDecimals(partPrice.regressionItems[i].price, 2),
				totalPrice: roundAtDecimals(partPrice.regressionItems[i].price * partPrice.regressionItems[i].quantity, 2),
				time: partPrice.regressionItems[i].time,
			})
		}
		return regressions

	}

	return (
		<Modal
			isOpen={isOpen}
			title="Part Costs"
			closeModal={() => closeModal(false)}
			className="largeModal"
			body={
				<div className="d-flex flex-column p-4">
					{currentPartData?.regressionItems?.length > 0 ? (

						<div>

							<div className="d-flex flex-row mb-2">
								<h5>{t('part', { ns: 'naming' })}: {partId}</h5>
								{isVcPart && <h5 className="mx-3">{t('ppm', { ns: 'naming' })}: {currentPartData.ppm}</h5>}
								{isVcPart && <h5>{t('mpm', { ns: 'naming' })}: {currentPartData.mpm}</h5>}
							</div>
							<h5>{t('supplier', { ns: 'naming' })}: {supplier?.name}</h5>
							<div className='d-flex justify-content-end me-4 mb-4'>
								<OptionsSelector options={CHART_OPTIONS} setValue={setActiveChart} value={activeChart} />
							</div>
							{
								activeChart === TABLE_CHART && <RegressionsTable id="regressionsTable" isVcPart={isVcPart} currentPartData={currentPartData} />
							}
							{
								activeChart === GRAPHIC_CHART && <RegressionsGraphic regressionItems={currentPartData.regressionItems} />
							}

						</div>
					) : (
						<h5>{t('noPartCosts', { ns: 'naming' })}</h5>
					)}
				</div>
			}
			buttons={[]}
		/>
	)
}

VcPartCostsModal.propTypes = {
	partId: PropTypes.number,
	supplier: PropTypes.array,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partCosts: PropTypes.array,
	partPrices: PropTypes.array,
	t: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		partCosts: state.Quotes.partCosts,
		partPrices: state.Quotes.partPrices,
	}
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(VcPartCostsModal)))
