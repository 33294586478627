import { formatCurrencyWithSeparators, roundCurrency } from "common/Utils/NumberUtilities"
import { getDeliveryNoteSerial } from "common/Utils/StringUtilities"
import { IconTooltip } from "components/Common/IconTooltip"
import Loader from "components/Common/Loader"
import Modal from "components/Common/Modal/Modal"
import PartThumbnail from "components/Common/PartThumbnail"
import { getPdfBlob } from "components/Common/PrintableContent/GetPdfBlob"
import SimpleTable from "components/Common/SimpleTable"
import { htmlToPdfRemit } from "components/PdfTemplates/RemitPDFTemplate"
import { OrderStatus } from "constants/orderStatus"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import { createRemit, updateOrder, uploadOrderDeliveryNote } from "store/actions"

const NewRemitModal = ({ t, orderId, orderItems, orderInfo, isOpen, close, createRemit, remit, organization, uploadOrderDeliveryNote, deliveryNote, updateOrder }) => {

	const [partQuantities, setPartQuantities] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const [remitNumber, setRemitNumber] = useState(undefined)
	const [isCompleteRemit, setIsCompleteRemit] = useState(false)

	const width = 210
	const height = 297
	const orientation = 'p'
	const id = 'remitPrint'
	const filename = useMemo(() => {
		if (remit == null) return undefined
		return `AB_${getDeliveryNoteSerial(remit.creationDate, remit.serialNumber)}_${orderInfo?.billingInfo?.companyName}`
	}, [remit])

	const options = {
		height,
		width,
		printPageCounter: true,
		printDocumentFooter: true,
		jsPDF: {
			format: [width, height],
			unit: 'mm',
			orientation,
			hotfixes: ['px_scaling'],
		},
		margin: 0,
		html2canvas: { scale: 8 },
		filename: filename,
	}


	useEffect(() => {
		if (orderItems && orderItems.length > 0) {
			let partQuantitiesToUpdate = {}
			orderItems.forEach(orderItem => {
				if (partQuantities[orderItem.part.id] == undefined) {
					partQuantitiesToUpdate = {
						...partQuantitiesToUpdate,
						[orderItem.part.id]: 0
					}
				}
			})
			setPartQuantities({ ...partQuantities, ...partQuantitiesToUpdate })
		}
	}, [orderItems])

	const setCompleteRemitQuantities = () => {
		if (orderItems && orderItems.length > 0) {
			let partQuantitiesToUpdate = {}
			orderItems.forEach(orderItem => {
				partQuantitiesToUpdate = {
					...partQuantitiesToUpdate,
					[orderItem.part.id]: orderItem.quantity
				}
				const quantityInput = document.getElementById(`selectedQuantityPart${orderItem.part.id}`)
				if (quantityInput) quantityInput.value = orderItem.quantity
			})
			setPartQuantities(partQuantitiesToUpdate)
			setIsCompleteRemit(true)
		}
	}

	const generateHtmlToPrint = () => {
		if (remit == null) return null
		let subtotal = 0
		const remitItems = remit.items.map((item) => {
			const orderItem = orderItems.find((orderItem) => orderItem.id == item.orderItemId)
			const unitPrice = orderItem.total / orderItem.quantity
			subtotal += unitPrice * item.quantity
			return {
				name: orderItem.part.name,
				units: -item.quantity,
				unitPrice: formatCurrencyWithSeparators(unitPrice),
				totalPrice: `-${formatCurrencyWithSeparators(unitPrice * item.quantity)}`
			}
		})
		if (isCompleteRemit) {
			if (orderInfo.feeApplied && orderInfo.feeApplied != 0) {
				remitItems.push({
					name: "Minimum Fee",
					units: -1,
					unitPrice: formatCurrencyWithSeparators(orderInfo.feeApplied),
					totalPrice: `-${formatCurrencyWithSeparators(orderInfo.feeApplied)}`
				})
			}
			if (orderInfo.shipmentPricing && orderInfo.shipmentPricing != 0) {
				remitItems.push({
					name: "Shipment Price",
					units: -1,
					unitPrice: formatCurrencyWithSeparators(orderInfo.shipmentPricing),
					totalPrice: `-${formatCurrencyWithSeparators(orderInfo.shipmentPricing)}`
				})
			}
			if (orderInfo.discountApplied && orderInfo.discountApplied != 0) {
				remitItems.push({
					name: "Discount",
					units: -1,
					unitPrice: `-${formatCurrencyWithSeparators(orderInfo.discountApplied)}`,
					totalPrice: formatCurrencyWithSeparators(orderInfo.discountApplied)
				})
			}
			return htmlToPdfRemit(remitItems, { organization: { id: orderInfo.organizationId }, order: { ...orderInfo } }, remit, organization, t)
		}
		const taxesApplied = orderInfo?.shippingAddress?.country?.toUpperCase() == "ES" ? subtotal * 0.21 : 0
		const totalPrice = subtotal + taxesApplied
		return htmlToPdfRemit(remitItems, { organization: { id: orderInfo.organizationId }, order: { ...orderInfo, subtotal, taxesApplied, totalPrice } }, remit, organization, t)
	}

	useEffect(async () => {
		if (remit) {
			const pdf = await getPdfBlob(id, options)
			const url = URL.createObjectURL(pdf)
			const link = document.createElement('a')
			link.href = url
			link.download = filename + '.pdf'
			link.click()
			setTimeout(() => {
				URL.revokeObjectURL(url)
			}, 100)
			setIsLoading(false)
			uploadOrderDeliveryNote({ data: { orderId }, deliveryNote: pdf })
		}
	}, [remit])

	useEffect(() => {
		if (deliveryNote && isCompleteRemit) {
			const orderToUpdate = {
				id: orderId,
				statusId: OrderStatus.REFUNDED
			}
			updateOrder({ data: { order: orderToUpdate } })
		}
	}, [deliveryNote])

	const updatePartQuantity = (partId, quantity) => {
		setPartQuantities({
			...partQuantities,
			[partId]: quantity
		})
	}

	const header = ["id", "part", "name", "price", "quantity", "total"]
	const getTableBody = () => {
		return orderItems.map((orderItem) => {
			const stlData = {
				partId: orderItem.part.id,
				partName: orderItem.part.name,
				size: orderItem.part.size,
				weight: orderItem.part.weight,
			}
			const unitPrice = roundCurrency(orderItem.total / orderItem.quantity)
			return <tr key={`part${orderItem.part.id}`} valign="middle">
				<td className="col-2">{orderItem.part.id}</td>
				<td className="col-2">
					<PartThumbnail geometryData={stlData} propsGeometryUrl={orderItem.part.fileLinks?.stlModel || orderItem.part.fileLinks?.objModel} propsImageUrl={orderItem.part?.imageUrl} />
				</td>
				<td className="col-5">{orderItem.part.name}</td>
				<td className="col-1">{`${unitPrice}€`}</td>
				<td className="col-1"><input
					id={`selectedQuantityPart${orderItem.part.id}`}
					className="form-control"
					type="number"
					defaultValue={0}
					min={0}
					max={orderItem.quantity}
					onChange={e => {
						e.target.value = e.target.value.split('.')[0]
						let quantity = parseInt(e.target.value)
						if (isNaN(quantity)) {
							e.target.value = ''
							quantity = 0
						} else if (quantity > orderItem.quantity) {
							e.target.value = orderItem.quantity
							quantity = orderItem.quantity
						} else {
							e.target.value = quantity
						}
						updatePartQuantity(orderItem.part.id, quantity)
					}}
					onBlur={() => {
						const value = document.getElementById(`selectedQuantityPart${orderItem.part.id}`)?.value
						if (!value) {
							document.getElementById(`selectedQuantityPart${orderItem.part.id}`).value = '0'
						}
					}}
				/></td>
				<td className="col-1">{`${roundCurrency(partQuantities[orderItem.part.id] * unitPrice)}€`}</td>
			</tr>
		})
	}

	const generateRemit = () => {
		setIsLoading(true)
		const items = []
		for (const [key, value] of Object.entries(partQuantities)) {
			if (value != 0) items.push({
				partId: Number(key),
				quantity: value
			})
		}
		const data = {
			orderId,
			customNumber: remitNumber,
			items
		}
		createRemit(data)
	}

	const remitHtmlToPrint = useMemo(() => generateHtmlToPrint(), [remit])

	return <>
		<div>
			<div id={id} className='d-none'>
				{remitHtmlToPrint}
			</div>
		</div>
		<Modal
			title={`${t("new-remit", { ns: "naming" })} - ${t("order", { ns: "naming" })} ${orderId}`}
			isOpen={isOpen}
			closeModal={() => close()}
			className="modal-dialog-centered"
			size="xl"
			body={
				<div className="m-2">
					<SimpleTable
						key={'newRemitPartsTable'}
						header={header}
						getTableBody={() => getTableBody()}
						striped
					/>
					<div className="d-flex flex-row justify-content-between">
						<div className="d-flex flex-row align-items-center">
							<div className="w-auto">{`${t("serial-number", { ns: "naming" })}:`}</div>
							<IconTooltip icon="bx bxs-info-circle me-2 text-primary" message={t('request-remit-serial', { ns: 'naming' })} position="top" name={"RemitSerialNumber"} />
							<input
								id={`RemitNumber${orderId}`}
								className="form-control w-auto"
								type="number"
								value={remitNumber}
								onChange={(e) => {
									e.target.value = e.target.value.split('.')[0]
									let customNumber = parseInt(e.target.value)
									if (isNaN(customNumber)) {
										e.target.value = ''
										customNumber = undefined
									}
									setRemitNumber(customNumber)
								}}
							/>
						</div>
						<button
							type="button"
							key="btnRemit"
							className="btn btn-primary w-auto my-auto"
							onClick={() => setCompleteRemitQuantities()}
						>
							{t('complete-remit', { ns: 'naming' })}
						</button>
					</div>
				</div>
			}
			buttons={[
				<button
					type="button"
					key="btnRemit"
					className="btn btn-primary"
					onClick={() => generateRemit()}
					disabled={Object.values(partQuantities).reduce((a, b) => a + b, 0) === 0 || remitNumber == undefined}
				>
					{isLoading ? <Loader className="mx-auto" /> : t('generate', { ns: 'naming' })}
				</button>
			]}
		/>
	</>
}

const mapStateToProps = state => {
	return {
		remit: state.Orders.remit,
		organization: state.Users.organization,
		deliveryNote: state.CloudStorage.deliveryNote
	}
}

NewRemitModal.propTypes = {
	t: PropTypes.func,
	orderId: PropTypes.number,
	orderItems: PropTypes.array,
	orderInfo: PropTypes.object,
	isOpen: PropTypes.bool,
	close: PropTypes.func,
	createRemit: PropTypes.func,
	remit: PropTypes.object,
	organization: PropTypes.object,
	uploadOrderDeliveryNote: PropTypes.func,
	deliveryNote: PropTypes.object
}

export default withRouter(connect(mapStateToProps, { createRemit, uploadOrderDeliveryNote, updateOrder })(
	withTranslation()(NewRemitModal),
))
