import Modal from 'components/Common/Modal/Modal'
import PartCard from 'components/Common/PartCard/PartCard'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const UploadModal = ({
	isUploadModalOpen,
	closeUploadModal,
	saveAndCloseUploadModal,
	selectedRows,
	getSelectedTabSupplier,
	hasAllSelectedRowsHaveAValidSupplier,
	t,
}) => {
	return (
		<Modal
			className="upload-modal"
			isOpen={isUploadModalOpen}
			closeModal={closeUploadModal}
			title={t('uploadPart', { ns: 'naming' })}
			body={
				<div>
					<div className="w-100 m-2">
						{selectedRows.length > 0 && hasAllSelectedRowsHaveAValidSupplier && (
							<div>
								<h5>Las siguientes piezas serán publicadas</h5>
								<div className="d-flex flex-wrap gap-03">
									{selectedRows.map(row => {
										return (
											<PartCard
												key={row.id.value}
												img={row.fileLinks.thumbnail}
												stl={row.fileLinks.stlModel}
												stlData={{
													partId: row.id.value,
													partName: row.name,
													size: row.size,
													weight: row.weight,
												}}
												partId={row.id.value}
												fileName={row.name}
												materialColorFinish={(
													<>
														{row.material}
														{row.finishing}
													</>
												)}
												technology={row.technology}
												supplierName={getSelectedTabSupplier(row.id.value).name}
											/>
										)
									})}
								</div>
							</div>
						)}
						{selectedRows.length == 0 && <div className="modal-body">{t('no_parts_selected_upload', { ns: 'naming' })}</div>}
						{!hasAllSelectedRowsHaveAValidSupplier && <div className="modal-body">{t('no_valid_quotes_upload', { ns: 'naming' })}</div>}
					</div>
				</div>
			}
			buttons={
				(selectedRows.length > 0 &&
					hasAllSelectedRowsHaveAValidSupplier && [
						<button type="button" key="btn3" className="btn btn-primary" onClick={() => saveAndCloseUploadModal()}>
							{t('upload', { ns: 'naming' })}
						</button>,
					]) || []
			}
		/>
	)
}

UploadModal.propTypes = {
	isUploadModalOpen: PropTypes.bool,
	closeUploadModal: PropTypes.func,
	saveAndCloseUploadModal: PropTypes.func,
	selectedRows: PropTypes.array,
	hasAllSelectedRowsHaveAValidSupplier: PropTypes.bool,
	getSelectedTabSupplier: PropTypes.func,
	t: PropTypes.func,
}

export default withTranslation()(UploadModal)
