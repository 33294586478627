import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import Modal from 'components/Common/Modal/Modal'
import OptionsButtons from 'components/Common/OptionsButtons'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Flag, Loader } from 'semantic-ui-react'
import { sendUploadOfferEmail } from 'store/actions'

const SendUploadEmailModal = ({
	isSendUploadEmailModalOpen,
	closeSendUploadEmailModal,
	customer,
	organization,
	offerId,
	setModalMessage,
	sendUploadOfferEmail,
	managers,
	emailSuccess,
	isLoading,
	error,
	t,
}) => {

	const [selectedLanguage, setSelectedLanguage] = useState()
	const [message, setMessage] = useState('')

	useEffect(() => {
		const languages = ['es', 'fr', 'en', 'it']
		if (languages.includes(customer?.personalInformation?.language)) {
			setSelectedLanguage(customer?.personalInformation?.language)
		} else {
			setSelectedLanguage('en')
		}
	}, [customer])

	useEffect(() => {
		if (emailSuccess) {
			setMessage('')
			setModalMessage(t('update_success', { ns: 'naming' }))
		} else if (error) {
			setModalMessage(t('error-email', { ns: 'errors' }))
		}
	}, [emailSuccess, error])

	const options = [
		{
			value: 'es',
			text: <Flag name="es" />,
			color: 'warning',
		},
		{
			value: 'fr',
			text: <Flag name="fr" />,
			color: 'warning',
		},
		{
			value: 'en',
			text: <Flag name="uk" />,
			color: 'warning',
		},
		{
			value: 'it',
			text: <Flag name="it" />,
			color: 'warning',
		},
	]

	const translationArray = [
		{
			lang: 'es',
			message: `Oferta ${offerId} Publicada`,
		},
		{
			lang: 'fr',
			message: `Offre ${offerId} Publiée`,
		},
		{
			lang: 'en',
			message: `Offer ${offerId} Published`,
		},
		{
			lang: 'it',
			message: `Offerta ${offerId} Pubblicato`,
		},
	]

	const getManagerData = (manager) => {
		return {
			name: `${manager.personalInformation.firstName} ${manager.personalInformation.lastName}`, email: manager.loginCredentials.email,
		}
	}

	const handleSendEmail = () => {
		const managerAccount = getUserValuesFromLocalStorage()
		const loggedManager = managers.find((manager) => manager.loginCredentials.email == managerAccount.email)
		const customerAsignedManager = managers.find((manager) => manager.id == organization.managerId)
		const data = {
			customer: customer,
			language: selectedLanguage,
			message: message,
			offerId: offerId,
			manager: loggedManager ? getManagerData(loggedManager) : getManagerData(customerAsignedManager),

		}
		sendUploadOfferEmail(data)
	}

	const translateSubject = () => {
		const translation = translationArray.find(e => e.lang == selectedLanguage)
		if (translation) return translation.message
		return ''
	}

	return (
		<Modal
			className="modal-lg"
			isOpen={isSendUploadEmailModalOpen}
			closeModal={closeSendUploadEmailModal}
			title={t('send-upload-email', { ns: 'naming' })}
			body={
				<div>
					<div className="m-3">
						<div className="my-2">
							<OptionsButtons
								options={options}
								valueSelected={selectedLanguage}
								handleOnChange={setSelectedLanguage}
								propsClassName="py-1 ps-2 mx-1"
							/>
						</div>
						<div>
							{t('recipient', { ns: 'naming' })}:
						</div>
						<div>
							<input
								key="recipientInput"
								className="form-control"
								disabled
								value={customer?.loginCredentials?.email}
							>
							</input>
						</div>
						<div>
							{t('subject', { ns: 'naming' })}:
						</div>
						<div>
							<input
								key="subjectInput"
								className="form-control"
								disabled
								value={translateSubject()}
							>
							</input>
						</div>
						<div>
							{t('message', { ns: 'naming' })}: ({t('added-to-email-template', { ns: 'naming' })})
						</div>
						<div>
							<textarea
								key="messageInput"
								className="form-control"
								rows={5}
								value={message}
								onChange={e => setMessage(e.target.value)}
							></textarea>
						</div>
					</div>
				</div>
			}
			buttons={[
				<button id="sendEmailButton" type="button" key="sendEmailButton" className="btn btn-primary" disabled={isLoading} onClick={() => handleSendEmail()}>
					{isLoading ? <Loader /> : t('send-email', { ns: 'naming' })}
				</button>,
			]}
		/>
	)
}

const mapStateToProps = state => {
	return {
		managers: state.Users.managers,
		emailSuccess: state.Emails.success,
		isLoading: state.Emails.isLoading,
		error: state.Emails.error,
	}
}

SendUploadEmailModal.propTypes = {
	isSendUploadEmailModalOpen: PropTypes.bool,
	closeSendUploadEmailModal: PropTypes.func,
	customer: PropTypes.object,
	offerId: PropTypes.number,
	setModalMessage: PropTypes.func,
	sendUploadOfferEmail: PropTypes.func,
	managers: PropTypes.array,
	emailSuccess: PropTypes.bool,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
	t: PropTypes.func,
}

export default connect(mapStateToProps, { sendUploadOfferEmail })(withTranslation()(SendUploadEmailModal))
