import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useObjectState } from 'common/Hooks/UseObjectState'
import { mappedSemaphoreFilterOptions } from 'common/Utils/ArrayUtilities'
import { getPostponedPaymentMethodsKeyValuePair } from 'common/Utils/PaymentMethodUtils'
import { getOrderStatusKeyValuePair } from 'common/Utils/StatusUtils'
import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SearchInput from 'components/Common/SearchInput'
import Switch from 'components/Common/Switch/Switch'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getFilteredOrders } from 'store/actions'

const INIT_STATE = {
	availableStatus: [],
	availableCountries: [],
	availablePaymentMethods: [],
	orderDate: { startDate: null, endDate: null },
	shipmentDate: { startDate: null, endDate: null },
	onlyNewUsers: false,
	onlyLateOrders: false,
}

export const OrderFiltersModal = ({ getFilteredOrders, t, pageSize, currentPage, setCurrentPage, semaphoreFilter, setSemaphoreFilter, resetSemaphoreFilter }) => {

	const { objectData, handleOnChange, handleOnChangeDateRange, resetState, resetDateRange } = useObjectState(INIT_STATE)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [keyword, setKeyword] = useState('')

	const pagination = {
		page: currentPage,
		pageSize,
	}

	const inputBody = {
		data: {
			keyword,
			pagination,
			filters: objectData,
		},
	}
	const getFilteredOrdersHandler = useHandleCancelableApi(getFilteredOrders, inputBody, 500, true)
	const getOrdersHandler = useHandleCancelableApi(getFilteredOrders, { data: { keyword: '', pagination } }, 0)

	const handleGetOrders = () => {
		getFilteredOrdersHandler.handleApiCall()

	}

	useEffect(() => {
		handleGetOrders()
	}, [pageSize, currentPage])

	useEffect(() => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			handleGetOrders()
		}
	}, [keyword])

	const handelApplyFilters = () => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			handleGetOrders()
		}
		setIsModalOpen(false)
	}

	const handleResetFilters = () => {
		resetState()
		setKeyword('')
		resetSemaphoreFilter()
		setIsModalOpen(false)
		getFilteredOrdersHandler.cancelApiCall()
		getOrdersHandler.handleApiCall()
	}

	return (
		<div className='d-flex'>
			<div className="position-relative me-3">
				<SearchInput onChange={setKeyword} value={keyword} />
			</div>
			<div>
				<button className='btn btn-secondary' onClick={() => { setIsModalOpen(true) }}>{t('filter', { ns: 'naming' })}</button>
				<Modal
					title={t('filterOrders', { ns: 'naming' })}
					buttons={[
						<button onClick={() => { handleResetFilters() }} key='reset_production_filters' className='btn btn-primary'>{t('reset', { ns: 'naming' })}</button>,
						<button onClick={() => { handelApplyFilters() }} key='apply_production_filters' className='btn btn-primary'>{t('apply', { ns: 'naming' })}</button>,
					]}
					body={
						<div className='m-4'>
							<OptionsInput
								isMulti
								value={objectData.availableStatus}
								setValue={handleOnChange}
								objectElement='availableStatus'
								title={t('status', { ns: 'naming' })}
								options={getOrderStatusKeyValuePair(t)}
								automaticMappedValue
							/>

							<div className='my-1 '>
								<span className='ms-2'>{t('countries', { ns: 'naming' })}</span>
								<CountryFlag
									isEditable
									onChange={(e) => handleOnChange('availableCountries', e.map(country => country.value))}
									isMulti
									automaticMappedValue
									countryState={objectData.availableCountries}
								/>
							</div>
							<OptionsInput
								isMulti
								value={objectData.availablePaymentMethods}
								setValue={handleOnChange}
								objectElement='availablePaymentMethods'
								title={t('paymentMethod', { ns: 'naming' })}
								options={getPostponedPaymentMethodsKeyValuePair(t)}
								automaticMappedValue
							/>
							<div className='my-1 ms-2'>
								<span>{t('new-organization', { ns: 'naming' })}</span>
								<Switch
									id={'switch_new_user'}
									value={objectData.onlyNewUsers}
									onChange={(e) => { handleOnChange('onlyNewUsers', e.target.checked) }}
									className={'fs-3'} />
							</div>
							<div className='my-1 ms-2'>
								<span>{t('late', { ns: 'naming' })}</span>
								<Switch
									id={'switch_late'}
									value={objectData.onlyLateOrders}
									onChange={(e) => { handleOnChange('onlyLateOrders', e.target.checked) }}
									className={'fs-3'} />
							</div>
							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.orderDate?.startDate, objectData.orderDate?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('order_date', { ns: 'naming' })}
								objectElement={'orderDate'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>
							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.shipmentDate?.startDate, objectData.shipmentDate?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('shipmentDate', { ns: 'naming' })}
								objectElement={'shipmentDate'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>
							<OptionsInput
								isMulti
								value={semaphoreFilter}
								setValue={setSemaphoreFilter}
								objectElement='shipmentDateSemaphoreFilter'
								title={t('order_semaphore_filter', { ns: 'naming' })}
								options={mappedSemaphoreFilterOptions(t)}
								automaticMappedValue
							/>
						</div>
					}
					isOpen={isModalOpen}
					closeModal={() => { setIsModalOpen(false) }}
				/>
			</div>
		</div>
	)
}

OrderFiltersModal.propTypes = {
	getFilteredOrders: PropTypes.func,
	t: PropTypes.func,
	pageSize: PropTypes.number,
	currentPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	semaphoreFilter: PropTypes.array,
	setSemaphoreFilter: PropTypes.func,
	resetSemaphoreFilter: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
	getFilteredOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderFiltersModal))
