import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { formatWeight, roundCurrency } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import { getFullPartDescription } from 'common/Utils/PartUtils'
import { getDate, getDateWithHour } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import DownloadFilesButtons from 'components/Common/DownloadFilesButtons'
import Loader from 'components/Common/Loader'
import PartThumbnail from 'components/Common/PartThumbnail'
import { NoteTooltip } from 'components/NoteTooltip'
import { PAYMENT_METHOD } from 'constants/paymentMethods'
import { ProductionStatus } from 'constants/productionStatus'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { getOrderDetail, getQualityProblems, getQualitySolutions, getSuppliers } from 'store/actions'
import NewInvoiceModal from './NewInvoiceModal'
import NewRemitModal from './NewRemitModal'
import OrderActions from './OrderActions'
import OrderClientData from './OrderClientData'
import OrderSummary from './OrderSummary'
import OrderTable from './OrderTable'
import OrderTabs from './OrderTabs'

const OrderDetail = ({
	t,
	i18n: { language },
	isLoading,
	getOrderDetail,
	orderDetail,
	suppliers,
	getSuppliers,
	partConfigOptions,
	getQualityProblems,
	getQualitySolutions,
}) => {

	const [isRemitModalOpen, setIsRemitModalOpen] = useState(false)
	const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)

	useEffect(() => {
		const orderId = GetOrderIdFromURL()
		getOrderDetail(orderId)
		if (suppliers?.length === 0) {
			getSuppliers()
		}
	}, [])

	useEffect(() => {
		getQualityProblems()
		getQualitySolutions()
	}, [])
	const PAYMENT_METHOD_TYPE = [
		{
			value: PAYMENT_METHOD.WIRE_TRANSFER,
			name: t('wireTransfer', { ns: 'naming' }),
		},
		{
			value: PAYMENT_METHOD.DEBIT_CREDIT_CARD,
			name: t('debitCreditCard', { ns: 'naming' }),
		},
		{
			value: PAYMENT_METHOD.POSTPONED,
			name: t('postponedPayment', { ns: 'naming' }),
		},
	]

	const getOrderWeight = () => {
		if (orderDetail?.order?.orderItems == null || orderDetail?.order?.orderItems.length === 0) return null
		return orderDetail.order.orderItems.reduce(
			(accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.part.weight, 0,
		)
	}

	const getOrderItemList = () => {
		if (orderDetail?.order?.orderItems == null) {
			return []
		}
		const orderItemList = []
		let item = 0
		for (const orderItem of orderDetail.order.orderItems) {
			const stlData = {
				partId: orderItem.part.id,
				partName: orderItem.part.name,
				size: orderItem.part.size,
				weight: orderItem.part.weight,
			}
			orderItemList.push({
				item: { value: ++item },
				id: { value: orderItem.part.id },
				part: <PartThumbnail geometryData={stlData} propsGeometryUrl={orderItem?.part?.fileLinks?.stlModel || orderItem?.part?.fileLinks?.objModel} propsImageUrl={orderItem?.part?.files?.image} />,
				technology: (
					<div className={getClassnameByTechnology({ part: orderItem.part })}>
						{PartConfigTranslations.getTechnologyName({ part: orderItem.part, partConfigOptions, language })}
					</div>
				),
				files: <DownloadFilesButtons part={orderItem?.part} />,

				description: {
					value: {
						name: orderItem.part.name,
						title: getFullPartDescription(orderItem.part, partConfigOptions, language),
					},
				},

				price: {
					value: `${roundCurrency(orderItem.total / orderItem.quantity)}€`,
				},
				quantity: { value: orderItem.quantity },
				total: { value: `${roundCurrency(orderItem.total)}€` },
				weight: formatWeight(orderItem.quantity * orderItem.part.weight),
				comment: <NoteTooltip note={orderItem.part.comment} t={t} isBlack={true} />,
				offer: { value: orderItem.part.offerId },
			})
		}
		return orderItemList
	}

	const getShipmentTotalPrice = (orderDetail) => {
		let shipmentTotalPrice = 0
		orderDetail.order.productions.forEach((item) => {
			if (![
				ProductionStatus.ACCEPTANCE_PENDING,
				ProductionStatus.CANCELLED,
				ProductionStatus.DENIED,
			].includes(item.statusId) && item.shipmentPrice) {

				shipmentTotalPrice += item.shipmentPrice

			}

		})
		return roundCurrency(shipmentTotalPrice)
	}

	const calculateTotalWeight = (orderDetail) => {
		let totalWeight = 0
		orderDetail.order.orderItems.forEach((item) => {
			totalWeight += item.part.weight * item.quantity
		})

		return formatWeight(totalWeight)
	}

	const orderItemList = useMemo(getOrderItemList, [orderDetail])
	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('order-detail', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('management', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('orders', { ns: 'naming' }),
						link: '/orders',
					},
					{
						item: t('order-detail', { ns: 'naming' }),
					},
				]}
			/>
			{isLoading && orderDetail.order == null &&
				<Loader className="font-size-80 mx-auto" />
			}
			{!isLoading && !orderDetail.order &&
				<Alert
					type="danger"
					centered
					fitContent
					message={t('unknownMessage', { ns: 'errors' })}
				/>
			}
			{orderDetail.order != undefined &&
				<div>
					<div className="d-flex flex-row justify-content-between">
						<div className="col bg-white p-3 mr-4">
							<OrderSummary
								productions={orderDetail.order.productions}
								orderId={orderDetail.order.id}
								orderName={orderDetail.order.customerComment}
								orderNumber={orderDetail.order.customerOrderNumber}
								orderDate={getDateWithHour(orderDetail.order.creationDate)}
								partsPrice={roundCurrency(orderDetail.order.partsPricing) || '-'}
								fee={roundCurrency(orderDetail.order.feeApplied) || '-'}
								shipmentPrice={roundCurrency(orderDetail.order.shipmentPricing) || '-'}
								discount={roundCurrency(orderDetail.order.discountApplied) || '-'}
								vouchers={orderDetail.order.vouchers}
								subtotal={roundCurrency(orderDetail.order.subtotal) || '-'}
								taxes={roundCurrency(orderDetail.order.taxesApplied) || '-'}
								totalPrice={roundCurrency(orderDetail.order.totalPricing) || '-'}
								paymentMethod={PAYMENT_METHOD_TYPE.find(e => e.value === orderDetail.order.paymentMethodId)?.name}
								productionPeriod={orderDetail.order.productionDays}
								estimatedShippingDate={orderDetail.order.estimatedShipmentDate && getDate(orderDetail.order.estimatedShipmentDate)}
								realShippingDate={orderDetail.order.deliveryDate && getDate(orderDetail.order.deliveryDate)}
								totalImportCost={getShipmentTotalPrice(orderDetail)}
								totalWeight={calculateTotalWeight(orderDetail)}
							/>
						</div>
						{orderDetail.order.statusId && (
							<div className="col bg-white p-3 mx-4">
								<OrderActions
									orderId={orderDetail.order.id}
									paymentDate={orderDetail.order.paymentDate}
									paymentMethod={orderDetail.order.paymentMethodId}
									orderStatus={orderDetail.order.statusId}
									orderItems={orderDetail.order.orderItems}
								/>
							</div>
						)}
						<div className="col bg-white p-3 ml-4">
							<OrderClientData
								orderId={orderDetail.order.id}
								name={`${orderDetail.account.personalInformation?.firstName || '-'} ${orderDetail.account.personalInformation?.lastName || '-'}`}
								organization={orderDetail.organization.organizationName || '-'}
								vat={orderDetail.organization.vat || '-'}
								address={orderDetail.order.billingInfo?.address1 || '-'}
								postalCode={orderDetail.order.billingInfo?.postalCode || '-'}
								city={orderDetail.order.billingInfo?.city || '-'}
								province={orderDetail.order.billingInfo?.province || '-'}
								country={orderDetail.order.billingInfo?.country || '-'}
								email={orderDetail.order.billingInfo?.email || '-'}
								phone={(orderDetail.order.billingInfo?.phonePrefix || '-') + ' ' + (orderDetail.order.billingInfo?.phoneNumber || '-')}
								shippingName={orderDetail.order.shippingAddress?.firstName}
								shippingLastName={orderDetail.order.shippingAddress?.lastName}
								shippingOrganization={orderDetail.order.shippingAddress?.company || '-'}
								shippingAddress={orderDetail.order.shippingAddress?.address1 || '-'}
								shippingPostalCode={orderDetail.order.shippingAddress?.postalCode || '-'}
								shippingCity={orderDetail.order.shippingAddress?.city || '-'}
								shippingProvince={orderDetail.order.shippingAddress?.state || '-'}
								shippingCountry={orderDetail.order.shippingAddress?.country || '-'}
								shippingEmail={orderDetail.order.shippingAddress?.email || '-'}
								shippingPrefix={orderDetail.order.shippingAddress?.phonePrefix || '-'}
								shippingPhone={orderDetail.order.shippingAddress?.phoneNumber || '-'}
								accountId={orderDetail.account.id}
								orderWeight={getOrderWeight()}
							/>
						</div>
					</div>
					<div className="bg-white">
						<OrderTabs
							orderId={orderDetail.order.id}
							organizationId={orderDetail.order.organizationId}
							clientNote={orderDetail.order.comment}
							commentsList={orderDetail.order.internalComment}
							qualityIssuesList={orderDetail.order.qualityIssues}
							interactionList={orderDetail.order.interactions}
							productions={orderDetail.order.productions}
							shipments={orderDetail.order.shipments}
							deliveryNotes={orderDetail.order.deliveryNotes}
							suppliers={suppliers}
							openRemitModal={() => setIsRemitModalOpen(true)}
							openInvoiceModal={() => setIsInvoiceModalOpen(true)}
							orderItemList={orderItemList}
							orderDetail={orderDetail.order}
						/>
					</div>
					<div className="bg-white">
						<OrderTable orderItemList={orderItemList} />
					</div>
					<NewRemitModal
						orderId={orderDetail.order.id}
						orderItems={orderDetail.order.orderItems}
						orderInfo={orderDetail.order}
						isOpen={isRemitModalOpen}
						close={() => setIsRemitModalOpen(false)}
					/>
					<NewInvoiceModal
						orderId={orderDetail.order.id}
						orderItems={orderDetail.order.orderItems}
						orderInfo={orderDetail.order}
						isOpen={isInvoiceModalOpen}
						close={() => setIsInvoiceModalOpen(false)}
					/>
				</div>
			}
		</div>
	)
}

const GetOrderIdFromURL = () => {
	const id = window.location.pathname.split('/')
	return id[id.length - 1]
}
const mapStateToProps = state => {
	return {
		orderDetail: state.Orders.orderDetail,
		suppliers: state.Users.suppliers,
		isLoading: state.Orders.isLoading,
		error: state.error,
	}
}
OrderDetail.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	getOrderDetail: PropTypes.func,
	orderDetail: PropTypes.object,
	suppliers: PropTypes.array,
	getSuppliers: PropTypes.func,
	getQualityProblems: PropTypes.func,
	getQualitySolutions: PropTypes.func,
}
export default withGetPartConfigOptions(withRouter(connect(mapStateToProps, { getOrderDetail, getSuppliers, getQualityProblems, getQualitySolutions })(
	withTranslation()(OrderDetail),
)))
