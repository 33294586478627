import GeometryViewerModal from 'pages/Offer/Modals/StlViewerModal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

export const PartThumbnail = ({ geometryData, partsFiles, propsImageUrl, propsGeometryUrl, id, hideData, extension }) => {

	const partId = geometryData?.partId

	const [isGeometryViewerModalOpen, setIsGeometryViewerModalOpen] = useState(false)

	const [imageUrl, setImageUrl] = useState(null)
	const [geometryInfo, setGeometryInfo] = useState({
		modelSrc: null,
		partId: null,
		size: null,
		weight: null,
		partName: null,
	})
	useEffect(() => {
		if (partId != null && propsImageUrl == null && propsGeometryUrl == null) {
			if (partsFiles && partsFiles.parts != null && partsFiles.parts.length > 0) {
				const fileData = getFilesData(partId)
				if (fileData != null) {
					setImageUrl(fileData.fileLinks.thumbnail)
					setGeometryInfo(getGeometryInfo(fileData.fileLinks.stlModel || fileData.fileLinks.objModel))
				}
			}
		} else {
			setImageUrl(propsImageUrl)
			setGeometryInfo(getGeometryInfo(propsGeometryUrl))
		}
	}, [partsFiles, propsGeometryUrl])

	const getGeometryInfo = (geometryUrl) => {
		return {
			...geometryData,
			modelSrc: geometryUrl,
		}
	}

	const getFilesData = partId => {
		return partsFiles.parts.find(part => parseInt(part.id) === partId)
	}

	const closeModal = () => {
		setIsGeometryViewerModalOpen(false)
	}

	const openGeometryViewerModal = () => {
		if (geometryInfo.modelSrc != null) {
			setIsGeometryViewerModalOpen(true)
		}
	}

	return (
		<div id={id} className='d-flex justify-content-start cursor-pointer' onClick={(e) => openGeometryViewerModal()}>
			<GeometryViewerModal
				isGeometryViewerModalOpen={isGeometryViewerModalOpen}
				closeGeometryViewerModal={closeModal}
				geometryViewerData={geometryInfo}
				hideData={hideData}
				extension={extension} />
			{imageUrl != null ?
				<img src={imageUrl} className='' height={100} width={75} />
				: <p className='fs-2 m-0'><i className='bx bx-camera-off'></i></p>}
		</div>
	)

}

const mapStateToProps = state => {
	return {
		errorCloud: state.CloudStorage.error,
		partsFiles: state.CloudStorage.partsFiles,
		isLoadingCloud: state.CloudStorage.isLoading,
	}
}

PartThumbnail.propTypes = {
	geometryData: PropTypes.object,
	partsFiles: PropTypes.array,
	propsImageUrl: PropTypes.string,
	propsGeometryUrl: PropTypes.string,
}

export default connect(mapStateToProps, {})(PartThumbnail)
